import React, { useEffect, useState } from "react";
import { ToolCanvas } from "@shared/Map/tools/PathTool";
import { pointerState } from "@shared/states/mapStates";
import { LayerID } from "@shared/Map/types";
import { useSnapshot } from "valtio";

export interface SvgEraserToolProps {
	toolCanvas: ToolCanvas;
	onRemove: (layerId: LayerID, objectId: string) => void;
}

const EraserTool: React.FC<SvgEraserToolProps> = (props) => {
	const pointerSnap = useSnapshot(pointerState);
	const [down, setDown] = useState(false);

	useEffect(() => {
		if (!down) return;

		const moveListener = (e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();

			// it can happen that target doesn't have the getAttribute somehow
			if (!e.target || !(e.target as Element).getAttribute) {
				return;
			}

			const objectId = (e.target as Element).getAttribute("data-objectid");
			const layerId = (e.target as Element).getAttribute("data-layerid");
			if (objectId && layerId) {
				props.onRemove(layerId as LayerID, objectId);
			}
		};

		window.addEventListener("mousemove", moveListener);
		return () => {
			window.removeEventListener("mousemove", moveListener);
		};
	}, [down]);

	useEffect(() => {
		const moveListener = (e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();
			setDown(true);
		};

		pointerState.ref?.addEventListener("mousedown", moveListener);
		return () => {
			pointerState.ref?.removeEventListener("mousedown", moveListener);
		};
	}, []);

	useEffect(() => {
		if (!down) return;

		const moveListener = (e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();

			setDown(false);
		};

		window.addEventListener("mouseup", moveListener);
		window.addEventListener("pointercancel", moveListener);
		return () => {
			window.removeEventListener("mouseup", moveListener);
			window.removeEventListener("pointercancel", moveListener);
		};
	}, [down]);

	return (
		<rect
			style={{ pointerEvents: "none" }}
			x={pointerSnap.position.x - 100}
			y={pointerSnap.position.y - 100}
			rx={50}
			width={200}
			height={200}
			opacity={down ? 0.8 : 0.4}
			fill={"#ffffff"}
		></rect>
	);
};

export { EraserTool };
