import React from "react";
import { ChampionIcon } from "@shared/components/icons/ChampionIcon";
import { KillEvent } from "@shared/types/riot";
import { EventRow } from "./EventRow";

export const KillEventRow: React.FC<{ event: KillEvent }> = ({ event }) => {
	// This should really show the championId from the overlay if it's set, but
	// that would require setting up somehow looking it at the frame that's set and
	// other stuff, not worth it for now.
	return (
		<EventRow event={event}>
			<div className={"flex gap-1 items-center"}>
				<div className={"h-8 w-8"}>
					<ChampionIcon participantId={event.killerId} />
				</div>
				<div>killed</div>
				<div className={"h-8 w-8"}>
					<ChampionIcon participantId={event.victimId} />
				</div>
			</div>
		</EventRow>
	);
};
