import React from "react";
import { SettingsState, settingsState, useSettingsSnapshot } from "../../states/uiStates";

interface IngameSettingsModalProps {}

const BehaviorSettingsModal: React.FC<IngameSettingsModalProps> = (props) => {
	const settingsSnap = useSettingsSnapshot();

	const handleSettingChange = (setting: keyof NonNullable<SettingsState["behavior"]>) => () => {
		if (!settingsState.behavior) settingsState.behavior = {};

		settingsState.behavior[setting] = settingsState.behavior?.[setting] === false;
	};

	return (
		<div className={"flex flex-col text-white"}>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Open Match Review on Game End
					<input
						type={"checkbox"}
						checked={settingsSnap.behavior?.openPostGame !== false}
						onChange={handleSettingChange("openPostGame")}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Close on Game Start
					<input
						type={"checkbox"}
						checked={settingsSnap.behavior?.closeOnGame === true}
						onChange={handleSettingChange("closeOnGame")}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Change Screen on Game Start
					<input
						type={"checkbox"}
						disabled={settingsSnap.behavior?.closeOnGame === true}
						checked={settingsSnap.behavior?.moveToSecondScreen !== false}
						onChange={handleSettingChange("moveToSecondScreen")}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Open Minimap Overlay on Game Start
					<input
						type={"checkbox"}
						checked={settingsSnap.behavior?.openMinimap !== false}
						onChange={handleSettingChange("openMinimap")}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			{/*<div className={"border-b border-slate-400 p-2"}>*/}
			{/*	<label className={"flex justify-between items-center select-none cursor-pointer"}>*/}
			{/*		Open Minimap Assistant on Game Start*/}
			{/*		<input type={"checkbox"}*/}
			{/*			   checked={settingsSnap.behavior?.openAssist !== false}*/}
			{/*			   onChange={handleSettingChange("openAssist")}*/}
			{/*			   className={"p-1 rounded text-white m-1 outline-none bg-slate-500"} />*/}
			{/*	</label>*/}
			{/*</div>*/}
		</div>
	);
};

export { BehaviorSettingsModal };