import React, { FC, useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import MoveIcon from "../../../assets/icons/arrow-move.svg?react";
import SettingsIcon from "../../../assets/icons/cog.svg?react";
import DiscordIcon from "../../../assets/icons/discord.svg?react";
import EraserIcon from "../../../assets/icons/eraser.svg?react";
import OpenIcon from "../../../assets/icons/files-history.svg?react";
import HelpIcon from "../../../assets/icons/help.svg?react";

import PanIcon from "../../../assets/icons/pan.svg?react";
import { platformState, toolState, uiState } from "../states/uiStates";
import { MapTool } from "../types/tools";
import { AuthButton } from "./AuthButton/AuthButton";
import { DragonToolButton } from "./DragonButton/DragonToolButton";
import { NewToolbarButton } from "./NewToolbarButton";
import { ShapeToolButton } from "./ShapePopup/ShapeToolButton";
import { ShareToolbarButton } from "./SharePopup/ShareToolbarButton";
import { StrategiesButton } from "./StrategiesButton/StrategiesButton";
import { ToolButton } from "./ToolButton";
import { WardToolButton } from "./WardButton/WardToolButton";
import { ImportStratButton } from "@shared/Toolbar/ImportStratButton";
import { SaveImageToolButton } from "@shared/Toolbar/SaveImageToolButton";
import { TextButton } from "@shared/Toolbar/TextButton/TextButton";
import { PencilButton } from "@shared/Toolbar/PencilButton/PencilButton";

interface ToolbarProps {}

export const Toolbar: FC<ToolbarProps> = ({}) => {
	const toolSnap = useSnapshot(toolState);
	const [lastTool, setLastTool] = useState<MapTool | null>();
	const platformSnap = useSnapshot(platformState);

	useEffect(() => {
		const downListener = (e: KeyboardEvent) => {
			const focusedElement = document.activeElement?.localName;
			if (focusedElement && ["textarea", "input"].includes(focusedElement)) {
				return;
			}
			if (e.key === "w") {
				toolState.selectedTool = MapTool.WARD;
			}
			if (e.key === "e") {
				toolState.selectedTool = MapTool.ERASER;
			}
			if (e.key === "l") {
				toolState.selectedTool = MapTool.PENCIL;
				toolState.properties[MapTool.PENCIL].isArrow = false;
			}
			if (e.key === "a") {
				toolState.selectedTool = MapTool.PENCIL;
				toolState.properties[MapTool.PENCIL].isArrow = true;
			}
			if (e.key === "s") {
				toolState.selectedTool = MapTool.SHAPE;
			}
			if (e.key === "t") {
				toolState.selectedTool = MapTool.TEXT;
			}
			if (e.key === "Control" && !!toolSnap.selectedTool && !lastTool) {
				setLastTool(toolSnap.selectedTool);
				toolState.selectedTool = MapTool.MOVE;
			}
		};

		const upListener = (e: KeyboardEvent) => {
			if (e.key === "Control" && lastTool) {
				toolState.selectedTool = lastTool;
				setLastTool(null);
			}
		};

		window.addEventListener("keydown", downListener);
		window.addEventListener("keyup", upListener);

		return () => {
			window.removeEventListener("keydown", downListener);
			window.removeEventListener("keydown", upListener);
		};
	}, [toolSnap.selectedTool, lastTool]);

	return (
		<div className="w-14 border-right flex flex-col justify-between p-2">
			<div className={"flex flex-col gap-2"}>
				<ToolButton tool={MapTool.PAN} tooltipMessage={"Pan around the map"}>
					<PanIcon width={32} height={32} />
				</ToolButton>
				<ToolButton tool={MapTool.MOVE} tooltipMessage={"Move objects on the map [Ctrl]"}>
					<MoveIcon width={32} height={32} />
				</ToolButton>
				<PencilButton />
				<TextButton />
				<ShapeToolButton />
				<WardToolButton />
				<ToolButton tool={MapTool.ERASER} tooltipMessage={"Eraser [e]"}>
					<EraserIcon width={32} height={32} />
				</ToolButton>
				<DragonToolButton />
				<div className={"bg-slate-500"} style={{ height: 1 }}></div>
				{platformSnap.platform !== "desktop" && <StrategiesButton />}
				<ShareToolbarButton />
				<NewToolbarButton
					onClick={() => (uiState.modal = "import")}
					tooltipMessage={"Import game from history"}
					testId="toolbar-btn-import"
				>
					<OpenIcon width={32} height={32} />
				</NewToolbarButton>
				<SaveImageToolButton></SaveImageToolButton>
			</div>
			<div className={"flex flex-col gap-2"}>
				{platformSnap.platform !== "desktop" && <AuthButton />}
				{IS_DEV && <ImportStratButton />}

				<NewToolbarButton
					onClick={() => (uiState.modal = "settings")}
					tooltipMessage={"Settings"}
					testId="toolbar-btn-settings"
				>
					<SettingsIcon width={32} height={32} />
				</NewToolbarButton>
				<NewToolbarButton
					onClick={() => window.open("https://url.duiker101.net/riftkitdiscord")}
					tooltipMessage={"Join our Discord Server"}
					testId="toolbar-btn-discord"
				>
					<DiscordIcon width={32} height={32} />
				</NewToolbarButton>
				<NewToolbarButton
					onClick={() => (uiState.modal = "help")}
					tooltipMessage={"Help"}
					testId="toolbar-btn-help"
				>
					<HelpIcon width={32} height={32} />
				</NewToolbarButton>
			</div>
		</div>
	);
};
