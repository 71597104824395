import * as React from "react";
const SvgBaronHunter = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 116 101", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("rect", { id: "baron-hunter", x: 0.711, y: 0.09, width: 115, height: 100, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "baron-hunting" }, /* @__PURE__ */ React.createElement("path", { id: "baron-pit", "serif:id": "baron pit", d: "M36.609,47.181l-0.009,-0.007c0,0 -1.304,4.975 -0.713,9.11c1.017,7.12 -0.424,8.634 -6.223,3.843c-4.899,-4.048 -9.938,-6.311 -17.353,-25.507c-0.668,-1.731 1.579,-5.439 3.384,-5.872c8.022,-1.922 18.573,-1.819 22.408,-14.462c21.357,-0.063 33.678,10.111 39.279,22.845c6.737,15.314 1.012,28.075 16.807,38.731c0.503,0.339 -5.189,10.134 -7.462,10.02c-21.98,-1.098 -25.468,-4.45 -32.428,-8.37c-1.767,-0.996 -5.51,-3.503 -5.774,-6.096c-0.289,-2.832 2.861,-1.942 7.437,-3.045c2.438,-0.587 5.329,-2.68 6.227,-3.363c4.244,-3.004 7.017,-7.951 7.017,-13.542c-0,-9.15 -7.429,-16.578 -16.578,-16.578c-7.669,-0 -14.128,5.217 -16.019,12.293Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} })));
export default SvgBaronHunter;
