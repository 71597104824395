import { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { ViewerRef, Viewport } from "./Viewer";

import { usePageResize } from "@shared/utils";
import { useSnapshot } from "valtio";
import { matchState } from "@shared/states/uiStates";

export const useAutoFit = (ref: RefObject<ViewerRef>) => {
	const matchSnap = useSnapshot(matchState);
	useEffect(() => {
		if (!ref.current) return;
		// @ts-ignore
		window.viewer = ref.current;
		ref.current?.fit();
	}, [ref.current, matchSnap.match]);
};

/**
 * We use this toolCanvas to position a rect at 0,0 that covers the whole map to allow using tools
 */
export const useToolCanvas = (ref: RefObject<ViewerRef>, viewport: Viewport) => {
	const [bbox, setbbox] = useState<DOMRect | null>(null);

	usePageResize(() => {
		setbbox(ref.current?.bbox() || null);
	}, 500);

	useEffect(() => {
		setbbox(ref.current?.bbox() || null);
	}, [ref.current]);

	return useMemo(() => {
		return {
			x: -viewport.x / viewport.scale,
			y: -viewport.y / viewport.scale,
			width: (bbox?.width || 0) / viewport.scale,
			height: (bbox?.height || 0) / viewport.scale,
		};
	}, [bbox, viewport]);
};

export const useVTM = (viewport: Viewport) => {
	const viewportGroupRef = useRef<SVGGElement>(null);
	// ViewportTransformationMatrix = screenCTM + viewport
	// this is what we can use to convert any point from the screen to the real SVG coordinate
	// we get this by looking at the getScreenCTM on a group inside the group on which we apply the viewport
	const [vtm, setVTM] = useState<DOMMatrix | null>(null);

	useEffect(() => {
		setVTM(viewportGroupRef.current?.getScreenCTM() || null);
	}, [viewport]);

	return { viewportGroupRef, vtm };
};
