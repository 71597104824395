import React from "react";
import share_img from "./img/share.png";

interface WelcomeModalSharePageProps {}

const WelcomeModalSharePage: React.FC<WelcomeModalSharePageProps> = (props) => {
	return (
		<div className={"text-white"}>
			<img src={share_img} className={"mb-2"} />
			<h3 className={"text-2xl font-bold"}>Collaborate with your team!</h3>
			<p className={"text-slate-300"}>
				LoLPlanner allows you to strategize in real time with your team to always be on the
				same page!
				<br />
				Find the Share icon in the sidebar to start or join a room!
			</p>
		</div>
	);
};

export { WelcomeModalSharePage };