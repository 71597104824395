import React from "react";
import classNames from "classnames";

export interface MiniWardProps {
	onClick?: () => void;
	onMouseOver?: () => void;
	onMouseLeave?: () => void;
	selected: boolean;
	color: string;
	areaColor: string;
}

const MiniWard: React.FC<MiniWardProps> = (props) => {
	const { onClick, onMouseOver, onMouseLeave, selected, color, areaColor } = props;
	return (
		<div
			className={"flex items-center justify-center w-10 h-10"}
			style={{
				background: areaColor,
				borderRadius: "100%",
			}}
		>
			<div
				onMouseEnter={onMouseOver}
				onMouseLeave={onMouseLeave}
				className={classNames(
					"hover:cursor-pointer w-5 h-5 border border-opacity-0 border-white",
					{
						"border-opacity-100": selected,
					},
				)}
				onClick={onClick}
				style={{ background: color, borderRadius: "100%" }}
			></div>
		</div>
	);
};

export { MiniWard };
