import React from "react";
import { TextObject } from "@shared/Map/types";
import { ToolCanvas } from "@shared/Map/tools/PathTool";
import { toolState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { pointerState } from "@shared/states/mapStates";
import { pickle } from "@shared/utils";

export interface TextToolProps {
	toolCanvas: ToolCanvas;
	onAdd: (newObject: TextObject) => void;
}

const TextTool: React.FC<TextToolProps> = (props) => {
	const handleClick = () => {
		if (toolState.properties[MapTool.TEXT].editingId !== null) {
			toolState.properties[MapTool.TEXT].editingId = null;
			return;
		}
		const id = "text-" + Math.random();
		const newShape: TextObject = {
			color: toolState.properties[MapTool.TEXT].color,
			id,
			type: "text",
			layerId: "texts",
			text: "Text",
			fontSize: toolState.properties[MapTool.TEXT].fontSize,
			dragging: false,
			visible: true,
			position: pickle(pointerState.position),
		};

		toolState.properties[MapTool.TEXT].editingId = null;
		props.onAdd(newShape);
	};

	return (
		<>
			<rect
				onClick={handleClick}
				x={props.toolCanvas.x}
				y={props.toolCanvas.y}
				width={props.toolCanvas.width}
				height={props.toolCanvas.height}
				fill={"#ffffff00"}
			/>
		</>
	);
};

export { TextTool };
