import React from "react";

export interface FrameTimeProps {
	x: number;
	time: number;
}

const FrameTime: React.FC<FrameTimeProps> = (props) => {
	if (!props.time) return null;

	const minutes = (props.time / 1000 / 60) % 60;
	const timeText = minutes + "m";

	if (minutes % 5 !== 0) return null;

	return (
		<>
			<text
				x={props.x}
				style={{ pointerEvents: "none" }}
				textAnchor={"middle"}
				y={55}
				fill={"#93abc9"}
				fontSize={12}
				width={50}
			>
				{timeText}
			</text>
		</>
	);
};

export { FrameTime };
