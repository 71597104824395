import React from "react";
import { ToolCanvas } from "@shared/Map/tools/PathTool";
import { WardObject } from "@shared/Map/types";
import { toolState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { pointerState } from "@shared/states/mapStates";
import { pickle } from "@shared/utils";

export interface WardToolProps {
	toolCanvas: ToolCanvas;
	onAdd: (newObject: WardObject) => void;
}

const WardTool: React.FC<WardToolProps> = (props) => {
	const handleClick = () => {
		const id = "ward-" + Math.random();
		const newWard: WardObject = {
			id,
			type: "ward",
			layerId: "wards",
			color: toolState.properties[MapTool.WARD].color,
			areaColor: toolState.properties[MapTool.WARD].areaColor,
			dragging: false,
			visible: true,
			position: pickle(pointerState.position),
		};

		props.onAdd(newWard);
	};

	return (
		<>
			<rect
				onClick={handleClick}
				x={props.toolCanvas.x}
				y={props.toolCanvas.y}
				width={props.toolCanvas.width}
				height={props.toolCanvas.height}
				fill={"#ffffff00"}
			/>
		</>
	);
};

export { WardTool };
