import { ShapeObject } from "../Map/types";

export enum MapTool {
	PAN,
	MOVE,
	SHAPE,
	IMAGE,
	PENCIL,
	WARD,
	ERASER,
	TEXT,
}

interface WardToolProps {
	color: "green" | "pink" | "blue" | "yellow";
	areaColor: "blue" | "red";
}

interface PencilToolProps {
	isArrow: boolean;
	color: string;
	expiry?: number;
}

interface ShapeToolProps {
	color: string;
	shape: ShapeObject["shape"];
	scale: number;
}

interface ImageToolProps {
	url?: string;
	scale: number;
}

interface TextToolProps {
	color: string;
	editingId: string | null;
	fontSize: number;
}

export interface ToolProps {
	[MapTool.WARD]: WardToolProps;
	[MapTool.PENCIL]: PencilToolProps;
	[MapTool.SHAPE]: ShapeToolProps;
	[MapTool.TEXT]: TextToolProps;
	[MapTool.IMAGE]: ImageToolProps;
}
