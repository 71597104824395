import * as React from "react";
const SvgCog = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 5167 5167", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd"
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M3075.93,850.251c292.251,82.973 554.288,237.726 765.83,443.985l382.678,-220.843c33.516,-19.342 76.372,-7.863 95.722,25.638l420.44,727.904c19.35,33.501 7.867,76.339 -25.648,95.68l-383.15,221.115c35.594,141.338 54.499,289.306 54.499,441.687c0,152.381 -18.905,300.348 -54.499,441.686l383.15,221.115c33.515,19.342 44.998,62.179 25.648,95.68l-420.44,727.905c-19.35,33.501 -62.206,44.979 -95.722,25.637l-382.678,-220.843c-211.542,206.259 -473.579,361.012 -765.83,443.985l0,439.659c0,38.684 -31.373,70.043 -70.073,70.043l-840.88,0c-38.701,0 -70.074,-31.359 -70.074,-70.043l0,-437.685c-293.868,-82.142 -557.45,-236.786 -770.228,-443.42l-378.28,218.304c-33.515,19.342 -76.372,7.864 -95.722,-25.637l-420.44,-727.905c-19.35,-33.501 -7.867,-76.338 25.649,-95.68l377.035,-217.586c-36.171,-142.403 -55.392,-291.566 -55.392,-445.215c-0,-153.65 19.221,-302.812 55.392,-445.216l-377.035,-217.586c-33.516,-19.341 -44.999,-62.179 -25.649,-95.68l420.44,-727.904c19.35,-33.501 62.207,-44.98 95.722,-25.638l378.28,218.305c212.778,-206.634 476.36,-361.278 770.228,-443.421l0,-437.685c0,-38.684 31.373,-70.043 70.074,-70.043l840.88,0c38.7,0 70.073,31.359 70.073,70.043l0,439.659Zm-493.601,904.534c-460.451,-0 -833.72,373.269 -833.72,833.72c0,460.45 373.269,833.719 833.72,833.719c460.45,0 833.72,-373.269 833.72,-833.719c-0,-460.451 -373.27,-833.72 -833.72,-833.72Z", style: {
  fill: "none",
  fillRule: "nonzero",
  stroke: "currentColor",
  strokeWidth: "185.27px"
} }));
export default SvgCog;
