import { useNetworkState } from "@react-hookz/web";
import React from "react";
import { useHasTier } from "../states/authState";
import { AdView } from "./AdView";
import { LayerList } from "./LayerList";
import { uiState } from "@shared/states/uiStates";
import { TopAdBanner } from "@shared/Sidebar/TopAdBanner";

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = (props) => {
	const hideAds = useHasTier("Diamond");
	const network = useNetworkState();

	return (
		<div className={"flex flex-col my-2 mr-2"} style={{}}>
			{/*{!hideAds && <TopAdBanner />}*/}
			<div
				className={"rounded bg-slate-600 flex-1 mb-2 overflow-hidden"}
				style={{ width: 416 }}
			>
				<LayerList />
			</div>

			{!hideAds && (
				<>
					<div className={"text-sm text-slate-300 text-right"}>Advertisement</div>
					<div className={"rounded bg-slate-600 p-2"}>
						<AdView />
					</div>
				</>
			)}

			{!network.online && (
				<div className={"my-2 rounded bg-red-500 text-white text-center p-2"}>
					No Internet Connection
				</div>
			)}

			<div className={"text-sm text-slate-300 text-right flex justify-between mt-2"}>
				<div>
					<a
						href={"https://docs.riftkit.net/docs/privacy"}
						rel={"noreferrer nofollow"}
						className={"visited:text-slate-300"}
					>
						Privacy Policy
					</a>
				</div>
				<div
					onClick={() => (uiState.modal = "changelog")}
					className={"text-slate-300 cursor-pointer flex items-center gap-2"}
				>
					{APP_VERSION}
				</div>
			</div>
		</div>
	);
};

export { Sidebar };
