import React from "react";
import { ChampionIcon } from "@shared/components/icons/ChampionIcon";
import { EliteMonsterKillEvent } from "@shared/types/riot";
import { EventRow } from "./EventRow";

export const EliteKillEventRow: React.FC<{ event: EliteMonsterKillEvent }> = ({ event }) => {
	return (
		<EventRow event={event}>
			<div className={"flex gap-1 items-center"}>
				<ChampionIcon participantId={event.killerId} width={35} height={35} />
				<div className={"w-48"}>
					Has slain {event.monsterType === "DRAGON" && "the Dragon"}
					{event.monsterType === "BARON_NASHOR" && "the Baron Nashor"}
					{event.monsterType === "RIFTHERALD" && "the Rift Herald"}
				</div>
			</div>
		</EventRow>
	);
};
