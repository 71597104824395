import React, { useMemo, useState } from "react";
import { APIError, fetchHistory } from "../../api";
import { GameSummaryView } from "./GameSummaryView";
import { MatchSummary, RegionV5, Summoner } from "../../types/riot";
import { loadMapStateFromGame } from "../../states/frameState";
import useAsyncEffect from "use-async-effect";
import { closeAllModals, useOpenUpgrade } from "../../states/uiStates";
import { useApi } from "@shared/api/hooks";
import { regionV4toV5, trackEvent } from "@shared/utils";
import { useHasTier } from "@shared/states/authState";

export interface GamesHistoryViewProps {
	summoner: Summoner;
	region: RegionV5;
	queueId: string;
}

const GamesHistoryView: React.FC<GamesHistoryViewProps> = (props) => {
	const { loading, request } = useApi(fetchHistory);
	const [history, setHistory] = useState<string[]>([]);
	const [error, setError] = useState<APIError>();
	const { openUpgradeModal } = useOpenUpgrade();

	const isPro = useHasTier("Diamond");

	useAsyncEffect(async () => {
		const response = await request(props.region, props.summoner.puuid, props.queueId);

		if (!response) {
			setError({ message: "Unknown error", code: 500 });
			return;
		}

		if (response.error) {
			setError(response.error);
			return;
		}

		if (!response.history) {
			setError({ message: "Unknown error", code: 500 });
			return;
		}

		setHistory(response.history);
	}, [props.summoner, props.region, props.queueId]);

	const visibleHistory = useMemo(() => {
		if (isPro) {
			return history;
		}
		return history.slice(0, 10);
	}, [history, isPro]);

	const handleClick = async (summary: MatchSummary) => {
		if (!summary.platformId) {
			return;
		}
		trackEvent("import_summoner");
		loadMapStateFromGame(
			regionV4toV5(summary.platformId),
			summary.platformId + "_" + summary.gameId,
		);
		closeAllModals();
		// todo f4 warn that importing a game will clear the overlay
	};

	if (loading) {
		return <div className={"text-white"}>Loading Game History...</div>;
	}

	if (history.length === 0) {
		return <div className={"text-white opacity-80"}>No recent games found.</div>;
	}

	return (
		<>
			{!loading && error && <div className={"text-red-400 mt-2"}>{error.message}</div>}
			{visibleHistory.map((gameId) => (
				<GameSummaryView
					key={gameId}
					region={props.region}
					gameId={gameId}
					playerId={props.summoner.puuid}
					onClick={handleClick}
				/>
			))}
			{history.length > visibleHistory.length && (
				<div
					onClick={openUpgradeModal}
					className={
						"text-center w-100 rounded p-2 cursor-pointer bg-opacity-80 bg-blend-lighten hover:bg-blend-darken hover:bg-opacity-100 active:bg-slate-700 select-none text-white disabled:opacity-30 bg-blue-500"
					}
				>
					Upgrade to load more games
				</div>
			)}
		</>
	);
};

export { GamesHistoryView };
