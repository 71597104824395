import { useCallback, useState } from "react";

// todo d1 improve types here?
export function useApi<T extends (...args: any[]) => any | null>(apiCall: T) {
	const [loading, setLoading] = useState(false);

	const request = useCallback(
		async (...args: Parameters<T>) => {
			if (loading) {
				return null;
			}

			setLoading(true);
			try {
				return await apiCall(...args);
			} catch (e) {
				return null;
			} finally {
				setLoading(false);
			}
		},
		[loading, apiCall],
	);

	return { loading, request } as { loading: boolean; request: T };
}
