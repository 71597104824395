import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "../../shared/src/components/ErrorBoundary";
import { StaticDataContextWrapper } from "../../shared/src/context/StaticDataContext";
import { platformState } from "../../shared/src/states/uiStates";
import { App } from "./App";
import "./index.css";


const root = createRoot(document.querySelector("#root")!);

platformState.platform = "web";

root.render(
	<ErrorBoundary>
		<StaticDataContextWrapper>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</StaticDataContextWrapper>
	</ErrorBoundary>
);
