import React, { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { useThrottledCallback } from "@react-hookz/web";
import { useSnapshot } from "valtio";
import { toolState } from "@shared/states/uiStates";
import { pointerState } from "@shared/states/mapStates";
import { Position } from "@shared/types/riot";
import { MapTool } from "@shared/types/tools";

export interface DraggableProps {
	draggable?: boolean;
	position: Position;
	onMove: (position: Position) => void;
}

type OnStart = (point: Position) => boolean;
type OnMove = (point: Position, movement: Position) => void;
type OnEnd = () => void;

export const useDrag = (callbacks: { onMove?: OnMove; onStart?: OnStart; onEnd?: OnEnd }) => {
	const isDragging = useRef(false);

	const offset = useRef({ x: 0, y: 0 });

	let handleDragStart = useCallback(
		(e: React.MouseEvent) => {
			if (e.button !== 0) return;

			let point = pointerState.position;
			if (!point) return;

			const shouldDrag = callbacks.onStart?.(point);
			if (!shouldDrag) return;

			e.stopPropagation();
			offset.current = point;
			// setIsDragging(true);
			isDragging.current = true;
		},
		[callbacks.onStart],
	);

	const moveListener = useThrottledCallback(
		() => {
			if (!isDragging.current) return;
			const point = pointerState.position;
			const movement = { x: point!.x - offset.current.x, y: point!.y - offset.current.y };
			callbacks.onMove?.({ x: point.x, y: point.y }, movement);
			offset.current = point;
		},
		[callbacks.onMove, isDragging],
		16,
	);

	useEffect(() => {
		if (!isDragging) return;

		const upListener = () => {
			if (!isDragging.current) return;
			isDragging.current = false;
			callbacks.onEnd?.();
		};

		window.addEventListener("mouseup", upListener);
		window.addEventListener("pointerup", upListener);
		window.addEventListener("pointercancel", upListener);
		window.addEventListener("mousemove", moveListener);

		return () => {
			window.removeEventListener("mouseup", upListener);
			window.removeEventListener("pointerup", upListener);
			window.removeEventListener("pointercancel", upListener);
			window.removeEventListener("mousemove", moveListener);
		};
	}, [callbacks.onEnd, moveListener]);

	return { handleDragStart };
};

const Draggable: FC<PropsWithChildren<DraggableProps>> = (props) => {
	const toolSnap = useSnapshot(toolState);
	const startDiff = useRef({ x: 0, y: 0 });

	const handleMove = useCallback(
		(position: Position, movement: Position) => {
			if (props.draggable === false || toolSnap.selectedTool === MapTool.PAN) return;
			props.onMove({
				x: position.x - startDiff.current.x,
				y: position.y - startDiff.current.y,
			});
		},
		[props.draggable, toolSnap.selectedTool],
	);

	const handleStart = useCallback(
		(position: Position) => {
			if (props.draggable === false || toolSnap.selectedTool === MapTool.PAN) return false;
			startDiff.current = {
				x: position.x - props.position.x,
				y: position.y - props.position.y,
			};
			return true;
		},
		[props.position, props.draggable, toolSnap.selectedTool],
	);

	const handleEnd = useCallback(() => {}, []);

	const { handleDragStart } = useDrag({
		onMove: handleMove,
		onStart: handleStart,
		onEnd: handleEnd,
	});

	return (
		<g
			transform={`translate(${props.position.x}, ${props.position.y})`}
			onMouseDown={handleDragStart}
		>
			{props.children}
		</g>
	);
};

export { Draggable };
