import React, { FC, PropsWithChildren } from "react";
import { useSnapshot } from "valtio";
import { toolState } from "../states/uiStates";
import { MapTool } from "../types/tools";
import { NewToolbarButton } from "./NewToolbarButton";

interface ToolButtonProps {
	tool: MapTool;
	className?: string;
	style?: React.CSSProperties;
	isSelected?: boolean;
	onClick?: () => void;
	tooltipMessage: string;
	extra?: React.ReactElement;
}

export const ToolButton: FC<PropsWithChildren<ToolButtonProps>> = (props) => {
	const { tool, children, style, className = "", onClick, isSelected } = props;
	const toolSnap = useSnapshot(toolState);

	const handleClick = () => {
		if (onClick) {
			onClick();
			return;
		}

		toolState.selectedTool = tool;
	};

	let actualSelected = isSelected !== undefined ? isSelected : toolSnap.selectedTool == tool;

	return (
		<NewToolbarButton
			testId={"toolbar-btn-" + props.tool}
			onClick={handleClick}
			isSelected={actualSelected}
			tooltipMessage={props.tooltipMessage}
			extra={actualSelected ? props.extra : undefined}
		>
			{children}
		</NewToolbarButton>
	);
};
