import React from "react";
import { Route, Routes } from "react-router-dom";
import { Main } from "../../shared/src/Main";

export const App = () => {
	return (
		<div className="bg-slate-700 min-h-screen flex flex-col">
			<Routes>
				<Route path={"/strategy/:strategyId/*"} element={<Main />}></Route>
				<Route path={"/*"} element={<Main />}></Route>
			</Routes>
		</div>
	);
};
