import * as React from "react";
const SvgWind = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:serif": "http://www.serif.com/", width: "100%", height: "100%", viewBox: "0 0 101 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-245,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(14.641,0,0,11.5354,33.2849,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { id: "wind", x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "wind1", "serif:id": "wind" }, /* @__PURE__ */ React.createElement("g", { id: "Layer1", transform: "matrix(0.091003,0,0,0.115504,13.3686,8.97184)" }, /* @__PURE__ */ React.createElement("path", { d: "M38.819,15.488C41.297,15.889 57.983,30.552 56.026,39.357C54.069,48.163 31.654,60.706 27.079,68.321C24.196,73.119 30.157,85.552 28.574,85.045C26.992,84.538 15.234,71.656 17.583,65.279C19.723,59.472 38.166,50.84 41.157,36.954C42.667,29.946 36.341,15.087 38.819,15.488Z", style: {
  fill: "none",
  stroke: "rgb(116,190,221)",
  strokeWidth: "2.25px"
} }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,0.673709,0)" }, /* @__PURE__ */ React.createElement("path", { d: "M64.601,25.571C66.792,25.891 76.053,40.833 75.514,47.636C74.48,60.689 64.771,64.172 56.185,63.93C38.766,63.438 38.25,72.069 35.632,68.645C33.523,65.886 54.753,52.22 62.37,45.714C67.351,41.461 62.411,25.25 64.601,25.571Z", style: {
  fill: "none",
  stroke: "rgb(116,190,221)",
  strokeWidth: "2.25px"
} })), /* @__PURE__ */ React.createElement("path", { d: "M39.931,78.365C38.728,77.438 44.621,71.419 48.336,70.258C52.168,69.061 58.404,72.999 62.925,71.181C68.427,68.969 79.57,54.792 81.352,56.986C83.133,59.181 77.913,81.21 73.614,84.348C69.315,87.487 61.169,76.816 55.555,75.819C50.36,74.896 41.135,79.291 39.931,78.365Z", style: {
  fill: "none",
  stroke: "rgb(116,190,221)",
  strokeWidth: "2.25px"
} }))))));
export default SvgWind;
