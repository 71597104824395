import { deepmerge } from "deepmerge-ts";
import { mapOverlayFramesState } from "../states/frameState";
import { matchState, uiState } from "../states/uiStates";
import { FrameLayer, MapFrame } from "../types/map";
import { LayerID, MapObject } from "./types";

export function setTimelineObject(
	frameIndex: number,
	layerId: LayerID,
	objectId: string,
	object: Partial<MapObject>,
) {
	const applyToAll = uiState.applyToAllFrames;
	const startFrameIndex = applyToAll ? 0 : frameIndex;
	// TODO d4 this needs to change to allow frames without match
	const framesCount = Math.max(matchState.match?.timeline.frames.length || 1, 1);

	const endFrameIndex = applyToAll ? framesCount : frameIndex + 1;

	const batch: OverlayChange<MapObject>[] = [];
	for (let i = startFrameIndex; i < endFrameIndex; i++) {
		batch.push({ frameIndex: i, layerId, objectId, object });
	}

	batchOverlayChanges(batch);
}

interface OverlayChange<T extends MapObject> {
	frameIndex: number;
	layerId: LayerID;
	objectId: string;
	object: Partial<T>;
}

export function batchOverlayChanges<T extends OverlayChange<K>[], K extends MapObject>(changes: T) {
	const newFrames = changes.reduce(
		(a, c) =>
			deepmerge(a, {
				[c.frameIndex]: {
					frameIndex: c.frameIndex,
					[c.layerId]: {
						id: c.layerId,
						objects: {
							[c.objectId]: c.object,
						},
					},
				},
			}),
		{},
	);

	mapOverlayFramesState.frames = deepmerge(mapOverlayFramesState.frames, newFrames);
}

export function setOverlayObject<T extends MapObject>(
	frameIndex: number,
	layerId: LayerID,
	objectId: string,
	object: Partial<T>,
) {
	const structure = {
		id: layerId,
		objects: {
			[objectId]: object,
		},
	} as unknown as FrameLayer<any>;

	setOverlayLayer(frameIndex, layerId, structure);
	return getOverlayObject(frameIndex, layerId, objectId) || null;
}

export function getOverlayObject(frameIndex: number, layerId: LayerID, objectId: string) {
	return mapOverlayFramesState.frames?.[frameIndex]?.[layerId]?.objects[objectId];
}

export function setOverlayLayer(
	frameIndex: number,
	layerID: LayerID | "dragon" | "baronTerrain",
	value: Partial<MapFrame[keyof MapFrame]>,
) {
	if (!mapOverlayFramesState.frames) mapOverlayFramesState.frames = {};

	const structure = {
		[frameIndex]: {
			frameIndex,
			[layerID]: value,
		},
	};

	mapOverlayFramesState.frames = deepmerge(mapOverlayFramesState.frames, structure);
}

export function deleteOverlayObject(frameIndex: number, layerId: LayerID, objectId: string) {
	if (!!mapOverlayFramesState?.frames?.[frameIndex]?.[layerId]?.objects[objectId])
		delete mapOverlayFramesState.frames[frameIndex][layerId]?.objects[objectId];
}
