import React, { useState } from "react";
import { useHasTier } from "../../states/authState";
import { Modal } from "../Modal";
import classNames from "classnames";
import { UpgradeButton } from "./UpgradeButton";

export interface UpgradeModalProps {
	onClose: () => void;
}

const PRICING = {
	diamond: { 1: 10, 12: 9 },
	challenger: { 1: 15, 12: 13.5 },
};

const UpgradeModal: React.FC<UpgradeModalProps> = (props) => {
	const [selectedCycle, setSelectedCycle] = useState<1 | 12>(1);
	const hasDiamond = useHasTier("Diamond");

	return (
		<Modal title={"Upgrade your Membership"} onClose={props.onClose}>
			<div className={"flex justify-center mb-4"}>
				<div className={"flex bg-slate-700 gap-2 text-white p-2 rounded items-center"}>
					<div
						onClick={() => setSelectedCycle(1)}
						className={classNames(
							{ "bg-slate-500 rounded p-1": selectedCycle === 1 },
							"cursor-pointer w-20 text-center",
						)}
					>
						Monthly
					</div>
					<div
						onClick={() => setSelectedCycle(12)}
						className={classNames(
							{ "bg-slate-500 rounded p-1": selectedCycle === 12 },
							"cursor-pointer  w-20 text-center",
						)}
					>
						Yearly
					</div>
				</div>
			</div>
			<div className={"flex text-white gap-4"}>
				<div className={"bg-slate-700 p-2 rounded flex-1 w-80 flex flex-col gap-4"}>
					<div className={"text-4xl font-bold"}>Diamond</div>
					<div className={"opacity-70"}>For dedicated Players and Coaches</div>
					<ul className={"list-disc list-inside"}>
						<li>Unlimited Saved Strategies</li>
						<li>Load tournament games</li>
						<li>Load more game history</li>
						<li>Higher quality image export</li>
						<li>No ads</li>
					</ul>
					<div className={"flex-1"}></div>
					<div className={"flex justify-center items-center gap-8"}>
						<div className={"flex flex-col justify-center items-center relative"}>
							<div className={"text-4xl relative"}>
								{selectedCycle > 1 && (
									<div
										className={
											"text-xl absolute -left-10 top-1 line-through opacity-50"
										}
									>
										${PRICING["diamond"][1]!}
									</div>
								)}
								${PRICING["diamond"][selectedCycle]!}
							</div>
							<div className={"text-sm opacity-70"}>
								per month {selectedCycle > 1 && "billed annually"}
							</div>
						</div>
					</div>
					<UpgradeButton enabled={!hasDiamond} name={"Diamond"} cycle={selectedCycle} />
				</div>
				<div className={"bg-slate-700 p-2 rounded flex-1 w-80 flex flex-col gap-4"}>
					<div className={"text-4xl font-bold"}>Challenger</div>
					<div className={"opacity-70"}>Extra access for Esport Organizations</div>
					<div className={"flex-1"}>
						All Diamond Features plus the ability to upload GRID games
					</div>

					<div className={"flex justify-center items-center gap-8"}>
						<div className={"flex flex-col justify-center items-center relative"}>
							<div className={"text-4xl relative"}>
								{selectedCycle > 1 && (
									<div
										className={
											"text-xl absolute -left-10 top-1 line-through opacity-50"
										}
									>
										${PRICING["challenger"][1]!}
									</div>
								)}
								${PRICING["challenger"][selectedCycle]!}
							</div>
							<div className={"text-sm opacity-70"}>
								per month {selectedCycle > 1 && "billed annually"}
							</div>
						</div>
					</div>
					<UpgradeButton enabled={true} name={"Challenger"} cycle={selectedCycle} />
				</div>
			</div>
		</Modal>
	);
};

export { UpgradeModal };
