export interface TimeTick {
	timestamp: number;
	isMinute: boolean;
	x: number;
	frameIndex: number | null; // null frame means no frame associated. E.g., it's a second tick in Riot Games
}

export const createTicks = (timestamps: number[], ticksPerMinute: number, width: number) => {
	if (timestamps.length < 1) return [];
	const msPerTick = (60 / ticksPerMinute) * 1000;
	const ticks: TimeTick[] = [];
	const lastTimestamp = timestamps[timestamps.length - 1];
	let frameIndex = 0;
	const secondFrag = width / Math.floor(lastTimestamp / 1000);

	for (let ms = 0; ms < lastTimestamp; ms += 1000) {
		const seconds = ms / 1000;
		let isFrame = timestamps.find((t) => Math.floor(t / 1000) === seconds) !== undefined;
		if (ms % msPerTick === 0) {
			let isMinute = ms % 60000 === 0;
			ticks.push({
				frameIndex: isFrame ? frameIndex++ : null,
				timestamp: ms,
				isMinute,
				x: secondFrag * (ms / 1000),
			});
		}
	}

	if (timestamps.length > 1) {
		ticks.push({
			frameIndex,
			timestamp: lastTimestamp,
			isMinute: true,
			x: secondFrag * Math.floor(lastTimestamp / 1000),
		});
	}
	return ticks;
};
