import { SignalingConn, WebrtcProvider } from "y-webrtc";
import * as Y from "yjs";
import { randomHSL } from "../utils";

export class NetProvider {
	private readonly ydoc: Y.Doc;
	private provider: WebrtcProvider | null;

	constructor(ydoc: Y.Doc) {
		this.ydoc = ydoc;
		this.provider = null;
	}

	connect(room: string, username: string) {
		const fullRoomName = "room-" + room;
		console.log("connect yjs", fullRoomName, username);

		this.provider = new WebrtcProvider(fullRoomName, this.ydoc, {
			signaling: ["wss://signaling.riftkit.net"],
		} as any);

		this.provider.signalingConns.forEach((c) => this.onConnection(c));

		this.provider.connect();
		this.provider.on("synced", this.onSync);

		this.provider.awareness.setLocalStateField("username", username);
		this.provider.awareness.setLocalStateField("color", randomHSL({}));
		this.provider.awareness.setLocalStateField("position", { x: 0, y: 0 });
		this.provider.awareness.setLocalStateField("currentFrame", 0);
		this.provider.awareness.setLocalStateField("clientId", this.provider.awareness.clientID);
	}

	disconnect() {
		this.provider?.disconnect();
		this.provider?.destroy();
	}

	onConnection(connection: SignalingConn) {
		const provider = this.provider;
		connection.on("connect", () => {
			console.log("connected yjs", provider?.roomName);
			// if (connectionType === "query") {
			// 	// createDefaultState();
			// }
		});
	}

	onSync() {
		// if (connectionType == "join") {
		// 	resetStates();
		// }
		// bindDoc();
	}

	getAwareness() {
		return this.provider?.awareness;
	}

	isConnected() {
		return this.provider?.connected === true;
	}
}
