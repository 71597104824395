import React, { FormEvent, useState } from "react";
import { REGIONS_V4, RegionV4 } from "../../types/riot";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import { APIError, fetchGameSummary } from "../../api";
import { regionV4toV5 } from "../../utils";
import { loadMapStateFromGame } from "../../states/frameState";
import { closeAllModals, useOpenUpgrade } from "../../states/uiStates";
import { useApi } from "@shared/api/hooks";
import { useHasTier } from "@shared/states/authState";

export interface ImportMatchIdProps {}

const ImportMatchId: React.FC<ImportMatchIdProps> = (props) => {
	const { loading: loading, request: requestGameSummary } = useApi(fetchGameSummary);
	const [matchId, setMatchId] = useState("");
	const [region, setRegion] = useState<RegionV4>("EUW1");
	const [error, setError] = useState<APIError>();
	const hasPro = useHasTier("Diamond");
	const { openUpgradeModal } = useOpenUpgrade();

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault();

		const matchIdWithRegion = matchId.startsWith(region) ? matchId : `${region}_${matchId}`;

		setError(undefined);
		const summary = await requestGameSummary(regionV4toV5(region), matchIdWithRegion);
		if (!summary || !summary.summary.platformId) {
			setError({ message: "An error occurred.", code: 2442 });
			return;
		}

		if (summary.error) {
			setError(summary.error);
			return;
		}

		loadMapStateFromGame(
			regionV4toV5(summary.summary.platformId),
			summary.summary.platformId + "_" + summary.summary.gameId,
		);
		closeAllModals();
	};

	return (
		<form onSubmit={handleSubmit}>
			{!hasPro && (
				<div className={"flex flex-col items-center justify-center gap-4"}>
					<div className={"text-slate-100"}>
						Importing Tournament games requires a Diamond Subscription.
					</div>
					<div
						onClick={openUpgradeModal}
						className={
							"text-center w-100 rounded p-2 cursor-pointer bg-opacity-80 bg-blend-lighten hover:bg-blend-darken hover:bg-opacity-100 active:bg-slate-700 select-none text-white disabled:opacity-30 bg-blue-500"
						}
					>
						Upgrade Now
					</div>
				</div>
			)}

			{hasPro && (
				<>
					<div className={"flex relative bg-slate-500 p-1 rounded text-white"}>
						<select
							onChange={(e) => setRegion(e.currentTarget.value as RegionV4)}
							value={region}
							className={"bg-slate-600 rounded mr-2 outline-none"}
						>
							{Object.entries(REGIONS_V4).map(([key, name]) => (
								<option key={key} value={key}>
									{name}
								</option>
							))}
						</select>

						<div className={"flex flex-col"}>
							<input
								id={"name"}
								value={matchId}
								placeholder={"MatchID"}
								onChange={(e) => setMatchId(e.currentTarget.value)}
								className={"bg-transparent m-1 outline-none"}
							/>
						</div>
						<button
							type={"submit"}
							className={"hover:bg-slate-600 rounded disabled:opacity-30"}
							disabled={matchId.length < 3}
						>
							<SearchIcon width={30} height={30} />
						</button>
					</div>
				</>
			)}
			{!loading && error && <div className={"text-red-400 mt-2"}>{error.message}</div>}
		</form>
	);
};

export { ImportMatchId };
