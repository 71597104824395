import { Dialog } from "@headlessui/react";
import React, { PropsWithChildren } from "react";

interface ModalProps {
	show?: boolean;
	onClose?: () => void;
	title?: string | React.ReactElement;
}

const Modal: React.FC<PropsWithChildren<ModalProps>> = (props) => {
	const handleClose = () => {
		if (props.onClose) props.onClose();
	};

	return (
		<Dialog open={props.show != false} onClose={handleClose}>
			<div className="fixed inset-0 bg-black/40" aria-hidden="true" />
			<div className="fixed inset-0 flex items-center justify-center p-4">
				<div
					className={
						"rounded overflow-hidden border border-slate-800 shadow-xl shadow-black/30"
					}
				>
					<Dialog.Panel>
						{props.title && (
							<div>
								<Dialog.Title
									className={"bg-slate-700 text-white text-xl font-bold p-2"}
								>
									{props.title}
								</Dialog.Title>
							</div>
						)}
						<div className={"p-2 bg-slate-600"}>{props.children}</div>
					</Dialog.Panel>
				</div>
			</div>
		</Dialog>
	);
};

export { Modal };
