import * as React from "react";
const SvgHextech = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:serif": "http://www.serif.com/", width: "100%", height: "100%", viewBox: "0 0 101 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-608,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(14.641,0,0,11.5354,396.132,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { id: "hextech", x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "hextech1", "serif:id": "hextech" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0689154,0,0,0.0896148,-27.4162,10.0948)" }, /* @__PURE__ */ React.createElement("g", { id: "Layer1" }, /* @__PURE__ */ React.createElement("path", { d: "M650.667,80.355L622.311,52L664.667,9.645L664.667,23.645L693.022,52L650.667,94.355L650.667,80.355ZM658.311,29.883L650.148,38.046L658.311,46.209L666.475,38.046L658.311,29.883ZM643.947,41.353L635.3,50L657.596,72.296L679.892,50L671.245,41.353L657.596,55.003L643.947,41.353Z", style: {
  fill: "none",
  stroke: "rgb(0,195,255)",
  strokeWidth: "2.94px"
} })))))));
export default SvgHextech;
