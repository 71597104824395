import { ArrowObject, PencilPathObject } from "@shared/Map/types";
import React, { useCallback, useRef, useState } from "react";
import { useDrag } from "../Draggable";
import { Path } from "@shared/Map/objects/shapes/Path";
import { useSnapshot } from "valtio";
import { toolState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { pickle } from "@shared/utils";
import simplify from "simplify-js";
import { pointsToBounds } from "@shared/Map/utils";
import { Position } from "@shared/types/riot";

export interface ToolCanvas {
	width: number;
	height: number;
	x: number;
	y: number;
}

export interface SvgPathToolProps {
	toolCanvas: ToolCanvas;
	onAdd: (newObject: PencilPathObject | ArrowObject) => void;
	isMinimap?: boolean;
}

const MIN_MOVE_DISTANCE = 80;
const PathTool: React.FC<SvgPathToolProps> = (props) => {
	const [points, setPoints] = useState<Position[]>([]);
	const toolSnap = useSnapshot(toolState);

	const ref = useRef<any>(null);

	const handleMove = useCallback((position: Position, movement: Position) => {
		setPoints((p) => {
			if (p.length === 0) return [position];
			const point1 = p[p.length - 1];
			const point2 = position;
			const deltaX = point2.x - point1.x;
			const deltaY = point2.y - point1.y;
			const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
			if (distance > MIN_MOVE_DISTANCE) return [...p, position];
			return p;
		});
	}, []);

	const handleStart = useCallback((position: Position) => {
		setPoints([]);
		return true;
	}, []);

	const typeProps = toolSnap.properties[MapTool.PENCIL].isArrow
		? ({ layerId: "arrows", type: "arrow" } as {
				layerId: "arrows";
				type: "arrow";
			})
		: ({ layerId: "paths", type: "path" } as {
				layerId: "paths";
				type: "path";
			});

	const handleEnd = useCallback(() => {
		let dragPoints = pickle(points);
		if (dragPoints.length > 10) dragPoints = simplify(dragPoints, 100, true);
		const { x, y, points: boundPoints } = pointsToBounds(dragPoints);

		const arrowId = typeProps.type + "-" + Math.random();
		const pathProps = toolSnap.properties[MapTool.PENCIL];
		const newArrow: ArrowObject | PencilPathObject = {
			id: arrowId,
			...typeProps,
			dragging: false,
			visible: true,
			color: pathProps.color,
			expiry: pathProps.expiry,
			position: {
				x,
				y,
			},
			points: boundPoints,
		};
		setPoints([]);

		props.onAdd(newArrow);
	}, [props.onAdd, points, typeProps]);

	const { handleDragStart } = useDrag({
		onMove: handleMove,
		onStart: handleStart,
		onEnd: handleEnd,
	});

	return (
		<>
			{points.length > 0 && (
				<Path
					onUpdate={() => {}}
					object={{
						position: {
							x: 0,
							y: 0,
						},
						...typeProps,
						color: toolSnap.properties[MapTool.PENCIL].color,
						points: points,
						id: "tmp",
					}}
				/>
			)}
			<rect
				ref={ref}
				onMouseDown={handleDragStart}
				x={props.toolCanvas.x}
				y={props.toolCanvas.y}
				width={props.toolCanvas.width}
				height={props.toolCanvas.height}
				fill={props.isMinimap ? "#ffffff11" : "#ffffff00"}
			/>
		</>
	);
};

export { PathTool };
