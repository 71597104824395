import React from "react";
import { ChampionIcon } from "@shared/components/icons/ChampionIcon";
import { SummonerItemIcon } from "@shared/components/icons/SummonerItemIcon";
import { ItemPurchaseEvent } from "@shared/types/riot";
import { EventRow } from "./EventRow";

export const ItemPurchaseEventRow: React.FC<{ event: ItemPurchaseEvent }> = ({ event }) => {
	return (
		<EventRow event={event}>
			<div className={"flex gap-1 items-center"}>
				<ChampionIcon participantId={event.participantId} width={35} height={35} />
				Bought
				<SummonerItemIcon itemId={event.itemId} width={35} height={35} />
			</div>
		</EventRow>
	);
};
