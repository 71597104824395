import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Popover } from "react-tiny-popover";
import { StaticDataContext } from "../../context/StaticDataContext";
import { Champion } from "../../types/riot";
import { ChampionIcon } from "../icons/ChampionIcon";

interface ChampionSelectorProps {
	onSelectChampion: (selectedChampion: Champion) => void;
}

const ChampionSelector: React.FC<PropsWithChildren<ChampionSelectorProps>> = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Popover
			isOpen={isOpen}
			align={"start"}
			positions={["right"]}
			content={<ChampionSelectorContent onSelectChampion={props.onSelectChampion} />}
			onClickOutside={() => setIsOpen(false)}
		>
			<div className={"cursor-pointer"} onClick={() => setIsOpen(true)}>
				{props.children}
			</div>
		</Popover>
	);
};

const ChampionSelectorContent: React.FC<ChampionSelectorProps> = (props) => {
	const { champions } = useContext(StaticDataContext);
	const [filter, setFilter] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setTimeout(() => {
			inputRef.current?.focus();
		}, 100);
	}, [inputRef.current]);

	const filteredChamps = useMemo(() => {
		return Object.values(champions).filter(
			(c) => filter.length === 0 || c.name.toLowerCase().includes(filter.toLowerCase()),
		);
	}, [filter]);

	return (
		<div className={"p-2 bg-slate-600 border border-black drop-shadow-md rounded "}>
			<div className={"text-white font-bold pb-2"}>Select a Champion</div>
			<div>
				<input
					value={filter}
					ref={inputRef}
					autoFocus
					placeholder={"Filter Champion"}
					onChange={(e) => setFilter(e.currentTarget.value)}
					className={"px-2 rounded bg-slate-300 bg-opacity-20 text-white"}
				/>
			</div>
			<div className={"relative h-96"}>
				<div className={"absolute inset-0 overflow-y-scroll mt-2"}>
					<div className={"flex gap-1 flex-col text-white"}>
						{filteredChamps.map((champion) => (
							<div
								key={champion.id}
								onClick={() => props.onSelectChampion(champion)}
								className={
									"p-1 flex items-center gap-2 hover:bg-slate-500 rounded hover:cursor-pointer"
								}
							>
								<ChampionIcon championId={champion.key} width={32} height={32} />
								{champion.name}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export { ChampionSelector };
