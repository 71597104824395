import React, { useMemo } from "react";
import { useSnapshot } from "valtio";
import { barState, ICON_HALF, X_PADDING } from "@shared/components/NewFrameBar/FramesBar";
import { matchState } from "@shared/states/uiStates";
import { FrameEvent } from "@shared/types/riot";

import TowerIcon from "@assets/img/frame_bar/tower.svg?react";
import InhibIcon from "@assets/img/frame_bar/inhibitor.svg?react";
import DragonIcon from "@assets/img/frame_bar/dragon.svg?react";
import KillIcon from "@assets/img/frame_bar/kill.svg?react";
import { useMatchSnap } from "@shared/Map/frame_hooks";

export interface EventIconsProps {}

const ALLOWED_EVENTS = ["CHAMPION_KILL", "BUILDING_KILL", "ELITE_MONSTER_KILL"];
const SUB_TYPES = ["kill", "tower", "dragon", "herald", "inhibitor", "baron", "elder"];

const EventIcons: React.FC<EventIconsProps> = (props) => {
	const barSnap = useSnapshot(barState);
	const matchSnap = useMatchSnap();

	const events: FrameEvent[] = useMemo(() => {
		if (!matchSnap) return [];

		return matchSnap.timeline.frames.reduce((a, c) => {
			a.push(...c.events.filter((e) => ALLOWED_EVENTS.includes(e.type)));
			return a;
		}, [] as FrameEvent[]);
	}, [matchSnap]);

	// todo d2 this works but it's a mess
	const groupedEventIcons = useMemo(() => {
		if (!matchSnap?.summary.gameDuration) return [];

		const gameDuration = matchSnap.summary.gameDuration;

		if (barSnap.size.width <= 0 || !gameDuration) return [];

		let timeMulti = 1;
		// in some old games the timestamp is in seconds!
		// I might need to increase this until I find the right version in which the API changed
		// if (compareLoLVersion(matchSnap.match.summary.gameVersion, "11.18") <= 0) timeMulti = 1000;
		const barSize = barSnap.size.width - X_PADDING * 2;
		const allEvents = events.map((event) => ({
			x: (barSize * (event.timestamp * timeMulti)) / (gameDuration * 1000),
			event,
		}));

		type BarEvent = { x?: number; events: { x: number; event: FrameEvent }[] };
		const res: BarEvent[] = [];

		let groupStartX = 0;
		allEvents.forEach((event) => {
			if (event.x == 0) {
				res.push({ events: [event] });
			}

			if (event.x > groupStartX + ICON_HALF * 2) {
				res.push({ events: [event] });
				groupStartX = event.x;
			} else {
				if (res.length < 1) res.push({ events: [event] });
				res[res.length - 1].events.push(event);
			}
		});

		return res.map((e: BarEvent) => {
			if (e.events.length == 1) e.x = e.events[0].x;
			else {
				const diff = e.events[e.events.length - 1].x - e.events[0].x;
				e.x = e.events[0].x + diff / 2;
			}
			return e;
		});
	}, [events, barSnap.size.width, matchSnap]);

	return (
		<g>
			{groupedEventIcons.map(({ x, events }) => {
				// todo d1 should we really do this at render time?
				const event_types = events.map((ex) => {
					const e = ex.event;
					if (e.type === "CHAMPION_KILL") return "kill";

					if (e.type === "BUILDING_KILL") {
						if (e.buildingType === "TOWER_BUILDING") return "tower";
						if (e.buildingType === "INHIBITOR_BUILDING")
							// todo f3
							return "inhibitor";
					}
					if (e.type === "ELITE_MONSTER_KILL") {
						if (e.monsterType === "RIFTHERALD") return "herald";
						if (e.monsterType === "BARON_NASHOR") return "baron";
						if (e.monsterType === "DRAGON") {
							if (e.monsterSubType === "ELDER_DRAGON") return "elder";
							return "dragon";
						}
					}

					return "other";
				});

				const top_type = event_types.reduce<number>((a: number, c: string) => {
					const i = SUB_TYPES.indexOf(c);
					if (i > a) return i;
					return a;
				}, -1);

				const type = SUB_TYPES[top_type];

				// after coming out of the algo, x is always valid
				if (type === "kill") {
					return <KillIcon x={x! - 10} key={x} width={20} height={20} y={0} />;
				}
				//
				if (type === "tower") {
					// return <KillFrame x={x!} key={x} icon={tower_icon} />;
					return <TowerIcon x={x! - 10} key={x} width={20} height={20} y={0} />;
				}
				//
				if (type === "inhibitor") {
					return <InhibIcon x={x! - 10} key={x} width={20} height={20} y={0} />;
				}
				if (
					type === "dragon" ||
					type === "baron" ||
					type === "herald" ||
					type === "elder"
				) {
					return <DragonIcon x={x! - 10} key={x} width={20} height={20} y={0} />;
				}
				//
				// if (type === "dragon" || type === "baron" || type === "herald" || type === "elder")
				// 	return <KillFrame x={x!} key={x} icon={dragon_icon} />;
				// return
			})}
		</g>
	);
};

export { EventIcons };
