import classNames from "classnames";
import React, { useMemo } from "react";
import HiddenIcon from "../../../../../assets/icons/eye-hidden.svg?react";
import VisibleIcon from "../../../../../assets/icons/eye-visible.svg?react";
import { ChampionSelector } from "@shared/components/ChampionSelector/ChampionSelector";
import { ChampionIcon } from "@shared/components/icons/ChampionIcon";
import { useChampion } from "@shared/hooks";
import { setTimelineObject } from "@shared/Map/actions";
import { useParticipant } from "@shared/Map/frame_hooks";
import { ChampionObject, MapObject } from "@shared/Map/types";
import { uiState } from "@shared/states/uiStates";
import { Champion } from "@shared/types/riot";

interface ChampionRowProps {
	mapObject: ChampionObject;
	teamIndex: number;
}

const positions = {
	TOP: "Top",
	JUNGLE: "Jungle",
	MIDDLE: "Middle",
	BOTTOM: "Carry",
	UTILITY: "Support",
};

// TODO when hovering a champion, highlight it in the map
export const ChampionRow: React.FC<ChampionRowProps> = (props) => {
	const { mapObject, teamIndex } = props;

	const participant = useParticipant(mapObject.participantId);
	const championId = useMemo(() => {
		if (mapObject.championId) {
			return mapObject.championId;
		}

		return participant?.championId;
	}, [mapObject.championId, participant?.championId]);

	const position = useMemo(() => {
		if (mapObject.role) return positions[mapObject.role];
		if (participant?.teamPosition) return positions[participant.teamPosition];
		return null;
	}, [mapObject.role, participant?.teamPosition]);

	const champ = useChampion(championId);

	const handleVisibilityToggle = (obj: MapObject) => () => {
		const frameIndex = uiState.applyToAllFrames ? -1 : uiState.currentFrameIndex;
		setTimelineObject(frameIndex, "champions", mapObject.id, {
			visible: mapObject.visible == false,
		});
	};

	const handleChampionSelected = (selectedChampion: Champion) => {
		const frameIndex = uiState.applyToAllFrames ? -1 : uiState.currentFrameIndex;
		setTimelineObject(frameIndex, "champions", mapObject.id, {
			championId: selectedChampion.key,
		});
	};

	const textParts: string[] = [];
	if (position) {
		textParts.push(position);
	}
	if (props.mapObject.summonerName) {
		textParts.push(props.mapObject.summonerName);
	}
	if (champ?.name) {
		textParts.push(champ?.name);
	}

	return (
		<div
			className={
				"p-1 bg-slate-500 border-b w-full border-gray-600 flex justify-between items-center " +
				(teamIndex == 1 ? "text-red-200" : "text-blue-200")
			}
		>
			<div className={"flex items-center"}>
				<ChampionSelector onSelectChampion={handleChampionSelected}>
					{championId ? (
						<ChampionIcon championId={championId} width={32} height={32} />
					) : (
						<div
							className={classNames(
								"w-8 h-8 border border-slate-600 rounded flex items-center justify-center text-white font-bold select-none",
								{
									"bg-blue-500": teamIndex === 0,
									"bg-red-500": teamIndex === 1,
								},
							)}
						>
							{position?.substr(0, 1).replace("C", "B")}
						</div>
					)}
				</ChampionSelector>
				<div className={"capitalize ml-2"}>{textParts.join(" - ")}</div>
			</div>
			<button
				className={"p-1 bg-transparent bg-opacity-40 text-white rounded select-none"}
				onClick={handleVisibilityToggle(mapObject)}
			>
				{mapObject.visible == false ? (
					<VisibleIcon width={20} height={20} />
				) : (
					<HiddenIcon width={20} height={20} />
				)}
			</button>
		</div>
	);
};
