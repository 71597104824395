import { ChampionObject } from "@shared/Map/types";
import { useAssetImage, useChampion } from "@shared/hooks";
import { TEAM_COLORS } from "@shared/utils";
import { Draggable } from "../../Draggable";
import { useSettingsSnapshot } from "@shared/states/uiStates";
import { useParticipant } from "@shared/Map/frame_hooks";
import React, { useCallback } from "react";
import { Position } from "@shared/types/riot";
import { SVGImage } from "@shared/Map/objects/SVGImage";

const SIZE = 800;

const RoleNames = {
	UTILITY: "S",
	BOTTOM: "B",
	MIDDLE: "M",
	JUNGLE: "J",
	TOP: "T",
};

interface SVGChampionProps {
	champ: ChampionObject;
	onUpdate: (update: Partial<ChampionObject>) => void;
}

export const Champion: React.FC<SVGChampionProps> = (props) => {
	const participant = useParticipant(props.champ.participantId);
	const settingsSnap = useSettingsSnapshot();
	const championId = props.champ.championId || participant?.championId;
	const scale = settingsSnap.map?.championSize !== undefined ? settingsSnap.map.championSize : 1;

	const handleMove = useCallback((newPosition: Position) => {
		props.onUpdate({ position: newPosition });
	}, []);

	if (props.champ.visible === false) return null;

	return (
		<Draggable position={props.champ.position} onMove={handleMove}>
			<g
				transform={`scale(${scale}) `}
				style={{ opacity: props.champ.alive === false ? 0.5 : 1 }}
			>
				{championId ? (
					<ChampionPortrait
						championId={championId}
						alive={props.champ.alive}
						color={TEAM_COLORS[props.champ.team]}
					/>
				) : (
					<ChampionLetter
						color={TEAM_COLORS[props.champ.team]}
						text={RoleNames[props.champ.role!]}
					/>
				)}
			</g>
		</Draggable>
	);
};

interface ChampionPortraitProps {
	championId: string | number;
	color: string;
	alive?: boolean;
}

const ChampionPortrait: React.FC<ChampionPortraitProps> = ({ championId, color, alive }) => {
	const champ = useChampion(championId);
	const src = useAssetImage(champ?.image, false);

	return (
		<>
			<circle
				cx={0}
				cy={0}
				r={(SIZE / 2) * 1.1}
				fill={color}
				stroke="white"
				strokeWidth={8}
			></circle>
			<SVGImage
				x={-SIZE / 2}
				y={-SIZE / 2}
				width={SIZE}
				height={SIZE}
				href={src!}
				filter={alive === false ? "grayscale(1)" : undefined}
				clipPath={`url(#circle-clip)`}
			/>
		</>
	);
};

const ChampionLetter: React.FC<{ color: string; text: string }> = ({ color, text }) => {
	return (
		<>
			<circle cx={0} cy={0} r={430} fill={color} stroke="white" strokeWidth={8}></circle>
			<text
				x={0}
				y={30}
				width={50}
				alignmentBaseline="middle"
				textAnchor="middle"
				dominantBaseline={"middle"}
				fontSize={SIZE / 2}
				fontWeight={"bold"}
				fill="white"
			>
				{text}
			</text>
		</>
	);
};
