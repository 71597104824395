import React, { useState } from "react";
import { useSnapshot } from "valtio";
import { mapOverlayFramesState, playbookState, sharedSettingsState } from "../../states/frameState";
import { uiState } from "../../states/uiStates";

interface NotesPanelProps {}

const NotesPanel: React.FC<NotesPanelProps> = (props) => {
	const [noteType, setNoteType] = useState<"game" | "frame">("game");
	const sharedSettingsSnap = useSnapshot(sharedSettingsState, { sync: true });
	const playbookSnap = useSnapshot(playbookState);
	const uiSnap = useSnapshot(uiState);
	const overlayFramesSnap = useSnapshot(mapOverlayFramesState, { sync: true });

	const handleUpdate = (val: string) => {
		const { currentFrameIndex } = uiSnap;
		if (!mapOverlayFramesState.frames) return;
		if (noteType === "frame") {
			if (!mapOverlayFramesState.frames[currentFrameIndex])
				mapOverlayFramesState.frames[currentFrameIndex] = {
					frameIndex: uiSnap.currentFrameIndex,
					notes: "",
				};
			mapOverlayFramesState.frames[currentFrameIndex].notes = val;
		} else {
			sharedSettingsState.notes = val;
		}
	};

	let note;
	if (noteType === "frame") {
		note = overlayFramesSnap.frames?.[uiSnap.currentFrameIndex]?.notes || "";
	} else {
		note = sharedSettingsSnap.notes || "";
	}

	return (
		<div className={"h-full flex flex-col"}>
			<div className={"flex gap-2 bg-slate-600 pt-2 mx-2 cursor-pointer "}>
				<div
					className={
						"flex-1 rounded-t text-white p-2 " +
						(noteType === "game" ? "bg-slate-500" : "bg-slate-700 ")
					}
					onClick={() => setNoteType("game")}
				>
					Game Notes
				</div>
				{playbookSnap.game && (
					<div
						className={
							"flex-1 rounded-t text-white p-2 " +
							(noteType === "frame" ? "bg-slate-500" : "bg-slate-700 ")
						}
						onClick={() => setNoteType("frame")}
					>
						Frame Notes
					</div>
				)}
			</div>

			<div className={"bg-slate-500 p-1 flex-1 p-2 flex p-2"}>
				<textarea
					className={"flex-1 rounded bg-slate-400 p-1 m-2 text-white outline-0"}
					placeholder={
						noteType == "frame" ? "Notes for this time frame" : "Notes for this game"
					}
					value={note}
					onChange={(e) => {
						handleUpdate(e.target.value);
					}}
				></textarea>
			</div>
		</div>
	);
};

export { NotesPanel };
