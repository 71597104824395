import React, { useMemo } from "react";

import { useSnapshot } from "valtio";
import SyncOffIcon from "@assets/icons/arrow-sync-off.svg?react";
import SyncOnIcon from "@assets/icons/arrow-sync.svg?react";

import { Tooltip } from "@shared/components/Tooltip";
import { useMapFrameSnap, useMatchSnap } from "@shared/Map/frame_hooks";
import { uiState } from "@shared/states/uiStates";
import { Collapsible } from "../../Collapsible";
import { ChampionRow } from "./ChampionRow";
import { HideTeamButton } from "@shared/Sidebar/panels/ChampsPanel/HideTeamButton";

interface ChampsPanelProps {}

const ChampsPanel: React.FC<ChampsPanelProps> = (props) => {
	const matchSnap = useMatchSnap();
	const uiSnap = useSnapshot(uiState);
	const frame = useMapFrameSnap(uiSnap.currentFrameIndex);

	// TODO disable this for grid/custom games
	const platformId = matchSnap?.summary.platformId;
	const graphsRegion = platformId?.replace(/\d+/, "")?.toLowerCase();
	const graphsGameId = matchSnap?.summary?.gameId;

	const groupedTeams = useMemo(() => {
		const objects = Object.values(frame.champions?.objects || {});

		return [objects.filter((p) => p.team === "blue"), objects.filter((p) => p.team === "red")];
	}, [frame.champions?.objects]);

	const getTeamTitle = (teamIndex: number) => {
		let team = `${teamIndex == 0 ? "Blue" : "Red"} Team`;

		if (!matchSnap) return team;
		let result = matchSnap.summary.teams[teamIndex]?.win ? "Win" : "Loss";

		return `${team} (${result})`;
	};

	return (
		<div className={"flex flex-col justify-between h-full"}>
			<div className={"flex flex-1 relative bg-slate-500"}>
				<div className={"absolute inset-0 overflow-y-auto"}>
					<div>
						{groupedTeams.map((team, teamIndex) => (
							<Collapsible
								title={getTeamTitle(teamIndex)}
								key={teamIndex}
								right={<HideTeamButton team={teamIndex === 0 ? "blue" : "red"} />}
							>
								{team.map((participant) => {
									return (
										<ChampionRow
											key={participant.id}
											teamIndex={teamIndex}
											mapObject={participant}
										/>
									);
								})}
							</Collapsible>
						))}
					</div>
				</div>
			</div>
			{(matchSnap?.timeline.frames.length || 0) > 0 && (
				<div
					className={
						"p-1 bg-slate-400 bg-opacity-20 text-slate-200 flex items-center justify-between"
					}
				>
					<div>
						{graphsRegion && graphsGameId && (
							<a
								href={`https://www.leagueofgraphs.com/match/${graphsRegion}/${graphsGameId}`}
								target={"_blank"}
								rel={"noreferrer noopener"}
							>
								Details
							</a>
						)}
					</div>
					<Tooltip
						content={
							uiSnap.applyToAllFrames
								? "Change are applied to all frames"
								: "Changes are applied only to the current frame"
						}
					>
						<button
							data-tooltip-id={"tooltip-sidebar-sync"}
							onClick={() => (uiState.applyToAllFrames = !uiSnap.applyToAllFrames)}
						>
							{uiSnap.applyToAllFrames ? (
								<SyncOnIcon width={20} height={20} />
							) : (
								<SyncOffIcon width={20} height={20} />
							)}
						</button>
					</Tooltip>
				</div>
			)}
		</div>
	);
};

export { ChampsPanel };
