import * as React from "react";
const SvgBaronSeeing = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 116 101", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("rect", { id: "baron-seeing", x: 0.809, y: 0.09, width: 115, height: 100, style: {
  fill: "#101800",
  fillOpacity: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "baron-seeing1", "serif:id": "baron-seeing" }, /* @__PURE__ */ React.createElement("path", { d: "M31.238,20.654l8.302,-9.105c0,0 24.906,3.214 34.279,14.461c9.373,11.248 2.142,18.211 5.088,23.567c2.946,5.356 16.068,25.441 16.068,25.441l-7.499,2.79c0,0 -1.606,-6.272 -8.837,-15.109c-7.231,-8.837 -13.886,-9.175 -13.886,-9.175c0,0 6.242,-13.526 -2.317,-20.413c-9.492,-7.638 -15.397,-3.619 -21.021,-1.477c-5.624,2.143 -10.177,-10.98 -10.177,-10.98Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} }), /* @__PURE__ */ React.createElement("path", { d: "M30.128,42.088c6.16,8.838 7.718,9.198 3.701,11.267c-4.017,2.069 -5.507,-3.618 -10.328,-7.903c-4.82,-4.284 -15.278,-12.586 -9.655,-16.068c5.624,-3.481 10.123,3.867 16.282,12.704Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} }), /* @__PURE__ */ React.createElement("path", { d: "M65.517,68.055c8.302,6.695 13.034,13.73 8.482,15.873c-4.553,2.142 -3.146,-4.785 -14.126,-10.283c-10.979,-5.498 -14.79,-6.772 -13.521,-11.208c1.12,-3.917 10.863,-1.077 19.165,5.618Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} })));
export default SvgBaronSeeing;
