import React from "react";
import { proxy, useSnapshot } from "valtio";
import { uiState } from "@shared/states/uiStates";
import { useMapFrameSnap } from "@shared/Map/frame_hooks";
import { WardRow } from "@shared/Sidebar/panels/WardsPanel/WardRow";

export interface WardsPanelProps {}

export const wardHighlightState = proxy<{ highlightWardId: string | null }>({
	highlightWardId: null,
});

const WardsPanel: React.FC<WardsPanelProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const frame = useMapFrameSnap(uiSnap.currentFrameIndex);

	return (
		<div className={"flex flex-col h-full"}>
			<div className={"p-1 bg-slate-600 border-b border-slate-700 text-white font-bold"}>
				Wards
			</div>
			<div className={"relative flex-1"}>
				<div className={"absolute inset-0 overflow-y-auto"}>
					{Object.values(frame?.wards?.objects || {}).map((obj) => {
						return <WardRow ward={obj} key={obj.id} />;
					})}
				</div>
			</div>
		</div>
	);
};

export { WardsPanel };
