import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../api/auth";
import { Button } from "../../components/Button";
import { authState } from "../../states/authState";

export interface LoginFormProps {
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<Record<string, string[]>>();
	const navigate = useNavigate();

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		setErrors(undefined);
		setLoading(true);
		const res = await login(email, password);
		setLoading(false);

		if (!res.ok) {
			setErrors(res.errors);
			return;
		}

		authState.accessToken = res.access_token;
		navigate("/");
	};

	return (
		<div className={"flex flex-col w-64"}>
			<form onSubmit={handleLogin}>
				<div className={"flex flex-col mb-4"}>
					<label className={"mb-4"}>
						<div className={"text-xs text-slate-300 my-1"}>Email</div>
						<input
							placeholder={"Email"}
							value={email}
							tabIndex={1}
							onChange={(e) => setEmail(e.currentTarget.value)}
							className={
								"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
							}
						/>
						{errors?.["email"]?.map((e) => (
							<div className={"text-red-400 text-xs"} key={e}>
								{e}
							</div>
						))}
					</label>
					<label className={"mb-4"}>
						<div className={"text-xs text-slate-300 my-1 flex justify-between"}>
							<div>Password</div>
							<Link to={"/request-reset"} className={"text-blue-300"} tabIndex={5}>
								Reset Password
							</Link>
						</div>
						<input
							type={"password"}
							placeholder={"Password"}
							value={password}
							tabIndex={2}
							onChange={(e) => setPassword(e.currentTarget.value)}
							className={
								"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
							}
						/>
						{errors?.["password"]?.map((e) => (
							<div className={"text-red-400 text-xs"} key={e}>
								{e}
							</div>
						))}
					</label>
					{errors?.["auth"]?.map((e) => (
						<div className={"text-red-400 text-xs"} key={e}>
							{e}
						</div>
					))}
				</div>

				<div className={"flex justify-between items-center"}>
					{!loading && <Button type={"submit"} className={"px-6"} onClick={handleLogin} tabIndex={3}>
						Login
					</Button>}
					{loading && <div className={"text-slate-200"}>Loading...</div>}
					<div>
						<Link to={"/register"} className={"text-slate-200 mx-2"} tabIndex={4}>
							Create Account
						</Link>
					</div>
				</div>
			</form>
		</div>
	);
};

export { LoginForm };