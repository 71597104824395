import React, { useContext } from "react";
import { StaticDataContext } from "../../context/StaticDataContext";
import { AssetIcon } from "@shared/components/AssetIcon";

interface SummonerSpellIconProps {
	spellId: number;
	width: number;
	height: number;
}

export const SummonerSpellIcon: React.FC<SummonerSpellIconProps> = ({ spellId, width, height }) => {
	const { summonerSpells } = useContext(StaticDataContext);
	const spell = Object.values(summonerSpells).find((s) => s.key === `${spellId}`);

	if (!spell) return null;

	return <AssetIcon image={spell.image} width={width} height={height} />;
};
