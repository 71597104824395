import React from "react";

export interface ImportCustomGameProps {}

const ImportCustomGame: React.FC<ImportCustomGameProps> = (props) => {
	return (
		<div className={"p-2"}>
			<div className={"rounded p-2 bg-slate-700 text-white"}>
				<div className={"font-bold"}>COMING SOON</div>
				<div className={"text-gray-400"}>
					Importing Custom Games is currently not supported.
				</div>
			</div>
		</div>
	);
};

export { ImportCustomGame };
