import {
	BuildingKill,
	EliteMonsterKillEvent,
	FrameEvent,
	KillEvent,
	Match,
	MatchSummary,
	MatchTimeline,
	ParticipantFrame,
	Position,
	TurretPlateDestroyed,
	WardKilledEvent,
	WardPlacedEvent,
} from "@shared/types/riot";
import {
	GridBuildingDestroyedEvent,
	GridEpicMonsterKillEvent,
	GridEvent,
	GridGameInfoEvent,
	GridGameInfoParticipant,
	GridKillEvent,
	GridMonsterType,
	GridPlateDestroyedEvent,
	GridPosition,
	GridStatusUpdateEvent,
	GridStatusUpdateParticipant,
	GridWardKilledEvent,
	GridWardPlacedEvent,
} from "@shared/modals/ImportGameModal/grid/gridTypes";
import {
	GridBuildingsMap,
	GridDragonTypeMap,
	GridLaneMap,
	GridMonsterTypeMap,
	GridTurretTierMap,
	GridWardMap,
} from "@shared/modals/ImportGameModal/grid/type_maps";
import { StaticDataContextType } from "@shared/context/StaticDataContext";

function distance(pos1: GridPosition, pos2: GridPosition): number {
	const { x: x1, z: y1 } = pos1;
	const { x: x2, z: y2 } = pos2;
	const dx = x2 - x1;
	const dy = y2 - y1;
	return Math.sqrt(dx * dx + dy * dy);
}

const isGameInfoEvent = (event: GridEvent): event is GridGameInfoEvent => {
	return event.rfc461Schema === "game_info";
};
const isStatusUpdateEvent = (event: GridEvent): event is GridStatusUpdateEvent => {
	return event.rfc461Schema === "stats_update";
};

const convertGridEventToFrame = (event: GridStatusUpdateEvent) => {
	const makeParticipantFrame = (p: GridStatusUpdateParticipant): ParticipantFrame => ({
		position: position(p.position),
		participantId: p.participantID,
		level: p.level,
		championStats: {
			health: p.health,
		},
	});

	const participantFrames = event.participants.map((p, i) => [
		String(i + 1),
		makeParticipantFrame(p),
	]);
	return {
		events: [],
		timestamp: event.gameTime,
		participantFrames: Object.fromEntries(participantFrames),
	} as any;
};

const makeSummaryParticipant = (
	value: GridGameInfoParticipant,
	champions: StaticDataContextType["champions"],
) => {
	const champion = champions[value.championName];
	if (!champion || !champion.key) {
		throw new Error("champion not found");
	}

	return {
		teamId: value.teamID,
		championId: parseInt(champion!.key),
		summonerName: value.summonerName,
		participantId: value.participantID,
	};
};
const createSummary = (
	event: GridGameInfoEvent,
	champions: StaticDataContextType["champions"],
): MatchSummary => {
	return {
		gameName: "grid_" + event.gameID,
		participants: event.participants.map((p) => makeSummaryParticipant(p, champions)),
		teams: [
			{ win: false, teamId: 100 },
			{ win: false, teamId: 200 },
		],
	};
};

const position = (p: GridPosition): Position => ({ x: p.x, y: p.z });

const makeKillEvent = (event: GridKillEvent): KillEvent => {
	return {
		killerId: event.killer,
		position: position(event.position),
		type: "CHAMPION_KILL",
		victimId: event.victim,
		timestamp: event.gameTime,
	};
};

const makeTookBuilding = (event: GridBuildingDestroyedEvent): BuildingKill => {
	return {
		assistingParticipantIds: event.assistants,
		bounty: 0,
		buildingType: GridBuildingsMap[event.buildingType],
		towerType: event.turretTier ? GridTurretTierMap[event.turretTier] : undefined,
		killerId: event.lastHitter,
		laneType: GridLaneMap[event.lane],
		position: position(event.position),
		teamId: event.teamID,
		timestamp: event.gameTime,
		type: "BUILDING_KILL",
	};
};

const makeTookPlateEvent = (event: GridPlateDestroyedEvent): TurretPlateDestroyed => {
	return {
		timestamp: event.gameTime,
		laneType: GridLaneMap[event.lane],
		position: position(event.position),
		teamId: event.teamID,
		type: "TURRET_PLATE_DESTROYED",
	} as TurretPlateDestroyed;
};

const wardsPlaced: any[] = [];
const makeWardPlacedEvent = (event: GridWardPlacedEvent): WardPlacedEvent => {
	const creatorId = event.placer;
	const timestamp = event.gameTime;
	const wardType = GridWardMap[event.wardType];
	const pos = position(event.position);
	const wardId = `${pos.x}-${pos.y}`;
	wardsPlaced.push(wardId);
	return {
		creatorId,
		position: pos,
		timestamp,
		wardId: wardId,
		wardType,
		type: "WARD_PLACED",
	};
};

const makeWardKilledEvent = (event: GridWardKilledEvent): WardKilledEvent => {
	const killerId = event.killer;
	const timestamp = event.gameTime;
	const wardType = GridWardMap[event.wardType];
	const pos = position(event.position);
	const wardId = `${pos.x}-${pos.y}`;

	return {
		killerId,
		position: pos,
		timestamp,
		wardId: wardId,
		wardType,
		type: "WARD_KILLED",
	};
};

const makeBlueWardKilledEvent = (
	wardId: string,
	pos: GridPosition,
	timestamp: number,
	killerId: number,
): WardKilledEvent => {
	return {
		killerId,
		position: position(pos),
		timestamp,
		wardId,
		wardType: "BLUE_TRINKET",
		type: "WARD_KILLED",
	};
};

const isEliteMonsterKill = (event: GridEpicMonsterKillEvent) => {
	const validMonsters: GridMonsterType[] = ["baron", "dragon", "riftHerald"];
	return validMonsters.includes(event.monsterType);
};
const makeMonsterKillEvent = (event: GridEpicMonsterKillEvent): EliteMonsterKillEvent => {
	return {
		assistingParticipantIds: [],
		killerId: event.killer,
		killerTeamId: event.killerTeamID,
		monsterSubType: event.dragonType ? GridDragonTypeMap[event.dragonType] : undefined,
		monsterType: GridMonsterTypeMap[event.monsterType],
		position: position(event.position),
		timestamp: event.gameTime,
		type: "ELITE_MONSTER_KILL",
	};
};

const SECONDS_GROUP = 5;
export const convertGridToMatch = (
	events: GridEvent[],
	champions: StaticDataContextType["champions"],
): Match | null => {
	let summary: MatchSummary | null = null;
	let timeline: MatchTimeline = { frames: [] };

	let lastFrameTime = -1;
	let hasLoadedEnd = false;
	let lastEvents: FrameEvent[] = [];
	let schema = new Set<string>();
	let liveBlueWards: { wardId: string; position: GridPosition; teamId: 100 | 200 }[] = [];
	for (const event of events) {
		schema.add(event.rfc461Schema);

		if (isGameInfoEvent(event)) {
			summary = createSummary(event, champions);
		}
		if (event.rfc461Schema === "champion_kill") {
			lastEvents.push(makeKillEvent(event));
		}
		if (event.rfc461Schema === "ward_placed" && event.wardType !== "unknown") {
			const wardEvent = makeWardPlacedEvent(event);
			lastEvents.push(wardEvent);
			if (event.wardType === "blueTrinket") {
				liveBlueWards.push({
					wardId: wardEvent.wardId,
					position: event.position,
					teamId: summary?.participants.find((p) => p.participantId === event.placer)!
						.teamId!,
				});
			}
		}
		if (event.rfc461Schema === "ward_killed" && event.wardType !== "unknown") {
			lastEvents.push(makeWardKilledEvent(event));
		}
		if (event.rfc461Schema === "epic_monster_kill" && isEliteMonsterKill(event)) {
			lastEvents.push(makeMonsterKillEvent(event));
		}
		if (event.rfc461Schema === "building_destroyed") {
			lastEvents.push(makeTookBuilding(event));
		}
		if (event.rfc461Schema === "turret_plate_destroyed") {
			lastEvents.push(makeTookPlateEvent(event));
		}
		if (event.rfc461Schema === "game_end") {
			const winningTeam = summary?.teams.find((t) => t.teamId === event.winningTeam);
			winningTeam!.win = true;
		}
		if (isStatusUpdateEvent(event)) {
			let frameTimeGroup = Math.floor(event.gameTime / 1000 / SECONDS_GROUP);
			if (!hasLoadedEnd && event.gameOver) {
				hasLoadedEnd = true;
				summary!.gameDuration = Math.floor(event.gameTime / 1000);
			}

			// blue wards die when spotted and don't emit a kill event. And that's the only way they die.
			// So we observe the position of all the blue wards ever created
			// and if an enemy gets in 500 unit distance to them, we create a destroy ward event
			for (const ward of liveBlueWards) {
				for (const p of event.participants) {
					if (ward.teamId === p.teamID) continue;
					if (distance(ward.position, p.position) <= 500) {
						lastEvents.push(
							makeBlueWardKilledEvent(
								ward.wardId,
								ward.position,
								event.gameTime,
								p.participantID,
							),
						);
						break;
					}
				}
			}

			if (lastFrameTime === frameTimeGroup || event.gameOver) {
				continue;
			}

			const frame = convertGridEventToFrame(event);
			if (!frame) continue;
			lastFrameTime = frameTimeGroup;
			frame.events = lastEvents;
			timeline.frames.push(frame);
			lastEvents = [];
		}
	}

	if (!summary) {
		return null;
	}

	return { summary, timeline };
};
