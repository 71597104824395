import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { MapTool } from "@shared/types/tools";
import { useSnapshot } from "valtio";
import { toolState } from "@shared/states/uiStates";
import { ColorsSelector } from "@shared/Toolbar/TextButton/TextPopup";
import LinePathIcon from "@assets/icons/line-path.svg?react";
import classNames from "classnames";
import { ArrowPathSvg } from "@assets/icons/ArrowPathSvg";
import { Tooltip } from "@shared/components/Tooltip";

export interface PencilPopupProps {}

const PencilPopup: React.FC<PencilPopupProps> = (props) => {
	const toolSnap = useSnapshot(toolState);
	const toolProps = toolSnap.properties[MapTool.PENCIL];
	const [isOpen, setIsOpen] = useState(false);

	const handleSelectColor = (color: string) => {
		toolState.properties[MapTool.PENCIL].color = color;
	};

	const handleSelectArrow = (arrow: boolean) => () => {
		toolState.properties[MapTool.PENCIL].isArrow = arrow;
	};

	return (
		<>
			<Popover
				isOpen={isOpen}
				positions={["right"]}
				content={
					<div
						className={
							"p-2 bg-slate-600 border border-black drop-shadow-md rounded flex flex-col gap-2"
						}
					>
						<div className={"flex gap-2 justify-around"}>
							<Tooltip content={"Arrow [a]"}>
								<button
									className={classNames(
										" p-1 rounded hover:bg-slate-500 cursor-pointer border border-transparent",
										{
											"bg-slate-700 border !border-slate-400":
												toolProps.isArrow,
										},
									)}
									style={{ color: toolProps.color }}
									onClick={handleSelectArrow(true)}
								>
									<ArrowPathSvg />
								</button>
							</Tooltip>
							<Tooltip content={"Line [L]"}>
								<button
									className={classNames(
										" p-1 rounded hover:bg-slate-500 cursor-pointer border border-transparent",
										{
											"bg-slate-700 !border-slate-400": !toolProps.isArrow,
										},
									)}
									style={{ color: toolProps.color }}
									onClick={handleSelectArrow(false)}
								>
									<LinePathIcon width={30} height={30} />
								</button>
							</Tooltip>
						</div>
						<ColorsSelector color={toolProps.color} onSelect={handleSelectColor} />
					</div>
				}
				onClickOutside={() => setIsOpen(false)}
			>
				<div className={"absolute -right-2 -bottom-2"}>
					<div
						onClick={() => setIsOpen(!isOpen)}
						className={
							"border border-slate-400 w-3 h-3 rounded-full m-2 hover:cursor-pointer"
						}
						style={{ background: toolProps.color }}
					></div>
				</div>
			</Popover>
		</>
	);
};

export { PencilPopup };
