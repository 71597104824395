import React from "react";
import { AssetImage } from "@shared/types/riot";
import { useAssetImage } from "@shared/hooks";

export interface AssetIconProps {
	image: AssetImage;
	width: number;
	height: number;
	className?: string;
	tooltip?: string;
}

const AssetIcon: React.FC<AssetIconProps> = (props) => {
	const url = useAssetImage(props.image);

	return (
		<div
			className={props.className}
			title={props.tooltip}
			style={{
				background: `url(${url}) -${props.image.x}px -${props.image.y}px`,
				zoom: props.width / 48,
				width: 48,
				height: 48,
			}}
		/>
	);
};

export { AssetIcon };
