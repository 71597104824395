import classNames from "classnames";
import React, { PropsWithChildren, useState } from "react";
import { useSnapshot } from "valtio";
import { Button } from "../../components/Button";
import { platformState } from "../../states/uiStates";
import { Modal } from "../Modal";
import { BehaviorSettingsModal } from "./BehaviorSettingsModal";
import { MapSettingsModal } from "./MapSettingsModal";
import { OnlineSettingsModal } from "./OnlineSettingsModal";

interface SettingsModalProps {
	onClose: () => void;
}

const SettingsTab: React.FC<PropsWithChildren<{ selected: boolean; onClick: () => void }>> = (
	props,
) => {
	return (
		<button
			className={classNames(
				{ "border-white bg-slate-600": props.selected },
				"rounded text-white px-4 hover:bg-slate-700",
			)}
			onClick={props.onClick}
		>
			{props.children}
		</button>
	);
};

const SettingsModal: React.FC<SettingsModalProps> = (props) => {
	const platformSnap = useSnapshot(platformState);
	const [currentTab, setCurrentTab] = useState<"online" | "map" | "behavior">("online");
	return (
		<Modal onClose={props.onClose} title={"Settings"}>
			<div className={"flex flex-col w-80 text-white"}>
				<div>
					<div className={"flex justify-center pb-2 border-b border-slate-400"}>
						<div className={"flex gap-4 justify-center p-1 bg-slate-500 rounded "}>
							<SettingsTab
								selected={currentTab === "online"}
								onClick={() => setCurrentTab("online")}
							>
								Share
							</SettingsTab>
							<SettingsTab
								selected={currentTab === "map"}
								onClick={() => setCurrentTab("map")}
							>
								Map Look
							</SettingsTab>
							{platformSnap.platform === "desktop" && (
								<SettingsTab
									selected={currentTab === "behavior"}
									onClick={() => setCurrentTab("behavior")}
								>
									Behavior
								</SettingsTab>
							)}
						</div>
					</div>

					<div>
						{currentTab === "online" && <OnlineSettingsModal />}
						{currentTab === "map" && <MapSettingsModal />}
						{currentTab === "behavior" && <BehaviorSettingsModal />}
					</div>
				</div>
				<div className={"flex justify-between mt-4"}>
					<div>
						<Button onClick={props.onClose}>Close</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export { SettingsModal };
