import React, { useState } from "react";
import { Modal } from "../Modal";
import { ImportSummoner } from "./ImportSummoner";
import { ImportMatchId } from "./ImportMatchId";
import { closeAllModals } from "../../states/uiStates";
import { ImportBayes } from "@shared/modals/ImportGameModal/bayes/ImportBayes";
import { ImportGrid } from "@shared/modals/ImportGameModal/grid/ImportGrid";
import { ImportCustomGame } from "@shared/modals/ImportGameModal/ImportCustomGame";

interface ImportGameModalProps {}

type ImportType = "summoner" | "matchid" | "bayes" | "grid" | "custom"; // "tournamentId"

const ImportGameModal: React.FC<ImportGameModalProps> = (props) => {
	const [importType, setImportType] = useState<ImportType>("summoner");
	const [queueId, setQueueId] = useState<string>("420");

	const handleClose = () => {
		closeAllModals();
	};

	return (
		<Modal
			onClose={handleClose}
			title={
				<div className={"flex justify-between"}>
					<div>Import Game</div>
					<div className={"text-sm font-light pl-4"}>
						<select
							defaultValue={importType}
							onChange={(e) => setImportType(e.currentTarget.value as ImportType)}
							className={"bg-slate-500 rounded outline-none text-white p-1"}
						>
							<option value={"summoner"}>Ranked</option>
							<option value={"grid"}>GRID</option>
							<option value={"matchid"}>Tournament Realm</option>
							<option value={"custom"}>Custom Game</option>
						</select>
					</div>

					{importType === "summoner" && (
						<div className={"text-sm font-light pl-4"}>
							<select
								defaultValue={queueId}
								onChange={(e) => setQueueId(e.currentTarget.value)}
								className={"bg-slate-500 rounded outline-none text-white p-1"}
							>
								<option value={"420"}>Solo Q</option>
								<option value={"440"}>Flex Q</option>
							</select>
						</div>
					)}
				</div>
			}
		>
			<div>
				{importType === "summoner" && <ImportSummoner queueId={queueId} />}
				{importType === "matchid" && <ImportMatchId />}
				{importType === "grid" && <ImportGrid />}
				{importType === "custom" && <ImportCustomGame />}
			</div>
		</Modal>
	);
};

export { ImportGameModal };
