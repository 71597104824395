import React, { createContext, PropsWithChildren, useState } from "react";

export const WelcomeModalContext = createContext<{
	nextEnabled: boolean;
	setNextEnabled: (enable: boolean) => void;
}>({
	nextEnabled: true,
	setNextEnabled: () => {},
});

export const WelcomeModalContextWrapper = ({ children }: PropsWithChildren<{}>) => {
	const [nextEnabled, setNextEnabled] = useState(true);

	const handleNextEnabled = (enabled: boolean) => {
		setNextEnabled(enabled);
	};

	return (
		<WelcomeModalContext.Provider value={{ nextEnabled, setNextEnabled: handleNextEnabled }}>
			{children}
		</WelcomeModalContext.Provider>
	);
};
