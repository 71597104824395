import React from "react";
import { proxy, useSnapshot } from "valtio";
import { useTimelineFrame } from "@shared/Map/frame_hooks";
import { uiState } from "@shared/states/uiStates";
import { EventsList } from "./EventsList";

const filtersState = proxy({ items: true, kills: true, buildings: true, monsters: true });

const EventFilter: React.FC<{ name: keyof typeof filtersState }> = (props) => {
	const filtersSnap = useSnapshot(filtersState);

	const handleClick = () => {
		filtersState[props.name] = !filtersState[props.name];
	};

	return (
		<div
			onClick={handleClick}
			className={
				"rounded hover:bg-slate-500 p-1 select-none cursor-pointer capitalize " +
				(!filtersSnap[props.name] ? "line-through" : "")
			}
		>
			{props.name}
		</div>
	);
};

export const EventsPanel = () => {
	const uiSnap = useSnapshot(uiState);
	const timelineMapFrame = useTimelineFrame(uiSnap.currentFrameIndex);
	const filtersSnap = useSnapshot(filtersState);

	if (!timelineMapFrame?.events) return null;

	//todo f2 TURRET_PLATE_DESTROYED
	return (
		<div className={"flex flex-col h-full"}>
			<div className={"bg-slate-600 pb-1"}>
				<div className={"px-2 text-lg text-white font-bold"}>Events</div>
				<div className={"text-sm flex text-white justify-around"}>
					<EventFilter name={"kills"} />
					<EventFilter name={"items"} />
					<EventFilter name={"buildings"} />
					<EventFilter name={"monsters"} />
				</div>
			</div>
			<div className={"flex flex-1 relative bg-slate-500"}>
				<div className={"absolute inset-0 overflow-y-scroll"}>
					<div className={"border-b border-slate-400 border-opacity-50"}>
						<EventsList
							events={timelineMapFrame.events.filter((e) => {
								return (
									(e.type === "CHAMPION_KILL" && filtersSnap.kills) ||
									(e.type === "ITEM_PURCHASED" && filtersSnap.items) ||
									(e.type === "ELITE_MONSTER_KILL" && filtersSnap.monsters) ||
									(e.type === "BUILDING_KILL" && filtersSnap.buildings)
								);
							})}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
