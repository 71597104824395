import React, { useCallback } from "react";
import { WardObject } from "@shared/Map/types";
import { Position } from "@shared/types/riot";
import { Draggable } from "@shared/Map/Draggable";
import { wardColors } from "@shared/Toolbar/WardButton/WardToolButton";
import { useSnapshot } from "valtio";
import { wardHighlightState } from "@shared/Sidebar/panels/WardsPanel/WardsPanel";
import { useSettingsSnapshot } from "@shared/states/uiStates";
import WardIcon from "@assets/icons/ward.svg?react";

export interface SvgWardProps {
	ward: WardObject;
	onUpdate: (update: Partial<WardObject>) => void;
}

const Ward: React.FC<SvgWardProps> = (props) => {
	const { highlightWardId } = useSnapshot(wardHighlightState);
	const settingsSnap = useSettingsSnapshot();
	const showWardVision = settingsSnap.map?.showWardVision !== false;

	const handleMove = useCallback((newPosition: Position) => {
		props.onUpdate({ position: newPosition });
	}, []);

	const handleMouseOver = () => {
		wardHighlightState.highlightWardId = props.ward.id;
	};

	const handleMouseLeave = () => {
		wardHighlightState.highlightWardId = null;
	};

	if (props.ward.visible === false) {
		return null;
	}

	return (
		<Draggable position={props.ward.position} onMove={handleMove}>
			{showWardVision && (
				<circle
					cx={0}
					cy={0}
					style={{ pointerEvents: "none" }}
					r={800}
					stroke={"#888"}
					opacity={0.2}
					strokeWidth={10}
					fill={props.ward.areaColor === "blue" ? "#00a2ff" : "#ef4b4b"}
				/>
			)}
			<circle
				data-layerid={props.ward.layerId}
				data-objectid={props.ward.id}
				cx={0}
				cy={0}
				r={150}
				stroke={"white"}
				strokeWidth={highlightWardId === props.ward.id ? 30 : 10}
				fill={wardColors[props.ward.color]}
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}
			/>
			{!showWardVision && (
				<WardIcon
					width={200}
					height={200}
					style={{ color: props.ward.areaColor === "blue" ? "#0029ff" : "#ff0000" }}
					x={-100}
					y={-100}
				/>
			)}
			<text x={0} y={-300} fontSize={200} fill={"white"} opacity={0.8} textAnchor={"middle"}>
				{props.ward.topText}
			</text>
			<text x={0} y={400} fontSize={200} fill={"white"} opacity={0.8} textAnchor={"middle"}>
				{props.ward.bottomText}
			</text>
		</Draggable>
	);
};

export { Ward };
