import { BuildingKill, EliteMonsterKillEvent, LaneType, WardType } from "@shared/types/riot";
import {
	GridBuildingType,
	GridDragonType,
	GridLane,
	GridMonsterType,
	GridTurretTier,
	GridWard,
} from "@shared/modals/ImportGameModal/grid/gridTypes";

export const GridLaneMap: Record<GridLane, LaneType> = {
	bot: "BOT_LANE",
	mid: "MID_LANE",
	top: "TOP_LANE",
};

export const GridTurretTierMap: Record<GridTurretTier, BuildingKill["towerType"]> = {
	inner: "INNER_TURRET",
	outer: "OUTER_TURRET",
	base: "BASE_TURRET",
	nexus: "NEXUS_TURRET",
};

export const GridBuildingsMap: Record<GridBuildingType, BuildingKill["buildingType"]> = {
	inhibitor: "INHIBITOR_BUILDING",
	turret: "TOWER_BUILDING",
	nexus: "NEXUS_BUILDING",
};

export const GridWardMap: Record<GridWard, WardType> = {
	unknown: "ZOMBIE", // might not be correct
	yellowTrinket: "YELLOW_TRINKET",
	blueTrinket: "BLUE_TRINKET",
	control: "CONTROL",
	sight: "SIGHT",
};

export const GridMonsterTypeMap: Record<GridMonsterType, EliteMonsterKillEvent["monsterType"]> = {
	baron: "BARON_NASHOR",
	riftHerald: "RIFTHERALD",
	dragon: "DRAGON",
	VoidGrub: "DRAGON",

	// unused for now
	redCamp: "RIFTHERALD",
	blueCamp: "RIFTHERALD",
	krug: "RIFTHERALD",
	gromp: "RIFTHERALD",
	raptor: "RIFTHERALD",
	wolf: "RIFTHERALD",
	scuttleCrab: "RIFTHERALD",
};

export const GridDragonTypeMap: Record<GridDragonType, EliteMonsterKillEvent["monsterSubType"]> = {
	earth: "EARTH_DRAGON",
	chemtech: "CHEMTECH_DRAGON",
	hextech: "HEXTECH_DRAGON",
	air: "AIR_DRAGON",
	water: "WATER_DRAGON",
	fire: "FIRE_DRAGON",

	elder: "ELDER_DRAGON",
};
