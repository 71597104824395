import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../api/auth";
import { Button } from "../../components/Button";
import { authState } from "../../states/authState";

export interface RegisterFormProps {
}

export const RegisterForm: React.FC<RegisterFormProps> = (props) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [marketing, setMarketing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<Record<string, string[]>>();
	const navigate = useNavigate();

	const handleRegister = async (e: React.FormEvent) => {
		e.preventDefault();
		if (password !== password2) {
			setErrors({ password2: ["Passwords don't match"] });
			return;
		}
		setErrors(undefined);
		setLoading(true);
		const res = await register(email, password, marketing);
		setLoading(false);
		if (!res.ok) {
			setErrors(res.errors);
			return;
		}

		authState.accessToken = res.access_token;
		navigate("/");
	};

	return (
		<div className={"flex flex-col w-64"}>
			<form onSubmit={handleRegister}>
				<div className={"flex flex-col mb-4"}>
					<label className={"mb-4"}>
						<div className={"text-xs text-slate-300 my-1"}>Email</div>
						<input
							placeholder={"Email"}
							value={email}
							tabIndex={0}
							onChange={(e) => setEmail(e.currentTarget.value)}
							className={
								"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
							}
						/>
						{errors?.["email"]?.map((e) => (
							<div className={"text-red-400 text-xs"} key={e}>
								{e}
							</div>
						))}
					</label>
					<label className={"mb-4"}>
						<div className={"text-xs text-slate-300 my-1 flex justify-between"}>
							<div>Password</div>
						</div>
						<input
							type={"password"}
							placeholder={"Password"}
							value={password}
							tabIndex={1}
							onChange={(e) => setPassword(e.currentTarget.value)}
							className={
								"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
							}
						/>
						{errors?.["password"]?.map((e) => (
							<div className={"text-red-400 text-xs"} key={e}>
								{e}
							</div>
						))}
					</label>
					<label className={"mb-4"}>
						<div className={"text-xs text-slate-300 my-1 flex justify-between"}>
							<div>Repeat Password</div>
						</div>
						<input
							type={"password"}
							placeholder={"Password"}
							value={password2}
							tabIndex={2}
							onChange={(e) => setPassword2(e.currentTarget.value)}
							className={
								"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
							}
						/>
						{errors?.["password2"]?.map((e) => (
							<div className={"text-red-400 text-xs"} key={e}>
								{e}
							</div>
						))}
					</label>
					<label className={"text-gray-300 mb-2"}>
						<input
							type={"checkbox"}
							className={"mr-2"}
							checked={marketing}
							tabIndex={3}
							onChange={(e) => setMarketing(e.currentTarget.checked)}
						/>
						Receive marketing emails
					</label>
					<a
						href={"https://docs.riftkit.net/docs/privacy/"}
						className={"text-blue-200 underline"}
						target={"_blank"}
						rel={"noreferrer noopener"}
					>
						Privacy Policy
					</a>
				</div>

				<div className={"flex justify-between items-center"}>
					{!loading && <Button
						type={"submit"}
						className={"px-6"}
						onClick={handleRegister}
						tabIndex={3}
					>
						Create Account
					</Button>}
					{loading && <div className={"text-slate-200"}>Loading...</div>}
					<div>
						<Link to={"/login"} className={"text-slate-200 mx-2"} tabIndex={4}>
							Login
						</Link>
					</div>
				</div>
			</form>
		</div>
	);
};
