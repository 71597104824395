import React from "react";
import { useSnapshot } from "valtio";
import { toolState } from "../../states/uiStates";
import { MapTool } from "../../types/tools";
import { ShapePopup } from "./ShapePopup";
import { ToolButton } from "../ToolButton";
import { ShapesFactory } from "@shared/Map/objects/shapes/ShapesFactory";

export interface ShapeToolButtonProps {}

const ShapeToolButton: React.FC<ShapeToolButtonProps> = (props) => {
	const toolSnap = useSnapshot(toolState);

	const handleClick = () => {
		toolState.selectedTool = MapTool.SHAPE;
	};

	return (
		<>
			<ToolButton
				tool={MapTool.SHAPE}
				className={`text-white-400`}
				isSelected={toolSnap.selectedTool === MapTool.SHAPE}
				onClick={handleClick}
				tooltipMessage={"Place shapes on the map [s]"}
				extra={<ShapePopup />}
			>
				<svg width={32} height={32} viewBox={"0 0 32 32"}>
					<ShapesFactory
						shape={toolSnap.properties[MapTool.SHAPE].shape}
						color={"transparent"}
						stroke={"white"}
					/>
				</svg>
			</ToolButton>
		</>
	);
};

export { ShapeToolButton };
