import { bind } from "valtio-yjs";
import * as Y from "yjs";
import { AwareUser } from "../hooks";
import {
	mapOverlayFramesState,
	overlaySettingsState,
	playbookState,
	sharedSettingsState,
} from "../states/frameState";
import { networkState } from "../states/networkState";
import { NetProvider } from "./NetProvider";

let ydoc = new Y.Doc();
let net_provider: NetProvider = new NetProvider(ydoc);

const OVERLAY_DOC_KEY = "overlay";
const FRAME_SETTINGS_DOC_KEY = "settings";
const SHARED_SETTINGS_DOC_KEY = "shared-settings";
const PLAYBOOK_DOC_KEY = "playbook";

const yOverlay = ydoc.getMap(OVERLAY_DOC_KEY);
const yFrameSettings = ydoc.getMap(FRAME_SETTINGS_DOC_KEY);
const ySharedSettings = ydoc.getMap(SHARED_SETTINGS_DOC_KEY);
const yPlaybook = ydoc.getMap(PLAYBOOK_DOC_KEY);

bind(mapOverlayFramesState, yOverlay);
bind(overlaySettingsState, yFrameSettings);
bind(playbookState as any, yPlaybook);
bind(sharedSettingsState, ySharedSettings);

function awarenessListener() {
	const states = net_provider.getAwareness()?.getStates() || new Map();
	networkState.states = Array.from(states.entries() as unknown as [number, AwareUser][]).map(
		([id, s]) => {
			s.clientId = id;
			return s as AwareUser;
		},
	);
}

export function updateAwareness(field: keyof AwareUser, val: any) {
	return net_provider.getAwareness()?.setLocalStateField(field, val);
}

export function connectPlaybook(room: string, username: string) {
	disconnectPlaybook();

	console.log("connected net yjs");
	net_provider.connect(room, username);
	net_provider.getAwareness()?.on("change", awarenessListener);
	awarenessListener();
	networkState.clientId = net_provider.getAwareness()?.clientID;
	networkState.roomName = room;
	networkState.connected = true;
}

export function disconnectPlaybook() {
	console.log("disconnect");
	net_provider.getAwareness()?.off("change", awarenessListener);
	net_provider.disconnect();
	networkState.clientId = null;
	networkState.roomName = "";
	networkState.connected = false;
}
