import * as React from "react";
const SvgHelp = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 150 150", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M53.389,52.083c3.149,-7.279 11.642,-12.785 21.611,-12.5c15.265,0.437 22.932,9.035 22.917,18.75c-0.024,14.576 -14.746,18.75 -22.917,18.75l-0,20.834", style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: "4.55px"
} }), /* @__PURE__ */ React.createElement("circle", { id: "dot", cx: 75, cy: 117.5, r: 3.333, fill: "currentColor" }), /* @__PURE__ */ React.createElement("circle", { id: "around", cx: 75, cy: 75, r: 66.667, style: {
  fill: "none",
  stroke: "currentColor",
  strokeWidth: "4.55px"
} }));
export default SvgHelp;
