import * as React from "react";
const SvgInhibitor = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 101 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-277,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(3.31977,0,0,2.81975,247.871,-17.284)" }, /* @__PURE__ */ React.createElement("g", { id: "blue-inhib1" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.261936,0,0,0.308383,-61.7911,8.44247)" }, /* @__PURE__ */ React.createElement("path", { d: "M327.668,4C353.056,4 373.668,24.612 373.668,50C373.668,75.388 353.056,96 327.668,96C302.28,96 281.668,75.388 281.668,50C281.668,24.612 302.28,4 327.668,4ZM327.668,13.2C347.978,13.2 364.468,29.69 364.468,50C364.468,70.31 347.978,86.8 327.668,86.8C307.357,86.8 290.868,70.31 290.868,50C290.868,29.69 307.357,13.2 327.668,13.2Z", style: {
  fill: "#93abc9"
}, opacity: 0.5 })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.310162,0.365162,-0.310162,0.365162,-71.3701,-114.017)" }, /* @__PURE__ */ React.createElement("rect", { x: 327.518, y: 19.85, width: 30.15, height: 30.15, style: {
  fill: "transparent",
  stroke: "#93abc9",
  strokeWidth: 4
} }))))));
export default SvgInhibitor;
