import React from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps {}

const Button: React.FC<
	ButtonProps &
		React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = (props) => {
	const { className, ...rest } = props;
	const base =
		"bg-slate-700 rounded p-2 cursor-pointer bg-opacity-80 bg-blend-lighten hover:bg-blend-darken hover:bg-opacity-100 active:bg-slate-700 select-none text-white disabled:opacity-30 ";
	const classes = twMerge(base, className);
	return (
		<button className={classes} {...rest}>
			{props.children}
		</button>
	);
};

export { Button };
