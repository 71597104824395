import React, { useEffect, useState } from "react";

const SVGImage: React.FC<React.SVGProps<SVGImageElement>> = (props) => {
	const { href, ...rest } = props;
	const [base, setBase] = useState("");

	// we have to inline the image because when we download
	// we can't use externally linked images
	useEffect(() => {
		const toDataURL = (url: string) =>
			fetch(url)
				.then((response) => response.blob())
				.then(
					(blob) =>
						new Promise<string>((resolve, reject) => {
							const reader = new FileReader();
							reader.onloadend = () => resolve(reader.result as string);
							reader.onerror = reject;
							reader.readAsDataURL(blob);
						}),
				);

		if (!href) return;
		toDataURL(href).then((dataUrl: string) => {
			setBase(dataUrl);
		});
	}, [href]);

	if (!base) return null;
	return <image href={base} {...rest}></image>;
};

export { SVGImage };
