import React from "react";
import { uiState } from "@shared/states/uiStates";
import TowerIcon from "@assets/img/structures/tower.svg?react";
import TowerPlateIcon from "@assets/img/structures/tower_plates.svg?react";
import { Structure } from "@shared/Map/objects/structures/Structure";
import { useRelativeTimestamp } from "@shared/hooks";
import { useSnapshot } from "valtio";
import { Position } from "@shared/types/riot";
import { TowerType } from "@shared/Map/StructuresLayer";
import { useMatchSnap } from "@shared/Map/frame_hooks";

export interface SvgTowerProps {
	position: Position;
	tower: TowerType;
	outer?: boolean;
	highlight?: boolean; // for debug
}

const Tower: React.FC<SvgTowerProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const matchSnap = useMatchSnap();
	const time = useRelativeTimestamp(
		matchSnap?.timeline.frames[uiSnap.currentFrameIndex].timestamp || 0,
	);
	const isOuter = time.minutes < 14 && props.outer;

	if (props.tower.life == 0) return null;

	return (
		<Structure
			highlight={props.highlight}
			position={props.position}
			teamId={props.tower.teamId}
			show={props.tower.life > 0}
			size={800}
		>
			<TowerIcon width={800} height={800} />
			{isOuter && (
				<>
					<TowerPlateIcon width={800} height={800} />
					<text
						x={400}
						y={460}
						textAnchor={"middle"}
						alignmentBaseline={"middle"}
						fill={"currentColor"}
						fontSize={300}
					>
						{props.tower.life}
					</text>
				</>
			)}
		</Structure>
	);
};

export { Tower };
