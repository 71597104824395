import React, { PropsWithChildren, useState } from "react";

interface CollapsibleProps {
	title: string;
	right?: React.ReactElement;
}

const Collapsible: React.FC<PropsWithChildren<CollapsibleProps>> = (props) => {
	const [open, setOpen] = useState(true);
	return (
		<div>
			<div
				className={
					"p-1 bg-slate-600 cursor-pointer border-b border-slate-700 text-white font-bold flex justify-between"
				}
				onClick={() => setOpen(!open)}
			>
				<div>{props.title}</div>
				<div>{props.right}</div>
			</div>
			<div className={"overflow-y-hidden " + (!open ? "h-0" : "")}>{props.children}</div>
		</div>
	);
};

export { Collapsible };
