import classNames from "classnames";
import React, { useContext, useState } from "react";
import { Popover } from "react-tiny-popover";
import { useSnapshot } from "valtio";
import { ALL_SHAPES, ShapeObject } from "../../Map/types";
import { toolState } from "../../states/uiStates";
import { MapTool } from "../../types/tools";
import { BASE_COLORS, ColorBlock } from "../ToolColorPicker";
import { ShapesFactory } from "@shared/Map/objects/shapes/ShapesFactory";
import { StaticDataContext } from "@shared/context/StaticDataContext";

export interface ShapePopupProps {}

const ShapePopup: React.FC<ShapePopupProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const toolSnap = useSnapshot(toolState);
	const toolProps = toolSnap.properties[MapTool.SHAPE];
	const currentColor = toolProps.color;
	const { summonerSpells } = useContext(StaticDataContext);

	const handleSelectColor = (color: string) => {
		toolState.properties[MapTool.SHAPE].color = color;
		setIsOpen(false);
	};

	const handleSelectShape = (shape: ShapeObject["shape"]) => {
		toolState.selectedTool = MapTool.SHAPE;
		toolState.properties[MapTool.SHAPE].shape = shape;
	};

	const handleSelectImage = (url: string) => {
		toolState.selectedTool = MapTool.IMAGE;
		toolState.properties[MapTool.IMAGE].url = url;
	};

	const handleChangeScale = (scale: number) => {
		toolState.properties[MapTool.SHAPE].scale = scale;
	};

	return (
		<>
			<Popover
				isOpen={isOpen}
				positions={["right"]}
				content={({ position, childRect, popoverRect }) => (
					<div
						className={
							"p-2 bg-slate-600 border border-black drop-shadow-md rounded flex flex-col gap-2"
						}
					>
						<input
							type={"range"}
							max={4}
							min={0.1}
							step={0.1}
							value={toolProps.scale}
							onChange={(e) => handleChangeScale(e.currentTarget.valueAsNumber)}
						/>
						<ColorsSelector color={toolProps.color} onSelect={handleSelectColor} />
						<ShapeSelector
							onSelectShape={handleSelectShape}
							selectedShape={toolProps.shape}
						/>
						{/*<div className={"flex gap-1 flex-wrap w-64"}>*/}
						{/*	{Object.entries(summonerSpells)*/}
						{/*		.filter(([k, v]) => v.modes.includes("CLASSIC"))*/}
						{/*		.map(([key, value]) => (*/}
						{/*			<button className={"rounded overflow-hidden"} key={key}>*/}
						{/*				<AssetIcon*/}
						{/*					image={value.image}*/}
						{/*					tooltip={value.name}*/}
						{/*					width={32}*/}
						{/*					height={32}*/}
						{/*				/>*/}
						{/*			</button>*/}
						{/*		))}*/}
						{/*</div>*/}
					</div>
				)}
				onClickOutside={() => setIsOpen(false)}
			>
				<div className={"absolute -right-2 -bottom-2"}>
					<div
						onClick={() => setIsOpen(!isOpen)}
						className={
							"border border-slate-400 w-3 h-3 rounded-full m-2 hover:cursor-pointer"
						}
						style={{ background: currentColor }}
					></div>
				</div>
			</Popover>
		</>
	);
};

export const ColorsSelector: React.FC<{ color: string; onSelect: (color: string) => void }> = (
	props,
) => {
	return (
		<div className={"flex gap-2"}>
			{BASE_COLORS.map((c) => (
				<ColorBlock key={c} color={c} onClick={props.onSelect} />
			))}
		</div>
	);
};

interface ShapeSelectorProps {
	selectedShape: ShapeObject["shape"];
	onSelectShape: (shape: ShapeObject["shape"]) => void;
}

const ShapeSelector: React.FC<ShapeSelectorProps> = (props) => {
	return (
		<div className={"flex gap-1 w-64 flex-wrap "}>
			{ALL_SHAPES.map((s) => (
				<div
					key={s}
					onClick={() => props.onSelectShape(s)}
					className={classNames(
						"rounded hover:bg-slate-500 hover:cursor-pointer items-center flex justify-center",
						{ "bg-slate-500": props.selectedShape == s },
					)}
				>
					<svg width={32} height={32} viewBox={"0 0 32 32"}>
						<ShapesFactory shape={s} color={"white"} />
					</svg>
				</div>
			))}
		</div>
	);
};
export { ShapePopup };
