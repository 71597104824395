import { proxy } from "valtio";
import { AwareUser } from "../hooks";

interface NetworkState {
	connected: boolean;
	roomName: string;
	clientId?: number | null;
	states: AwareUser[];
}

export const networkState = proxy<NetworkState>({
	connected: false,
	roomName: "",
	clientId: null,
	states: [],
});