import React, { useState } from "react";
import { useNetworkUsername } from "../../network/hooks";
import { useThrottledCallback } from "@react-hookz/web";

interface OnlineSettingsModalProps {}

const OnlineSettingsModal: React.FC<OnlineSettingsModalProps> = (props) => {
	const username = useNetworkUsername();
	const [name, setName] = useState(username || "");

	const updateValue = useThrottledCallback(
		(newName: string) => {
			localStorage.setItem("username", newName);
		},
		[],
		500,
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newName = e.target.value;
		setName(newName);

		if (newName.length > 3) {
			updateValue(newName);
		}
	};

	// todo a setting to say if to display position colors or players' colors
	// todo allow choosing the colors for positions
	return (
		<div>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center"}>
					Username
					<input
						value={name}
						onChange={handleChange}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
				{name.length < 3 && (
					<div className={"text-red-500 text-sm"}>
						Username needs to be at least 3 characters long
					</div>
				)}
			</div>
		</div>
	);
};

export { OnlineSettingsModal };
