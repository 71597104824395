import React, { useEffect, useState } from "react";
import { fetchGameSummary } from "../../api";
import { ChampionIcon } from "../../components/icons/ChampionIcon";
import { SummonerItemIcon } from "../../components/icons/SummonerItemIcon";
import { SummonerRuneIcon } from "../../components/icons/SummonerRuneIcon";
import { SummonerSpellIcon } from "../../components/icons/SummonerSpellIcon";
import { useTimeSince } from "../../hooks";
import { MatchSummary, RegionV5 } from "../../types/riot";
import { useApi } from "@shared/api/hooks";

interface GameSummaryProps {
	gameId: string;
	region: RegionV5;
	playerId: string;
	onClick: (summary: MatchSummary) => void;
}

const GameSummaryView: React.FC<GameSummaryProps> = (props) => {
	const { loading, request } = useApi(fetchGameSummary);
	const [summary, setSummary] = useState<MatchSummary | null>(null);

	useEffect(() => {
		const load = async () => {
			const res = await request(props.region, props.gameId);
			setSummary(res.summary);
		};

		load();
	}, [props.gameId]);

	if (loading || !summary) {
		return <div className={"text-white my-2"}>Loading...</div>;
	}

	return (
		<GameSummaryDetails playerId={props.playerId} summary={summary} onClick={props.onClick} />
	);
};

interface GameSummaryDetailsProps {
	summary: MatchSummary;
	playerId: string;
	onClick: GameSummaryProps["onClick"];
}

const GameSummaryDetails: React.FC<GameSummaryDetailsProps> = ({ onClick, summary, playerId }) => {
	const player = summary.participants.find((p) => p.puuid === playerId)!;
	const when = useTimeSince(summary.gameEndTimestamp! || summary.gameStartTimestamp!);

	if (!player) {
		// some games can be "abort_unexpected" and therefore we need to bail
		// to avoid crashing
		return null;
	}

	return (
		<div
			className={"p-2 rounded hover:bg-slate-500 cursor-pointer"}
			onClick={() => onClick(summary)}
			data-testid={"import-game-" + summary.gameId}
		>
			<div className={"flex flex-col gap-2"}>
				<div className={"flex gap-2"}>
					<div className={"text-sm flex-1"}>
						<div className={"text-gray-300"}>{when}</div>
						<div>
							{player.win ? (
								<div className={"font-bold text-blue-300"}>Victory</div>
							) : (
								<div className={"font-bold text-red-300"}>Defeat</div>
							)}
						</div>

						<div className={"text-white font-bold"}>
							<span className={"text-green-400"}>{player.kills}</span>/
							<span className={"text-red-400"}>{player.deaths}</span>/
							<span className={"text-blue-400"}>{player.assists}</span>
						</div>
					</div>
					<div className={"relative"}>
						<ChampionIcon championId={player.championId} width={60} height={60} />
						<div className={"bg-black text-white absolute right-0 bottom-0"}>
							{player.champLevel}
						</div>
					</div>
					<div className={"flex flex-col gap-1"}>
						<div className={"flex gap-1"}>
							<SummonerSpellIcon
								spellId={player.summoner1Id!}
								width={20}
								height={20}
							/>
							<SummonerSpellIcon
								spellId={player.summoner2Id!}
								width={20}
								height={20}
							/>
						</div>
						<div className={"flex gap-1"}>
							<SummonerRuneIcon
								runeId={player.perks!.styles[0].selections[0].perk}
								width={20}
								height={20}
							/>
							<SummonerRuneIcon
								runeId={player.perks!.styles[1].selections[0].perk}
								width={20}
								height={20}
							/>
						</div>
					</div>
				</div>
				<div className={"flex gap-1 justify-end"}>
					<SummonerItemIcon itemId={player.item0!} width={30} height={30} />
					<SummonerItemIcon itemId={player.item1!} width={30} height={30} />
					<SummonerItemIcon itemId={player.item2!} width={30} height={30} />
					<SummonerItemIcon itemId={player.item3!} width={30} height={30} />
					<SummonerItemIcon itemId={player.item4!} width={30} height={30} />
					<SummonerItemIcon itemId={player.item5!} width={30} height={30} />
				</div>
			</div>
		</div>
	);
};

export { GameSummaryView };
