import React from "react";
import { useSnapshot } from "valtio";
import WardIcon from "../../../../assets/icons/ward.svg?react";
import { WardObject } from "../../Map/types";
import { toolState } from "../../states/uiStates";
import { MapTool } from "../../types/tools";
import { ToolButton } from "../ToolButton";
import { WardSelectorPopup } from "./WardSelectorPopup";

interface Props {}

export const wardColors: Record<WardObject["color"], string> = {
	green: "#269626",
	pink: "#da2254",
	blue: "#166cee",
	yellow: "#eeaa16",
};

export const wardAreaColors: Record<WardObject["areaColor"], string> = {
	blue: "rgba(22,108,238,0.4)",
	red: "rgba(238,22,22,0.2)",
};

export const WardToolButton: React.FC<Props> = (props) => {
	const toolSnap = useSnapshot(toolState);

	const handleClick = () => {
		toolState.selectedTool = MapTool.WARD;
	};

	const handleColorSelect = (color: WardObject["color"], area: WardObject["areaColor"]) => {
		toolState.properties[MapTool.WARD].color = color;
		toolState.properties[MapTool.WARD].areaColor = area;
	};

	const currentColor = toolSnap.properties[MapTool.WARD].color;
	const currentAreaColor = toolSnap.properties[MapTool.WARD].areaColor;

	return (
		<>
			<ToolButton
				tool={MapTool.WARD}
				className={`text-white-400`}
				isSelected={toolSnap.selectedTool === MapTool.WARD}
				onClick={handleClick}
				tooltipMessage={"Place wards on the map [w]"}
				extra={
					<WardSelectorPopup
						currentColor={currentColor}
						currentArea={currentAreaColor}
						onSelect={handleColorSelect}
					/>
				}
			>
				<WardIcon width={32} height={32} />
			</ToolButton>
		</>
	);
};
