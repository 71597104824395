// this needs to match BayesMatchSnapshotPayloadPlayer
export interface ParticipantFrame {
	level: number;
	// currentGold: number;
	// xp: number;
	// totalGold: number;
	position: { x: number; y: number };
	participantId: number;
	championStats: {
		health: number;
	};
}

export interface BaseFrameEvent {
	timestamp: number;
}

export interface PauseEvent extends BaseFrameEvent {
	type: "PAUSE_END";
}

export interface Position {
	x: number;
	y: number;
}

export interface EliteMonsterKillEvent extends BaseFrameEvent {
	type: "ELITE_MONSTER_KILL";
	assistingParticipantIds: number[];
	bounty?: number;
	killerId: number;
	killerTeamId: 100 | 200;
	monsterType: "RIFTHERALD" | "DRAGON" | "BARON_NASHOR";
	monsterSubType?:
		| "EARTH_DRAGON"
		| "FIRE_DRAGON"
		| "AIR_DRAGON"
		| "ELDER_DRAGON"
		| "HEXTECH_DRAGON"
		| "WATER_DRAGON"
		| "CHEMTECH_DRAGON";
	position: Position;
}

export type WardType =
	| "SIGHT" // from support item
	| "YELLOW_TRINKET"
	| "CONTROL"
	| "BLUE_TRINKET"
	| "GHOST_PORO" // from rune
	| "ZOMBIE" // from rune
	| "EFFIGY"; // fiddlesticks

export interface WardPlacedEvent extends BaseFrameEvent {
	wardId: string;
	creatorId: number;
	type: "WARD_PLACED";
	wardType: WardType;
	position?: Position;
}

export interface WardKilledEvent extends BaseFrameEvent {
	wardId: string;
	creatorId?: number; // grid doesn't tell us
	killerId: number;
	type: "WARD_KILLED";
	wardType: WardType;
	position: Position;
}

export interface ItemPurchaseEvent extends BaseFrameEvent {
	participantId: number;
	itemId: number;
	type: "ITEM_PURCHASED";
}

export interface KillEvent extends BaseFrameEvent {
	assistingParticipantIds?: number[];
	bounty?: number;
	killStreakLength?: number;
	position: Position;
	shutdownBounty?: number;
	type: "CHAMPION_KILL";
	victimId: number; // participantId
	killerId: number;
}

export type LaneType = "BOT_LANE" | "MID_LANE" | "TOP_LANE";

export interface TurretPlateDestroyed extends BaseFrameEvent {
	killerId: number | null;
	laneType: LaneType;
	position: Position;
	teamId: 100 | 200;
	type: "TURRET_PLATE_DESTROYED";
}

export interface BuildingKill extends BaseFrameEvent {
	assistingParticipantIds: number[];
	bounty: number;
	buildingType: "TOWER_BUILDING" | "INHIBITOR_BUILDING" | "NEXUS_BUILDING";
	killerId: number | null;
	laneType: LaneType;
	position: Position;
	teamId: 100 | 200;
	towerType?: "OUTER_TURRET" | "INNER_TURRET" | "BASE_TURRET" | "NEXUS_TURRET";
	type: "BUILDING_KILL";
}

// "position": {
// 	"x": 1748,
// 		"y": 2270
// },
// "position": {
// 	"x": 2177,
// 		"y": 1807
// },

export type FrameEvent =
	| PauseEvent
	| KillEvent
	| WardPlacedEvent
	| WardKilledEvent
	| ItemPurchaseEvent
	| EliteMonsterKillEvent
	| TurretPlateDestroyed
	| BuildingKill;

export interface MatchFrame {
	events: FrameEvent[];
	timestamp: number;
	participantFrames: {
		"1": ParticipantFrame;
		"2": ParticipantFrame;
		"3": ParticipantFrame;
		"4": ParticipantFrame;
		"5": ParticipantFrame;
		"6": ParticipantFrame;
		"7": ParticipantFrame;
		"8": ParticipantFrame;
		"9": ParticipantFrame;
		"10": ParticipantFrame;
	};
}

export type TeamPosition = "MIDDLE" | "BOTTOM" | "UTILITY" | "TOP" | "JUNGLE";

// some of the optionals are used for showing the little history preview
export interface MatchParticipant {
	participantId: number;
	championId: number;
	championName?: string;
	summonerName?: string;
	champLevel?: number;
	item0?: number;
	item1?: number;
	item2?: number;
	item3?: number;
	item4?: number;
	item5?: number;
	item6?: number;
	summoner1Id?: number;
	summoner2Id?: number;
	win?: boolean;
	lane?: "MIDDLE" | "BOTTOM" | "TOP" | "JUNGLE";
	role?: "SOLO" | "NONE" | "CARRY" | "SUPPORT";
	teamPosition?: TeamPosition;
	teamId: 100 | 200;
	puuid?: string;
	kills?: number;
	deaths?: number;
	assists?: number;
	perks?: {
		statPers: {};
		styles: { description: string; selections: { perk: number }[] }[];
	};
}

export interface MatchTeam {
	teamId: 100 | 200;
	win: boolean;
	// bans: { championId: number; pickTurn: number };
	// objectives:{championId:number;pickTurn:number;};
}

export interface MatchSummary {
	participants: MatchParticipant[];
	gameVersion?: string;
	gameCreation?: number;
	gameDuration?: number;
	gameEndTimestamp?: number; // some people might miss this?
	gameId?: number;
	platformId?: RegionV4;
	gameMode?: "CLASSIC";
	gameName?: string;
	gameType?: "MATCHED_GAME";
	gameStartTimestamp?: number;
	queueId?: 420;
	mapId?: number;
	teams: MatchTeam[];
}

export interface MatchTimeline {
	frames: MatchFrame[];
}

export interface Match {
	summary: MatchSummary;
	timeline: MatchTimeline;
}

export interface SpectatorGame {
	gameId: number;
	platformId: RegionV4;
	gameMode: "CLASSIC";
	gameStartTime: number;
	gameLength: 1556;
	gameQueueConfigId: 420;
	gameType: "MATCHED_GAME";
	mapId: number;
	participants: SpectatorParticipant[];
}

export interface SpectatorParticipant {
	bot: boolean;
	championId: number;
	gameCustomizationObjects: [];
	perks: {
		perkIds: number[];
		perkStyle: number;
		perkSubStyle: number;
	};
	profileIconId: number;
	spell1Id: number;
	spell2Id: number;
	summonerId: string;
	summonerName: string;
	teamId: 100 | 200;
}

export interface Summoner {
	accountId: string;
	id: string;
	name: string;
	profileIconId: number;
	puuid: string;
	revisionDate: number;
	summonerLevel: number;
}

export interface AssetImage {
	full: string;

	sprite: string;
	group: "spell" | "champion" | "item";
	h: number;
	w: number;
	x: number;
	y: number;
}

export interface Champion {
	id: string;
	key: string;
	name: string;
	image: AssetImage;
}

export interface SummonerSpell {
	id: string;
	key: string;
	modes: (string | "CLASSIC")[];
	image: AssetImage;
	name: string;
}

export interface Item {
	name: string;
	plaintext: string;
	image: AssetImage;
}

export interface Rune {
	name: string;
	id: number;
	icon: string;
	key: string;
}

export interface RuneTreeSlot {
	runes: Rune[];
}

export interface RuneTree {
	icon: string;
	id: number;
	slots: RuneTreeSlot[];
}

export type RegionV5 = "americas" | "europe" | "asia" | "sea";
export type RegionV4 =
	// america
	| "NA1"
	| "BR1"
	| "LA1"
	| "LA2"

	// europe
	| "EUW1"
	| "EUN1"
	| "TR1"
	| "RU"

	//asia
	| "JP1"
	| "KR"

	// sea
	| "OC1"
	| "PH2"
	| "SG2"
	| "TH2"
	| "TW2"
	| "VN2";

export const REGIONS_V4: Record<RegionV4, string> = {
	EUW1: "EUW",
	EUN1: "EUN",
	NA1: "NA",
	JP1: "JP",
	KR: "KR",
	BR1: "BR",
	LA1: "LAN",
	LA2: "LAS",
	OC1: "OCE",
	TR1: "TR",
	RU: "RU",
	PH2: "PH",
	SG2: "SG",
	TH2: "TH",
	TW2: "TW",
	VN2: "VN",
};

export const REGIONS_V5: Record<RegionV5, string> = {
	europe: "Europe",
	americas: "Americas",
	asia: "Asia",
	sea: "SEA",
};
