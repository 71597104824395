import React, { useMemo, useState } from "react";
import DownloadIcon from "@assets/icons/download.svg?react";
import CopyIcon from "@assets/icons/copy.svg?react";
import { RIFT_SIZE } from "@shared/Map/Map";
import { NewToolbarButton } from "@shared/Toolbar/NewToolbarButton";
import { useSnapshot } from "valtio";
import { useHasTier } from "@shared/states/authState";
import { platformState } from "@shared/states/uiStates";

export interface SaveImageToolButtonProps {}

const SaveImageToolButton: React.FC<SaveImageToolButtonProps> = (props) => {
	const platformSnap = useSnapshot(platformState);
	const isPro = useHasTier("Diamond");

	const saveSize = useMemo(() => {
		return isPro ? 1600 : 800;
	}, [isPro]);

	const getMapCanvas = async () => {
		return new Promise<HTMLCanvasElement>((res) => {
			const svgString = document.querySelector("#map-viewer g")!.innerHTML;
			const svgData = `<svg xmlns="http://www.w3.org/2000/svg" 
				font-family="ui-sans-serif, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'">
				<g transform="scale(${saveSize / RIFT_SIZE})">${svgString}</g>
			</svg>`;
			const svgDataBase64 = btoa(unescape(encodeURIComponent(svgData)));
			const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`;

			const image = new Image();

			image.addEventListener("load", () => {
				const width = saveSize;
				const height = saveSize;
				const canvas = document.createElement("canvas");

				canvas.setAttribute("width", width + "px");
				canvas.setAttribute("height", height + "px");

				const context = canvas.getContext("2d")!;
				context.drawImage(image, 0, 0, width, height);

				res(canvas);
			});

			image.src = svgDataUrl;
		});
	};

	const triggerDownload = (uri: string, name: string) => {
		name = name.replace(/[/\\?%*:|"<>]/g, "_");
		const link = document.createElement("a");
		link.download = name;
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleDownload = async () => {
		const canvas = await getMapCanvas();
		const data = canvas.toDataURL("image/jpeg", 0.9);
		triggerDownload(data, "lol_map.jpg");
	};

	const handleCopy = async () => {
		const canvas = await getMapCanvas();

		const item = new ClipboardItem({
			"image/png": (async () => {
				const blobData = await new Promise<Blob>((resolve) => {
					canvas.toBlob((blob) => {
						resolve(blob!);
					});
				});

				if (!blobData) {
					throw new Error();
				}

				return blobData;
			})(),
		});
		await navigator.clipboard.write([item]);
	};

	const [showCopy, setShowCopy] = useState(false);
	const handleEnter = () => {
		setShowCopy(true);
	};
	const handleLeave = () => {
		setShowCopy(false);
	};

	if (platformSnap.platform === "desktop") return null;

	return (
		<NewToolbarButton
			onClick={handleDownload}
			testId={"toolbar-download-image"}
			tooltipMessage={"Download current map image"}
			onEnter={handleEnter}
			onLeave={handleLeave}
			extra={
				<>
					{showCopy && (
						<div
							onClick={handleCopy}
							onMouseEnter={handleEnter}
							onMouseLeave={handleLeave}
							className={
								"absolute right-0 bottom-0 text-white rounded-full bg-slate-600 border border-slate-400 hover:bg-slate-500 hover:cursor-pointer w-5 h-5 flex items-center justify-center"
							}
						>
							<CopyIcon width={12} height={12} />
						</div>
					)}
				</>
			}
		>
			<DownloadIcon width={32} height={32} />
		</NewToolbarButton>
	);
};

export { SaveImageToolButton };
