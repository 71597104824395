import React from "react";
import { TimeTick } from "@shared/components/NewFrameBar/Ticks/utils";
import { useRelativeTimestamp } from "@shared/hooks";

export interface TickCursorProps {
	tick: TimeTick;
	color?: string;
	showTime: boolean;
}

const TickCursor: React.FC<TickCursorProps> = ({ tick, color, showTime }) => {
	const time = useRelativeTimestamp(tick.timestamp);
	return (
		<>
			<path
				style={{ pointerEvents: "none" }}
				fill={color || "#98bdd7"}
				stroke={"black"}
				transform={`translate(${-6 + tick.x}, 18) `}
				d="M 10 12 L 20 0 L 0 0 Z"
			/>
			{showTime && (
				<text
					x={tick.x + 3}
					style={{ pointerEvents: "none" }}
					textAnchor={"middle"}
					y={55}
					fill={"#93abc9"}
					fontSize={12}
					width={50}
				>
					{time.timeText}
				</text>
			)}
		</>
	);
};

export { TickCursor };
