import { useEffect } from "react";
import { REGIONS_V5, RegionV4, RegionV5 } from "./types/riot";
import { useThrottledCallback } from "@react-hookz/web";

export function randomHSL({ saturation = 70, luminosity = 70, opacity = 0.8 }) {
	return `hsla(${~~(360 * Math.random())},${saturation}%,${luminosity}%,${opacity})`;
}

export function usePageResize(callback: () => void, debounce: number) {
	const resizeThrottle = useThrottledCallback(
		() => {
			callback();
		},
		[callback],
		debounce,
	);

	return useEffect(() => {
		window.addEventListener("resize", resizeThrottle);
		return () => {
			window.removeEventListener("resize", resizeThrottle);
		};
	}, [callback]);
}

export const convertTimestampToTime = (time: number) => {
	if (time <= 0) return { seconds: 0, minutes: 0, hours: 0, timeText: "00:00" };

	const seconds = Math.floor((time / 1000) % 60);
	const secondsText = ("" + seconds).padStart(2, "0");
	const minutes = Math.floor((time / 1000 / 60) % 60);
	const minutesText = ("" + minutes).padStart(2, "0");
	const hours = Math.floor(time / 1000 / 60 / 60);
	const hoursTxt = ("" + hours).padStart(2, "0");

	const timeText = (hours > 0 ? `${hoursTxt}:` : "") + `${minutesText}:${secondsText}`;

	return { seconds, minutes, hours, timeText };
};

export function pickle<T>(obj: T): T {
	if (obj === undefined || obj === null) return obj;
	return JSON.parse(JSON.stringify(obj));
}

export function isRegionV5(region: RegionV4 | RegionV5): region is RegionV5 {
	return Object.keys(REGIONS_V5).includes(region.toLowerCase());
}

export function normalizeRegionToV5(region: RegionV4 | RegionV5): RegionV5 {
	if (isRegionV5(region)) {
		return region;
	}

	const lv4 = region.toLowerCase();
	if (lv4 === "euw1" || lv4 === "eun1" || lv4 === "tr1" || lv4 === "ru") return "europe";
	if (lv4 === "na1" || lv4 === "la1" || lv4 == "la2" || lv4 === "br1") return "americas";
	if (lv4 === "kr" || lv4 === "jp1") return "asia";
	return "sea";
}

export function regionV4toV5(v4: RegionV4): RegionV5 {
	const lv4 = v4.toLowerCase();
	if (lv4 === "euw1" || lv4 === "eun1" || lv4 === "tr1" || lv4 === "ru") return "europe";
	if (lv4 === "na1" || lv4 === "la1" || lv4 == "la2" || lv4 === "br1") return "americas";
	if (lv4 === "kr" || lv4 === "jp1") return "asia";
	return "sea";
}

export function compareLoLVersion(a: string, b: string) {
	const an = a.split(".").map(parseInt);
	const bn = b.split(".").map(parseInt);

	for (let i = 0; i < an.length; i++) {
		if (an[i] > bn[i]) return 1;
		if (an[i] < bn[i]) return -1;
	}

	return 0;
}

export const round = (v: number, decimals: number = 2) =>
	Math.round(v * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const TEAM_COLORS = {
	blue: "#1e79ff",
	red: "#c54423",
};

export const trackEvent = (action: string) => {
	(window as any).agr?.collect({ event: action });
};
