import React, { PropsWithChildren, useState } from "react";
import { ArrowContainer, Popover } from "react-tiny-popover";

interface TooltipProps {
	content: React.ReactElement | string;
}

const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = (props) => {
	const [open, setOpen] = useState(false);

	return (
		<Popover
			isOpen={open}
			onClickOutside={() => setOpen(false)}
			content={({ position, childRect, popoverRect }) => (
				<ArrowContainer
					position={position}
					childRect={childRect}
					popoverRect={popoverRect}
					arrowColor={"#18181b"}
					arrowSize={10}
					arrowStyle={{ opacity: 0.75 }}
					className="popover-arrow-container"
					arrowClassName="popover-arrow"
				>
					<div className={"opacity-90 bg-zinc-900 text-white text-sm p-2 rounded"}>
						{props.content}
					</div>
				</ArrowContainer>
			)}
		>
			<div
				onMouseEnter={() => {
					setOpen(true);
				}}
				onMouseLeave={() => {
					setOpen(false);
				}}
			>
				{props.children}
			</div>
		</Popover>
	);
};

export { Tooltip };
