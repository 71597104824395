import { SettingsState } from "../states/uiStates";
import { RegionV5 } from "../types/riot";

export function storeSettings(settings: SettingsState) {
	store("settings", settings);
}

export function loadSettings() {
	return get("settings", {}) as SettingsState;
}

export function storeLastSearch(name: string, region: RegionV5) {
	store("last_search_2", { name, region });
}

export function loadLastSearch() {
	return get("last_search_2", {}) as { name: string; region: RegionV5 };
}

function store(key: string, value: any) {
	localStorage.setItem(key, JSON.stringify(value));
}

function get(key: string, def: any) {
	const item = localStorage.getItem(key);
	if (!!item) return JSON.parse(item);
	return def;
}
