import { round } from "../utils";

import { Position } from "@shared/types/riot";

export const getPointFromScreenCoordinates = (vtm: DOMMatrix, x: number, y: number) => {
	let svgPoint = new DOMPoint();
	svgPoint.x = x;
	svgPoint.y = y;
	return svgPoint.matrixTransform(vtm.inverse());
};

export function pointsToBounds(points: Position[]) {
	const x = points.reduce((a, c) => (c.x < a ? c.x : a), Number.MAX_VALUE);
	const y = points.reduce((a, c) => (c.y < a ? c.y : a), Number.MAX_VALUE);

	const newPoints = points.map((p) => ({
		x: round(p.x - x),
		y: round(p.y - y),
	}));

	return {
		x: round(x),
		y: round(y),
		points: newPoints,
	};
}

export function calculateLineLength(points: Position[]) {
	let length = 0;

	for (let i = 1; i < points.length; i++) {
		const pointA = points[i - 1];
		const pointB = points[i];

		const deltaX = pointB.x - pointA.x;
		const deltaY = pointB.y - pointA.y;

		length += Math.sqrt(deltaX * deltaX + deltaY * deltaY);
	}

	return length;
}
