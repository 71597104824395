import React, { useCallback } from "react";
import { ShapeObject } from "@shared/Map/types";
import { ShapesFactory } from "@shared/Map/objects/shapes/ShapesFactory";
import { Draggable } from "@shared/Map/Draggable";
import { Position } from "@shared/types/riot";
import { useSnapshot } from "valtio";
import { toolState } from "@shared/states/uiStates";

export interface ShapeProps {
	shape: ShapeObject;
	onUpdate: (update: Partial<ShapeObject>) => void;
}

const Shape: React.FC<ShapeProps> = (props) => {
	const handleMove = useCallback((newPosition: Position) => {
		props.onUpdate({ position: newPosition });
	}, []);

	const translate = 14 * props.shape.scale * 20;
	return (
		<Draggable position={props.shape.position} onMove={handleMove}>
			<g
				transform={`translate(-${translate} -${translate}) scale(${
					props.shape.scale * 20
				})`}
			>
				<ShapesFactory
					color={props.shape.color}
					shape={props.shape.shape}
					objId={props.shape.id}
				/>
			</g>
		</Draggable>
	);
};

export { Shape };
