import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import ShareIcon from "../../../../assets/icons/share.svg?react";
import { NewToolbarButton } from "../NewToolbarButton";
import { SharePopup } from "./SharePopup";

interface SharePopupProps {}

const ShareToolbarButton: React.FC<SharePopupProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = (e: React.MouseEvent) => {
		setIsOpen(true);
	};

	return (
		<Popover
			isOpen={isOpen}
			positions={["right"]}
			content={<SharePopup />}
			padding={10}
			onClickOutside={() => setIsOpen(false)}
		>
			<div>
				<NewToolbarButton
					onClick={handleClick}
					tooltipMessage={"Collaborate on this Map"}
					testId="toolbar-btn-share"
				>
					<ShareIcon width={32} height={32} />
				</NewToolbarButton>
			</div>
		</Popover>
	);
};

export { ShareToolbarButton };
