import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { fetchStatics } from "../api";
import { Champion, Item, RuneTree, SummonerSpell } from "../types/riot";
import { useApi } from "@shared/api/hooks";

export interface StaticDataContextType {
	champions: Record<string, Champion>;
	items: Record<string, Item>;
	summonerSpells: Record<string, SummonerSpell>;
	runes: Array<RuneTree>;
	version: string;
}

export const StaticDataContext = createContext<StaticDataContextType>({
	runes: [],
	champions: {},
	items: {},
	summonerSpells: {},
	version: "",
});

export const StaticDataContextWrapper = ({ children }: PropsWithChildren<{}>) => {
	const { loading, request } = useApi(fetchStatics);
	const [champions, setChampions] = useState<Record<string, Champion>>({});
	const [summoners, setSummoners] = useState<Record<string, SummonerSpell>>({});
	const [items, setItems] = useState<Record<string, Item>>({});
	const [runes, setRunes] = useState<Array<RuneTree>>([]);
	const [version, setVersion] = useState<string>("");

	useEffect(() => {
		async function load() {
			const res = await request();

			if (!res) {
				// error
				return;
			}

			setChampions(res.champions);
			setSummoners(res.summoners);
			setItems(res.items);
			setRunes(res.runes);
			setVersion(res.version);
		}

		load();
	}, []);

	return (
		<StaticDataContext.Provider
			value={{ champions, summonerSpells: summoners, items, version, runes }}
		>
			{children}
		</StaticDataContext.Provider>
	);
};
