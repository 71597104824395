import * as React from "react";
const SvgNexus = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:serif": "http://www.serif.com/", width: "100%", height: "100%", viewBox: "0 0 101 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-410,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(3.31977,0,0,2.81975,380.233,-17.284)" }, /* @__PURE__ */ React.createElement("rect", { id: "blue-nexus", "serif:id": "blue nexus", x: 8.975, y: 6.13, width: 30.123, height: 35.464, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "blue-nexus1", "serif:id": "blue nexus" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.338879,0,0,0.398971,-131.858,3.91642)" }, /* @__PURE__ */ React.createElement("path", { d: "M429.1,40C432.302,30.1 440.13,22.273 450.03,19.07L450.03,10L470.03,10L470.03,19.07C479.93,22.273 487.757,30.1 490.96,40L500.03,40L500.03,60L490.96,60C487.757,69.9 479.93,77.727 470.03,80.93L470.03,89.85L450.03,89.85L450.03,80.93C440.13,77.727 432.302,69.9 429.1,60L420.03,60L420.03,40L429.1,40ZM460.03,27.25C447.474,27.25 437.28,37.444 437.28,50C437.28,62.556 447.474,72.75 460.03,72.75C472.586,72.75 482.78,62.556 482.78,50C482.78,37.444 472.586,27.25 460.03,27.25Z", style: {
  fill: "currentColor",
  stroke: "black",
  strokeWidth: "2.67px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.451839,0.531961,-0.451839,0.531961,-161.231,-239.472)" }, /* @__PURE__ */ React.createElement("rect", { x: 445.03, y: 35, width: 15, height: 15, style: {
  fill: "currentColor",
  stroke: "black",
  strokeWidth: "1.41px"
} }))))));
export default SvgNexus;
