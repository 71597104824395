import React from "react";
import { useNavigate } from "react-router-dom";
import FileIcon from "../../../../assets/icons/archive.svg?react";
import { useAuth } from "../../states/authState";
import { uiState } from "../../states/uiStates";
import { NewToolbarButton } from "../NewToolbarButton";

export interface StrategiesButtonProps {}

const StrategiesButton: React.FC<StrategiesButtonProps> = (props) => {
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();

	const handleClick = () => {
		if (isAuthenticated) uiState.modal = "strategies";
		else navigate("/login");
	};

	return (
		<NewToolbarButton
			onClick={handleClick}
			tooltipMessage={"Save or Load state"}
			testId="toolbar-btn-save"
		>
			<FileIcon width={32} height={32} />
		</NewToolbarButton>
	);
};

export { StrategiesButton };
