import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import TrashIcon from "../../../../assets/icons/trash.svg?react";
import { deleteStrategy, Strategy } from "../../api/strategies";
import { uiState } from "../../states/uiStates";
import { trackEvent } from "@shared/utils";

export interface StrategyItemProps {
	strategy: Strategy;
	reload: () => void;
}

const StrategyItem: React.FC<StrategyItemProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const navigate = useNavigate();
	const [hover, setHover] = useState(false);
	const isSelected = uiSnap.strategyId + "" === props.strategy.id + "";

	const handleLoad = async (e: any) => {
		trackEvent("strat_load");

		// TODO f9 if opening while connected to a room, prompt to open a new room
		navigate(`/strategy/${props.strategy.id}`);
	};

	const handleDelete = async (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		if (window.confirm(`Delete ${props.strategy.title}`)) {
			await deleteStrategy(props.strategy.id);
			if (isSelected) {
				uiState.strategyId = undefined;
			}
			props.reload();
		}
	};

	const date = new Date(props.strategy.updated_at);
	return (
		<div
			className={classNames(
				"p-1 hover:cursor-pointer hover:bg-slate-500 rounded text-white flex justify-between items-center",
				{ "bg-slate-500 bg-opacity-40": isSelected },
			)}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
			onClick={handleLoad}
		>
			<div>
				<div>{props.strategy.title}</div>
				<div className={"text-xs opacity-60"}>
					Last saved: {date.getFullYear()}-{date.getMonth()}-{date.getDate()}{" "}
					{date.getHours()}:{date.getMinutes()}
				</div>
			</div>
			<div className={"flex items-center h-100"}>
				{hover && (
					<button className={"opacity-40 hover:opacity-80"} onClickCapture={handleDelete}>
						<TrashIcon />
					</button>
				)}
			</div>
		</div>
	);
};

export { StrategyItem };
