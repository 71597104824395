import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "react-tiny-popover";
import { useSnapshot } from "valtio";
import UpgradeIcon from "../../../../assets/icons/upgrade.svg?react";
import UserIcon from "../../../../assets/icons/user.svg?react";
import { authState } from "../../states/authState";
import { uiState } from "../../states/uiStates";
import { NewToolbarButton } from "../NewToolbarButton";
import { UserMenu } from "./UserMenu";

export interface AuthButtonProps {}

const AuthButton: React.FC<AuthButtonProps> = (props) => {
	const navigate = useNavigate();
	const authSnap = useSnapshot(authState);
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		if (!authSnap?.accessToken) {
			navigate("/login");
			return;
		}

		setIsOpen(true);
	};

	const handleClickOutside = () => {
		setIsOpen(false);
	};

	const handleClickUpgrade = () => {
		uiState.modal = "upgrade";
	};

	return (
		<>
			{!authSnap.user?.subscriptionId && (
				<NewToolbarButton
					testId="toolbar-btn-upgrade"
					onClick={handleClickUpgrade}
					tooltipMessage={"Upgrade"}
				>
					<UpgradeIcon width={32} height={32} className={"text-green-500"} />
				</NewToolbarButton>
			)}
			<Popover
				isOpen={isOpen}
				positions={["right"]}
				padding={10}
				content={<UserMenu onClose={handleClickOutside} />}
				onClickOutside={handleClickOutside}
			>
				<div>
					<NewToolbarButton
						testId="toolbar-btn-auth"
						onClick={handleClick}
						tooltipMessage={"Account"}
					>
						<UserIcon width={32} height={32} />
					</NewToolbarButton>
				</div>
			</Popover>
		</>
	);
};

export { AuthButton };
