import React from "react";
import { ToolButton } from "../ToolButton";
import { MapTool } from "@shared/types/tools";
import { useSnapshot } from "valtio";
import { toolState } from "@shared/states/uiStates";
import LinePathIcon from "@assets/icons/line-path.svg?react";
import { PencilPopup } from "@shared/Toolbar/PencilButton/PencilPopup";
import { ArrowPathSvg } from "@assets/icons/ArrowPathSvg";

export interface PencilButtonProps {}

const PencilButton: React.FC<PencilButtonProps> = (props) => {
	const toolSnap = useSnapshot(toolState);

	return (
		<ToolButton
			tool={MapTool.PENCIL}
			tooltipMessage={"Draw lines [L/a]"}
			extra={<PencilPopup />}
		>
			{toolSnap.properties[MapTool.PENCIL].isArrow ? (
				<ArrowPathSvg />
			) : (
				<LinePathIcon width={32} height={32} />
			)}
		</ToolButton>
	);
};

export { PencilButton };
