import React from "react";
import { ShapeObject } from "@shared/Map/types";
import { ToolCanvas } from "@shared/Map/tools/PathTool";
import { toolState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { pointerState } from "@shared/states/mapStates";
import { pickle } from "@shared/utils";

export interface ShapeToolProps {
	toolCanvas: ToolCanvas;
	onAdd: (newObject: ShapeObject) => void;
}

const ShapeTool: React.FC<ShapeToolProps> = (props) => {
	const handleClick = () => {
		const id = "shape-" + Math.random();
		const newShape: ShapeObject = {
			id,
			type: "shape",
			layerId: "shapes",
			color: toolState.properties[MapTool.SHAPE].color,
			shape: toolState.properties[MapTool.SHAPE].shape,
			scale: toolState.properties[MapTool.SHAPE].scale,
			dragging: false,
			visible: true,
			position: pickle(pointerState.position),
		};

		props.onAdd(newShape);
	};

	return (
		<>
			<rect
				onClick={handleClick}
				x={props.toolCanvas.x}
				y={props.toolCanvas.y}
				width={props.toolCanvas.width}
				height={props.toolCanvas.height}
				fill={"#ffffff00"}
			/>
		</>
	);
};

export { ShapeTool };
