import classNames from "classnames";
import React, { useContext, useEffect } from "react";
import { Button } from "../../components/Button";
import { settingsState, useSettingsSnapshot } from "../../states/uiStates";
import { WelcomeModalContext } from "./WelcomeModalContext";

interface WelcomeModalMinimapPageProps {}

const WelcomeModalMinimapPage: React.FC<WelcomeModalMinimapPageProps> = (props) => {
	const settingsSnap = useSettingsSnapshot();
	const selected =
		settingsSnap.behavior?.openMinimap === true || settingsSnap.behavior?.openMinimap === false;
	const { nextEnabled, setNextEnabled } = useContext(WelcomeModalContext);

	useEffect(() => {
		setNextEnabled(selected);
	}, [selected]);

	const handleSettingChange = (active: boolean) => () => {
		if (!settingsState.behavior) settingsState.behavior = {};

		if (settingsState.behavior["openMinimap"] == active)
			delete settingsState.behavior["openMinimap"];
		else settingsState.behavior["openMinimap"] = active;
	};

	return (
		<div className={"text-white"}>
			<div className={"flex justify-center"}>
				<video autoPlay loop width="250">
					<source src="/minimap_demo.mp4" type="video/mp4" />
				</video>
			</div>
			<h3 className={"text-2xl font-bold"}>Enhanced In-Game Communication</h3>
			<p className={"text-slate-300"}>
				LoLPlanner comes with you in the game! While keeping pressed <i>Shift + Alt</i>,
				draw on the minimap.
				<br />
				Any other player with LoLPlanner in your game will see your drawings!
				<br />
				Press <i>Tab</i> while in game to see a list of the players that have LoLPlanner
				active in your game!
			</p>

			<div className={"p-4 flex flex-col items-center"}>
				<div className={"text-xl mb-2"}>Would you like to enable Minimap Drawing?</div>
				<div className={"flex gap-10 justify-center"}>
					<Button
						className={classNames({
							"bg-green-700": settingsSnap.behavior?.["openMinimap"] === true,
						})}
						onClick={handleSettingChange(true)}
					>
						Activate
					</Button>
					<Button
						className={classNames({
							"bg-red-700": settingsSnap.behavior?.["openMinimap"] === false,
						})}
						onClick={handleSettingChange(false)}
					>
						Not now
					</Button>
				</div>
			</div>
		</div>
	);
};

export { WelcomeModalMinimapPage };
