import React, { PropsWithChildren, useEffect } from "react";
import { proxy, useSnapshot } from "valtio";
import ChampIcon from "../../../assets/icons/champ.svg?react";
import NotesIcon from "../../../assets/icons/notes.svg?react";
import WardIcon from "../../../assets/icons/ward.svg?react";
import TimelineIcon from "../../../assets/icons/timeline.svg?react";
import { playbookState } from "../states/frameState";
import { ChampsPanel } from "./panels/ChampsPanel/ChampsPanel";
import { EventsPanel } from "./panels/EventsPanel/EventsPanel";
import { NotesPanel } from "./panels/NotesPanel";
import { WardsPanel } from "@shared/Sidebar/panels/WardsPanel/WardsPanel";
import { matchState } from "@shared/states/uiStates";

interface LayerListProps {}

type SidebarLayerType = "champs" | "events" | "notes" | "wards";
const panelState = proxy<{ currentPanel?: SidebarLayerType }>({});

const LayerList: React.FC<LayerListProps> = (props) => {
	const layerSnap = useSnapshot(panelState);
	const playbookSnap = useSnapshot(playbookState);

	useEffect(() => {
		if (playbookSnap?.game) {
			panelState.currentPanel = "champs";
		} else {
			panelState.currentPanel = "notes";
		}
	}, [playbookSnap?.game]);

	// TODO structures
	return (
		<div className={"flex flex-row h-full"}>
			<div className={"flex-1"}>
				{layerSnap.currentPanel === "champs" && <ChampsPanel />}
				{layerSnap.currentPanel === "events" && <EventsPanel />}
				{layerSnap.currentPanel === "wards" && <WardsPanel />}
				{layerSnap.currentPanel === "notes" && <NotesPanel />}
			</div>
			<div className={"bg-slate-500 border-l border-gray-700 h-full"}>
				<LayerBtn layer={"champs"}>
					<ChampIcon width={32} height={32} className={"text-white"} />
				</LayerBtn>
				{matchState.match && (
					<>
						<LayerBtn layer={"events"}>
							<TimelineIcon width={32} height={32} className={"text-white"} />
						</LayerBtn>
					</>
				)}
				<LayerBtn layer={"wards"}>
					<WardIcon width={32} height={32} className={"text-white"} />
				</LayerBtn>
				<LayerBtn layer={"notes"}>
					<NotesIcon width={32} height={32} className={"text-white"} />
				</LayerBtn>
			</div>
		</div>
	);
};

interface LayerButtonProps {
	layer: SidebarLayerType;
}

export const LayerBtn: React.FC<PropsWithChildren<LayerButtonProps>> = (props) => {
	const { children, layer } = props;
	const layerSnap = useSnapshot(panelState);

	const handleClick = () => {
		panelState.currentPanel = layer;
	};

	return (
		<div
			className={
				"w-12 h-12 border-b border-gray-600 flex items-center justify-center cursor-pointer hover:opacity-70 " +
				(layerSnap.currentPanel === layer ? "bg-slate-400" : "bg-slate-500")
			}
			onClick={handleClick}
		>
			{children}
		</div>
	);
};

export { LayerList };
