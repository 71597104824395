import React from "react";
import { ImageObject, ShapeObject } from "@shared/Map/types";
import { ToolCanvas } from "@shared/Map/tools/PathTool";
import { toolState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { pointerState } from "@shared/states/mapStates";
import { pickle } from "@shared/utils";

export interface ShapeToolProps {
	toolCanvas: ToolCanvas;
	onAdd: (newObject: ImageObject) => void;
}

const ImageTool: React.FC<ShapeToolProps> = (props) => {
	const handleClick = () => {
		const id = "image-" + Math.random();

		// const newShape: ImageObject = {
		// 	id,
		// 	type: "image",
		// 	layerId: "images",
		// 	scale: toolState.properties[MapTool.SHAPE].scale,
		// 	url: toolState.properties[MapTool.IMAGE].url,
		// 	dragging: false,
		// 	visible: true,
		// 	position: pickle(pointerState.position),
		// };
		//
		// props.onAdd(newShape);
	};

	return (
		<>
			<rect
				onClick={handleClick}
				x={props.toolCanvas.x}
				y={props.toolCanvas.y}
				width={props.toolCanvas.width}
				height={props.toolCanvas.height}
				fill={"#ffffff00"}
			/>
		</>
	);
};

export { ImageTool };
