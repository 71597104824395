import { authState } from "@shared/states/authState";
import { Match } from "@shared/types/riot";
import { GameProvider } from "@shared/states/uiStates";

const BASE_URL = import.meta.env.VITE_API_URL;

export interface SaveCustomGameBody {
	game_provider: string;
	game_id: string;
	match: Match;
}

export async function saveNewCustomGame(
	body: SaveCustomGameBody,
): Promise<{ ok: boolean; token: string; custom_game: { id: number } }> {
	const res = await fetch(`${BASE_URL}/custom_game`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			Authorization: "Bearer " + authState.accessToken,
			"Content-type": "application/json",
		},
	});

	return await res.json();
}

export interface CustomGame {
	game_provider: GameProvider;
	game_id: string;
	id: number;
}

export async function fetchCustomGame(customGameId: number): Promise<{
	ok: boolean;
	token: string;
	custom_game: CustomGame;
	match?: Match;
}> {
	const res = await fetch(`${BASE_URL}/custom_game/${customGameId}`, {
		method: "GET",
		headers: { Authorization: "Bearer " + authState.accessToken },
	});

	return await res.json();
}

export async function fetchCustomGameByToken(token: string): Promise<{
	ok: boolean;
	match: Match;
	custom_game: CustomGame;
}> {
	const res = await fetch(`${BASE_URL}/custom_game/token`, {
		method: "POST",
		body: JSON.stringify({ token }),
		headers: {
			"Content-type": "application/json",
		},
	});

	return await res.json();
}
