import React from "react";

interface CursorProps {
	x: number;
	y: number;
	color: string;
}

const Cursor: React.FC<CursorProps> = (props) => {
	return (
		<g transform={`translate(${props.x},${props.y}) scale(20)`}>
			<path
				d={
					"m6.63564 2.28741c-.65239-.53716-1.63564-.07309-1.63564.77199v13.9988c0 .9261 1.15025 1.3547 1.75622.6543l3.52368-4.0724c.2849-.3293.6989-.5185 1.1343-.5185h5.5919c.9383 0 1.36-1.1756.6357-1.772z"
				}
				fill={props.color}
				stroke={"white"}
			/>
		</g>
	);
};

export { Cursor };
