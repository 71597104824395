import classNames from "classnames";
import React from "react";
import { SettingsState, settingsState, useSettingsSnapshot } from "../../states/uiStates";

interface MapSettingsModalProps {}

const MapSettingsModal: React.FC<MapSettingsModalProps> = (props) => {
	const settingsSnap = useSettingsSnapshot();

	const handleSettingChange =
		(
			setting: keyof Omit<
				NonNullable<SettingsState["map"]>,
				"championSize" | "structureScale"
			>,
			def: boolean,
		) =>
		() => {
			if (!settingsState.map) settingsState.map = {};

			let val = settingsState.map?.[setting];

			if (val == undefined) val = def;
			else val = !val;

			settingsState.map[setting] = val;
		};

	const handleChampionSize = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!settingsState.map) settingsState.map = {};
		settingsState.map.championSize = parseFloat(e.currentTarget.value);
	};

	const handleStructureScale = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!settingsState.map) settingsState.map = {};
		settingsState.map.structureScale = parseFloat(e.currentTarget.value);
	};

	const bushesDisabled = settingsState.map?.vectorStyle !== true;
	const structuresDisabled = settingsState.map?.showStructures === false;

	return (
		<div className={"flex flex-col text-white"}>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Use Vector style
					<input
						type={"checkbox"}
						checked={settingsSnap.map?.vectorStyle === true}
						onChange={handleSettingChange("vectorStyle", false)}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label
					className={classNames(
						"flex justify-between items-center select-none cursor-pointer",
					)}
				>
					Show Ward vision area
					<input
						type={"checkbox"}
						checked={settingsSnap.map?.showWardVision !== false}
						onChange={handleSettingChange("showWardVision", true)}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label
					className={classNames(
						"flex justify-between items-center select-none cursor-pointer",
					)}
				>
					Show Bushes
					<input
						type={"checkbox"}
						disabled={bushesDisabled}
						checked={settingsSnap.map?.showBush !== false}
						onChange={handleSettingChange("showBush", true)}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Champions scale
					<input
						type={"range"}
						min={0.5}
						max={1.5}
						step={0.1}
						value={
							settingsSnap.map?.championSize !== undefined
								? settingsSnap.map.championSize
								: 1
						}
						onChange={handleChampionSize}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label
					className={classNames(
						"flex justify-between items-center select-none cursor-pointer",
					)}
				>
					Show Structures
					<input
						type={"checkbox"}
						checked={settingsSnap.map?.showStructures !== false}
						onChange={handleSettingChange("showStructures", false)}
						className={"p-1 rounded text-white m-1 outline-none bg-slate-500"}
					/>
				</label>
			</div>
			<div className={"border-b border-slate-400 p-2"}>
				<label className={"flex justify-between items-center select-none cursor-pointer"}>
					Structures scale
					<input
						type={"range"}
						min={0.5}
						max={1.5}
						step={0.1}
						disabled={structuresDisabled}
						value={
							settingsSnap.map?.structureScale !== undefined
								? settingsSnap.map.structureScale
								: 1
						}
						onChange={handleStructureScale}
					/>
				</label>
			</div>
		</div>
	);
};

export { MapSettingsModal };
