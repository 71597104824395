import * as React from "react";
const SvgNormal = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:serif": "http://www.serif.com/", width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(14.641,0,0,11.5354,-212.179,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { id: "normal", x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "normal1", "serif:id": "normal" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0558304,0,0,0.0739581,15.0068,10.8942)" }, /* @__PURE__ */ React.createElement("path", { d: "M103.9,32.5L84.5,32.7L84.5,6.5L65.4,26.1L52.2,0L39.2,26L19.8,6.5L19,32.7L0,32.7L19,52.2C18.7,57.3 18.6,72.2 27.2,81.2C33.3,87.5 40.4,87.4 41.5,103.6C41.5,103.9 41.5,104.1 41.6,104.4L62.6,104.4C62.6,104.1 62.6,103.9 62.7,103.6C63.7,87.4 70.9,87.5 77,81.2C85.6,72.2 85.5,57.1 85.2,52.1L103.9,32.5ZM44.9,73.3C44.9,73.3 28.1,64.6 27.3,52.1L44.9,63.3L44.9,73.3ZM59,73.3L59,63.3L76.6,52.1C75.8,64.6 59,73.3 59,73.3Z", style: {
  fillOpacity: 0,
  fillRule: "nonzero",
  stroke: "white",
  strokeWidth: "3.59px"
} })))));
export default SvgNormal;
