import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { WardObject } from "../../Map/types";
import { wardAreaColors, wardColors } from "./WardToolButton";
import { MiniWard } from "@shared/Toolbar/WardButton/MiniWard";

export interface WardSelectorPopupProps {
	currentColor: WardObject["color"];
	currentArea: WardObject["areaColor"];
	onSelect: (color: WardObject["color"], area: WardObject["areaColor"]) => void;
}

const WardSelectorPopup: React.FC<WardSelectorPopupProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleSelect = (color: string, area: string) => {
		setIsOpen(false);
		props.onSelect(color as WardObject["color"], area as WardObject["areaColor"]);
	};

	return (
		<>
			<Popover
				isOpen={isOpen}
				positions={["right"]}
				content={
					<Selector
						currentArea={props.currentArea}
						currentColor={props.currentColor}
						onSelect={handleSelect}
					/>
				}
				onClickOutside={() => setIsOpen(false)}
			>
				<div className={"absolute -right-2 -bottom-2"}>
					<div
						onClick={() => setIsOpen(!isOpen)}
						className={
							"border border-slate-400 w-3 h-3 rounded-full m-2 hover:cursor-pointer"
						}
						style={{ background: wardColors[props.currentColor] }}
					></div>
				</div>
			</Popover>
		</>
	);
};

export interface SelectorProps {
	currentColor: WardObject["color"];
	currentArea: WardObject["areaColor"];
	onSelect: (color: string, area: string) => void;
}

const Selector: React.FC<SelectorProps> = (props) => {
	return (
		<div
			className={
				"p-2 bg-slate-600 border border-black drop-shadow-md rounded flex flex-col gap-2"
			}
		>
			{Object.entries(wardAreaColors).map(([areaColorKey, areaColor]) => (
				<div key={areaColorKey} className={"flex gap-2"}>
					{Object.entries(wardColors).map(([colorKey, color]) => (
						<MiniWard
							key={colorKey}
							onClick={() => props.onSelect(colorKey, areaColorKey)}
							selected={
								props.currentColor === colorKey &&
								props.currentArea === areaColorKey
							}
							color={color}
							areaColor={areaColor}
						/>
					))}
				</div>
			))}
		</div>
	);
};

export { WardSelectorPopup };
