import classNames from "classnames";
import React, { useContext, useMemo } from "react";
import { StaticDataContext } from "../../context/StaticDataContext";
import { useAssetImage } from "../../hooks";
import { useParticipant } from "../../Map/frame_hooks";
import { AssetIcon } from "@shared/components/AssetIcon";

interface ChampionIconProps {
	championId?: number | string;
	championName?: string;
	participantId?: number | string;
	width?: number;
	height?: number;
	team?: "blue" | "red";
}

/**
 * This can either accept a championId or participantId
 * if participantId is passed, then we use the matchState to find the championId
 */
export const ChampionIcon: React.FC<ChampionIconProps> = (props) => {
	const { championId, participantId, width, height, championName } = props;

	const { champions } = useContext(StaticDataContext);

	const participant = useParticipant(participantId);

	const champ = useMemo(() => {
		if (!!participant)
			return Object.values(champions).find((c) => `${participant.championId}` == `${c.key}`);

		if (!!championId)
			return Object.values(champions).find((c) => `${championId}` == `${c.key}`);

		// have to do the lowercase because apparently Fiddlestick is sometime written FiddleSticks
		if (!!championName)
			return Object.values(champions).find(
				(c) => championName.toLowerCase() == c.id.toLowerCase(),
			);

		return null;
	}, [participant, championId, champions]);

	const src = useAssetImage(champ?.image);

	let borderStyle = {};

	if (participant) {
		borderStyle = {
			"border-blue-300": participant?.teamId === 100,
			"border-red-500": participant?.teamId !== 100,
			rounded: true,
		};
	} else if (props.team) {
		borderStyle = {
			"border-blue-300": props.team === "blue",
			"border-red-500": props.team === "red",
			rounded: true,
		};
	}

	if (!src || !champ) return null;

	return (
		<AssetIcon
			image={champ?.image}
			width={width || champ.image.w}
			height={height || champ.image.h}
			className={classNames({ border: !!participant }, borderStyle)}
		/>
	);
};
