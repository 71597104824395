import {
	Match,
	MatchFrame,
	MatchParticipant,
	MatchSummary,
	MatchTimeline,
	WardPlacedEvent,
	WardType,
} from "@shared/types/riot";
import { FrameLayer } from "@shared/types/map";
import { ChampionObject, WardObject } from "@shared/Map/types";
import { RIFT_SIZE } from "@shared/Map/Map";
import { convertTimestampToTime } from "@shared/utils";

export function getMatchSummaryParticipant(
	summary: MatchSummary,
	participantId: number | undefined,
): MatchParticipant | null {
	if (!participantId) {
		return null;
	}
	return summary.participants?.find((mp) => "" + participantId === "" + mp.participantId) || null;
}

const getAverageLevel = (frame: MatchFrame) => {
	return Object.values(frame.participantFrames).reduce((a, c) => a + c.level, 0) / 10;
};

const getWardExpiry = (frame: MatchFrame, e: WardPlacedEvent) => {
	if (e.wardType === "YELLOW_TRINKET") {
		const duration = (90 + (30 / 17) * getAverageLevel(frame) - 1) * 1000;
		return e.timestamp + duration;
	}
	if (e.wardType === "SIGHT") return e.timestamp + 150 * 1000;
	if (e.wardType === "CONTROL") return undefined;
	if (e.wardType === "BLUE_TRINKET") return undefined;
};

export function convertMatchFrameToMapFrame(match: Match, frameIndex: number) {
	const championsLayer: FrameLayer<ChampionObject> = { id: "champions", objects: {} };
	const wardsLayer: FrameLayer<WardObject> = { id: "wards", objects: {} };
	const timelineFrames = match.timeline.frames as MatchTimeline["frames"];

	if (timelineFrames.length <= frameIndex) {
		return { frameIndex };
	}

	const timelineFrame = timelineFrames[frameIndex];

	for (const p of Object.values(timelineFrame.participantFrames)) {
		const participant = getMatchSummaryParticipant(match.summary, p?.participantId);
		const champId = `champion-${p.participantId}`;

		if (!participant) continue;

		championsLayer.objects[champId] = {
			type: "champion",
			id: champId,
			layerId: "champions",
			participantId: p.participantId,
			team: participant.teamId == 100 ? "blue" : "red",
			position: { x: p.position.x, y: p.position.y * -1 + RIFT_SIZE },
			alive: p.championStats.health > 0,
			summonerName: participant.summonerName,
		};
	}

	const wardColorMap: Record<WardType, WardObject["color"]> = {
		EFFIGY: "green",
		GHOST_PORO: "green",
		ZOMBIE: "green",
		CONTROL: "pink",
		SIGHT: "green",
		YELLOW_TRINKET: "yellow",
		BLUE_TRINKET: "blue",
	};

	const liveWards: Record<
		string,
		{
			id: string;
			expireAt?: number;
			teamId: 100 | 200;
			creatorId: number;
			object: WardObject;
			wardType: WardType;
		}
	> = {};

	for (const frame of timelineFrames) {
		if (frame.timestamp > timelineFrame.timestamp) {
			break;
		}
		for (const e of frame.events) {
			if (e.type === "WARD_PLACED") {
				if (!e.position) {
					// riot api has the event but not position. skip it
					continue;
				}
				const creator = getMatchSummaryParticipant(match.summary, e.creatorId);
				const expiry = getWardExpiry(frame, e);
				if (expiry && expiry < frame.timestamp) {
					continue;
				}

				// we need to count how many wards the player has, so we can expire the oldest
				const liveWardsForPlayer = Object.values(liveWards).filter(
					(w) => w.creatorId === e.creatorId && w.wardType === e.wardType,
				);

				if (e.wardType === "CONTROL" && liveWardsForPlayer.length >= 1) {
					const oldestWard = liveWardsForPlayer[0];
					delete liveWards[oldestWard.id];
				}

				if (e.wardType === "SIGHT" && liveWardsForPlayer.length >= 3) {
					const oldestWard = liveWardsForPlayer.sort((a, b) => a.expireAt! - b.expireAt!);
					if (oldestWard.length > 0) {
						delete liveWards[oldestWard[0].id];
					}
				}

				liveWards[e.wardId] = {
					id: e.wardId,
					creatorId: e.creatorId,
					expireAt: getWardExpiry(frame, e),
					teamId: creator!.teamId,
					wardType: e.wardType,
					object: {
						areaColor: creator?.teamId === 100 ? "blue" : "red",
						topText: convertTimestampToTime(e.timestamp).timeText, //creator?.championId + "",
						color: wardColorMap[e.wardType],
						id: e.wardId,
						layerId: "wards",
						position: { x: e.position.x, y: RIFT_SIZE - e.position.y },
						type: "ward",
					},
				};
			}
			if (e.type === "WARD_KILLED") {
				delete liveWards[e.wardId];
			}

			for (const liveWard of Object.values(liveWards)) {
				if (liveWard.expireAt && liveWard.expireAt < frame.timestamp) {
					delete liveWards[liveWard.id];
				}
			}
		}
	}

	for (const ward of Object.values(liveWards)) {
		wardsLayer.objects[ward.id] = ward.object;
	}

	return { frameIndex, champions: championsLayer, wards: wardsLayer };
}
