import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
	listStrategies,
	saveNewStrategy,
	SaveStratBody,
	saveStrategy,
	Strategy,
} from "../../api/strategies";
import { Button } from "../../components/Button";
import { useHasTier } from "../../states/authState";
import {
	playbookState,
	resetMapState,
	sharedSettingsState,
	useMapOverlayFrameSnapshot,
} from "../../states/frameState";
import { closeAllModals, uiState } from "../../states/uiStates";
import { Modal } from "../Modal";
import { StrategyItem } from "./StrategyItem";
import { trackEvent } from "@shared/utils";

export interface StrategiesModalProps {}

const StrategiesModal: React.FC<StrategiesModalProps> = (props) => {
	const [strategies, setStrategies] = useState<Strategy[]>([]);
	const [title, setTitle] = useState("");
	const uiSnap = useSnapshot(uiState);
	const [errors, setErrors] = useState<Record<string, string[]>>({});
	const navigate = useNavigate();
	const hasDiamondTier = useHasTier("Diamond");
	const overlaySnap = useMapOverlayFrameSnapshot();

	const selectedStrategy = useMemo(() => {
		return strategies.find((s) => s.id + "" === uiSnap.strategyId + "");
	}, [strategies, uiSnap.strategyId]);

	useEffect(() => {
		setTitle(selectedStrategy?.title || "");
	}, [selectedStrategy]);

	const loadStrategies = useCallback(async () => {
		const res = await listStrategies();
		if (res.ok)
			setStrategies(
				res.strategies.sort(
					(a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
				),
			);
	}, []);

	useEffect(() => {
		loadStrategies();
	}, [loadStrategies]);

	const handleSave = (shouldSaveNew: boolean) => async () => {
		if (title.length <= 0) {
			setErrors({ title: ["Title can't be empty"] });
			return;
		}

		const stratState: Strategy["state"] = {
			version: 1,
			frames: overlaySnap.frames,
			notes: sharedSettingsState.notes,
			game: playbookState.game,
		};
		trackEvent("strat_save");

		const body: SaveStratBody = { title, state: stratState };

		if (playbookState.customGameId) {
			body.custom_game_id = playbookState.customGameId;
		}

		// TODO f7 error handling
		if (!uiSnap.strategyId || shouldSaveNew) {
			const { strategy: newStrat } = await saveNewStrategy(body);
			uiState.strategyId = newStrat.id;
		} else {
			// TODO success on update
			await saveStrategy(uiSnap.strategyId, body);
		}

		loadStrategies();
	};

	const handleBlankClick = () => {
		trackEvent("strat_clear");
		resetMapState();
		handleClose();
		navigate("/");
	};

	const handleClose = () => {
		closeAllModals();
	};

	const handleUpgrade = () => {
		uiState.modal = "upgrade";
	};

	const canSaveNew = hasDiamondTier || strategies.length < 3;
	const isUpdate = !!uiSnap.strategyId;

	return (
		<Modal title={"Strategies"} onClose={() => (uiState.modal = undefined)}>
			<div style={{ minWidth: 500 }}>
				<h2 className={"text-white font-bold text-xl"}>Save Strategy</h2>
				<div className={"flex gap-2 w-full mt-2"}>
					<input
						className={"flex-1 bg-slate-500 rounded text-white px-2 "}
						value={title}
						onChange={(e) => setTitle(e.currentTarget.value)}
					/>
					{canSaveNew ? (
						<>
							<Button onClick={handleSave(false)}>
								{isUpdate ? "Update" : "Save"}
							</Button>
							{isUpdate && <Button onClick={handleSave(true)}>Save As New</Button>}
						</>
					) : (
						<>
							<Button className={"bg-green-500 font-bold"} onClick={handleUpgrade}>
								Upgrade
							</Button>
							{isUpdate && <Button onClick={handleSave(false)}>Update</Button>}
						</>
					)}
				</div>
				{errors?.["title"]?.map((e) => (
					<div className={"text-red-400 text-xs"} key={e}>
						{e}
					</div>
				))}

				<h2 className={"text-white font-bold text-xl mt-4 mb-2"}>Open Saved Strategy</h2>
				<div className={"h-64 relative mb-2"}>
					<div className={"flex flex-col gap-2 absolute inset-0 overflow-y-scroll"}>
						{strategies.map((s) => (
							<StrategyItem key={s.id} strategy={s} reload={() => loadStrategies()} />
						))}
					</div>
				</div>
				<div className={"flex justify-between"}>
					<Button onClick={handleBlankClick}>Start New Strategy</Button>
				</div>
			</div>
		</Modal>
	);
};

export { StrategiesModal };
