import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import { useSnapshot } from "valtio";
import CopyIcon from "../../../../assets/icons/copy.svg?react";
import { Button } from "../../components/Button";
import { useNetworkUsername } from "../../network/hooks";
import { connectPlaybook, disconnectPlaybook } from "../../network/playbook_network";
import { networkState } from "../../states/networkState";
import { platformState } from "../../states/uiStates";
import { trackEvent } from "@shared/utils";

interface SharePopupProps {}

const SharePopup: React.FC<SharePopupProps> = (props) => {
	const { connected } = useSnapshot(networkState);
	const [action, setAction] = useState<null | "join" | "new">(connected ? "new" : null);
	const networkUsername = useNetworkUsername();

	const handleClick = (act: "join" | "new") => (e: React.MouseEvent) => {
		trackEvent("share_" + act);
		e.stopPropagation();
		if (act === "new") {
			const newPin = uuid().slice(0, 6);
			handleJoin(true, newPin);
		} else {
			setAction("join");
		}
	};

	const handleJoin = (newRoom: boolean, room: string) => {
		if (!networkUsername) return;

		setAction("new");
		connectPlaybook(room, networkUsername);
	};
	useEffect(() => {
		if (!connected) setAction(null);
	}, [connected]);

	return (
		<div
			className={
				"p-2 bg-slate-600 drop-shadow-md rounded flex flex-col text-white border border-black"
			}
		>
			<div className={"font-bold mb-2"}>Collaborate on this map</div>
			{action === null && (
				<div className={"text-sm flex gap-2"}>
					<Button onClick={handleClick("new")}>Start Room</Button>
					<Button onClick={handleClick("join")}>Join Room</Button>
				</div>
			)}
			{action === "join" && <JoinRoom onJoin={(room) => handleJoin(false, room)} />}
			{action === "new" && <RoomDetails />}
		</div>
	);
};

const RoomLinks: React.FC<{ roomName: string }> = (props) => {
	const [showUrl, setShowUrl] = useState(true);

	const val = useMemo(() => {
		if (!showUrl) {
			return props.roomName;
		}
		const url = import.meta.env.VITE_APP_URL;
		if (url) return `${url}/?room=${props.roomName}`;
		return `${window.location.protocol}//${window.location.host}/?room=${props.roomName}`;
	}, [showUrl, props.roomName]);

	const handleCopyUrl = () => {
		if (platformState.platform === "desktop") {
			overwolf.utils.placeOnClipboard(val);
		} else {
			navigator.clipboard.writeText(val);
		}
	};

	return (
		<div className={"mb-2"}>
			<div className={"flex gap-2"}>
				<div
					className={"cursor-pointer " + classNames({ underline: showUrl })}
					onClick={() => setShowUrl(true)}
				>
					Url
				</div>
				<div
					className={"cursor-pointer " + classNames({ underline: !showUrl })}
					onClick={() => setShowUrl(false)}
				>
					Code
				</div>
			</div>
			<div className={"flex items-center"}>
				<input
					value={val}
					readOnly={true}
					className={"rounded-tl rounded-bl p-1 bg-slate-500"}
				/>
				<button
					onClick={handleCopyUrl}
					className={
						"text-white rounded-tr rounded-br bg-slate-700 p-2 hover:bg-slate-800 active:bg-slate-700"
					}
				>
					<CopyIcon width={16} height={16} />
				</button>
			</div>
		</div>
	);
};

const RoomDetails: React.FC = () => {
	const { roomName, clientId, states } = useSnapshot(networkState);

	const handleExit = () => {
		disconnectPlaybook();
	};

	return (
		<div>
			<RoomLinks roomName={roomName} />

			<div className={"mb-2"}>
				<div className={"font-bold mb-2"}>Users online</div>
				{states.map((v) => (
					<div key={v.clientId} className={"flex items-center gap-2"}>
						<div
							className={"rounded-full w-4 h-4 border border-white"}
							style={{ background: v.color }}
						></div>
						<div>
							{v.username}
							{clientId === v.clientId && <span>(You)</span>}
						</div>
					</div>
				))}
			</div>

			<Button onClick={handleExit}>Leave Room</Button>
		</div>
	);
};

const JoinRoom: React.FC<{ onJoin: (room: string) => void }> = (props) => {
	const [roomVal, setRoomVal] = useState("");

	const handleJoin = () => {
		props.onJoin(roomVal);
	};

	return (
		<div className={" flex flex-col"}>
			<div className={"mb-2"}>
				<label>
					<div>Room Code</div>
					<input
						className={"rounded-tl rounded-bl p-1 bg-slate-500"}
						placeholder={"Room code"}
						type={"text"}
						value={roomVal}
						onChange={(e) => setRoomVal(e.target.value)}
					/>
				</label>
			</div>

			<div>
				<Button onClick={handleJoin}>Join</Button>
			</div>
		</div>
	);
};

export { SharePopup };
