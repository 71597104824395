const BASE_URL = import.meta.env.VITE_API_URL;

interface AuthResponse {
	ok: boolean;
	access_token?: string;
	errors?: Record<string, string[]>;
}

interface PasswordResetResponse {
	ok: boolean;
	errors?: Record<string, string[]>;
}

export async function login(email: string, password: string): Promise<AuthResponse> {
	const res = await fetch(`${BASE_URL}/auth/login`, {
		method: "POST",
		body: JSON.stringify({ email, password }),
		headers: { "Content-Type": "application/json" },
	});
	return await res.json();
}

export async function register(
	email: string,
	password: string,
	marketing: boolean,
): Promise<AuthResponse> {
	const res = await fetch(`${BASE_URL}/auth/register`, {
		method: "POST",
		body: JSON.stringify({ email, password, marketing }),
		headers: { "Content-Type": "application/json" },
	});
	return await res.json();
}

export async function requestPasswordReset(email: string): Promise<PasswordResetResponse> {
	const res = await fetch(`${BASE_URL}/auth/pass/request`, {
		method: "POST",
		body: JSON.stringify({ email }),
		headers: { "Content-Type": "application/json" },
	});
	return await res.json();
}

export async function resetPassword(token:string, password:string) {
	const res = await fetch(`${BASE_URL}/auth/pass/reset`, {
		method: "POST",
		body: JSON.stringify({ token, password }),
		headers: { "Content-Type": "application/json" },
	});

	return await res.json();
}


