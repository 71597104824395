// ?product_id=fSyvV-q0jd0LT2QZAqm38A%3D%3D&product_permalink=wabmj&sale_id=y9TpCh2hPGql0HjMZDrllw%3D%3D
// ?product_id=fSyvV-q0jd0LT2QZAqm38A%3D%3D&product_permalink=wabmj&sale_id=qappNo2DrtV6-boJcoFOfw%3D%3D
// https://app.gumroad.com/subscriptions/hzDjA45dXYK4S6jvIATHaQ==/manage

import { authState } from "../states/authState";

const BASE_URL = import.meta.env.VITE_API_URL;

export async function checkPurchase(sale_id: string): Promise<{ ok: boolean }> {
	const res = await fetch(`${BASE_URL}/membership/check`, {
		method: "POST",
		headers: {
			Authorization: "Bearer " + authState.accessToken,
			"Content-type": "application/json",
		},
		body: JSON.stringify({ sale_id }),
	});

	return await res.json();
}
