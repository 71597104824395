import React, { useEffect } from "react";
import { useSnapshot } from "valtio";
import { closeAllModals, platformState, uiState } from "../states/uiStates";
import { ImportGameModal } from "./ImportGameModal/ImportGameModal";
import { SettingsModal } from "./SettingsModal/SettingsModal";
import { StrategiesModal } from "./StrategiesModal/StrategiesModal";
import { UpgradeModal } from "./UpgradeModal/UpgradeModal";
import { WelcomeModal } from "./WelcomeModal/WelcomeModal";
import { WelcomeModalContextWrapper } from "./WelcomeModal/WelcomeModalContext";
import { ChangelogModal } from "@shared/modals/ChangelogModal/ChangelogModal";

export interface ModalsProps {}

let latestSeenVersion = localStorage.getItem("lolplanner:latest-seen-version") || "0.0.0";

function isNewerVersion(newVersion: string, oldVersion: string) {
	if (oldVersion === "0.0.0") {
		return false;
	}
	// remove the w or d at the end of the version
	const parts1 = newVersion.replace(/[wd]$/, "").split(".").map(Number);
	const parts2 = oldVersion.replace(/[wd]$/, "").split(".").map(Number);

	for (let i = 0; i < 3; i++) {
		if (parts1[i] > parts2[i]) {
			return true;
		} else if (parts1[i] < parts2[i]) {
			return false;
		}
	}
	return false;
}

const Modals: React.FC<ModalsProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const platformSnap = useSnapshot(platformState);

	useEffect(() => {
		const shouldShowHelp = localStorage.getItem("needsWelcomeModal") !== "true";
		if (shouldShowHelp && platformSnap.platform === "desktop") {
			uiState.modal = "help";
		} else {
			if (isNewerVersion(APP_VERSION, latestSeenVersion)) {
				uiState.modal = "changelog";
			}
		}
		localStorage.setItem("lolplanner:latest-seen-version", APP_VERSION);
	}, [platformSnap.platform]);

	const handleCloseModals = () => {
		closeAllModals();
	};

	const handleCloseHelpModal = () => {
		localStorage.setItem("needsWelcomeModal", "true");
		handleCloseModals();
	};

	return (
		<>
			{uiSnap.modal === "import" && <ImportGameModal />}
			{uiSnap.modal === "settings" && <SettingsModal onClose={handleCloseModals} />}
			{uiSnap.modal === "strategies" && <StrategiesModal />}
			{uiSnap.modal === "changelog" && <ChangelogModal />}
			{uiSnap.modal === "upgrade" && <UpgradeModal onClose={handleCloseModals} />}
			{uiSnap.modal === "help" && (
				<WelcomeModalContextWrapper>
					<WelcomeModal onClose={handleCloseHelpModal} />
				</WelcomeModalContextWrapper>
			)}
		</>
	);
};

export { Modals };
