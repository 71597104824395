import React, { useCallback, useEffect, useMemo } from "react";
import { ArrowObject, PencilPathObject } from "@shared/Map/types";
import { Position } from "@shared/types/riot";
import { Draggable } from "@shared/Map/Draggable";
import { inGameState } from "@shared/states/inGameStates";
import { calculateLineLength } from "@shared/Map/utils";

export interface SvgPathProps {
	object: ArrowObject | PencilPathObject;
	onUpdate: (update: Partial<ArrowObject | PencilPathObject>) => void;
	isMinimap?: boolean;
}

const t = 1 / 5;

function controlPoints(p: Position[]) {
	// given the points array p calculate the control points for the cubic Bezier curves

	const pc: Position[][] = [];
	for (let i = 1; i < p.length - 1; i++) {
		const dx = p[i - 1].x - p[i + 1].x; // difference x
		const dy = p[i - 1].y - p[i + 1].y; // difference y
		// the first control point
		const x1 = p[i].x - dx * t;
		const y1 = p[i].y - dy * t;
		const o1 = {
			x: x1,
			y: y1,
		};

		// the second control point
		const x2 = p[i].x + dx * t;
		const y2 = p[i].y + dy * t;
		const o2 = {
			x: x2,
			y: y2,
		};

		// building the control points array
		pc[i] = [];
		pc[i].push(o1);
		pc[i].push(o2);
	}
	return pc;
}

function drawCurve(p: Position[]) {
	const pc = controlPoints(p);

	let bezierPoints = drawInitialQuadraticCurve(p, pc);
	if (p.length > 2) {
		bezierPoints += drawCubicCurves(p, pc);
		bezierPoints += drawFinalQuadraticCurve(p, pc);
	}

	return bezierPoints;
}

function drawInitialQuadraticCurve(p: Position[], pc: Position[][]): string {
	const [startPoint, controlPoint, endPoint] = [p[0], pc[1][1], p[1]];
	return `M${startPoint.x},${startPoint.y} Q${controlPoint.x},${controlPoint.y}, ${endPoint.x},${endPoint.y} `;
}

function drawCubicCurves(p: Position[], pc: Position[][]): string {
	let curves = "";
	for (let i = 1; i < p.length - 2; i++) {
		const startPoint = pc[i][0];
		const controlPoint1 = pc[i + 1][1];
		const endPoint = p[i + 1];
		curves += `C${startPoint.x},${startPoint.y}, ${controlPoint1.x},${controlPoint1.y}, ${endPoint.x},${endPoint.y} `;
	}
	return curves;
}

function drawFinalQuadraticCurve(p: Position[], pc: Position[][]): string {
	const n = p.length - 1;

	const startPoint = pc[n - 1][0];
	const endPoint = p[n];

	return `Q${startPoint.x},${startPoint.y}, ${endPoint.x},${endPoint.y}`;
}

const Path: React.FC<SvgPathProps> = (props) => {
	const path = useMemo(() => {
		if (props.object.points.length < 2) return "";

		if (props.object.points.length < 3) {
			const p1 = props.object.points[0];
			const p2 = props.object.points[1];
			return `M${p1.x},${p1.y} L${p2.x},${p2.y}`;
		}

		return drawCurve(props.object.points);
	}, [props.object.points]);

	const isArrow = props.object.type === "arrow";

	useEffect(() => {
		if (!props.object.expiry) return;

		// todo d5 better way of doing this
		setTimeout(() => {
			if (props.isMinimap) {
				if (!!inGameState.frame.arrows?.objects[props.object.id])
					delete inGameState.frame.arrows?.objects[props.object.id];
			}
			// else {
			// 	deleteOverlayObject(uiState.currentFrameIndex, "arrows", props.arrow.id);
			// }
		}, props.object.expiry);
	}, [props.object.expiry]);

	const lineLength = useMemo(() => {
		return calculateLineLength(props.object.points);
	}, [props.object.points]);

	const headScale = useMemo(() => {
		return Math.max(Math.min(lineLength / 1000, 1), 0.5);
	}, [lineLength]);

	const handleMove = useCallback((newPosition: Position) => {
		props.onUpdate({ position: newPosition });
	}, []);

	if (
		props.object.points.length < 2 ||
		path === "" ||
		props.object.position == null ||
		props.object.position.y == null ||
		props.object.position.x == null
	)
		return null;

	return (
		<>
			<Draggable
				position={props.object.position}
				draggable={props.object.id !== "tmp"}
				onMove={handleMove}
			>
				{isArrow && (
					<defs>
						<marker
							id={`arrow-points-${props.object.id}`}
							viewBox="0 0 10 10"
							refX="5"
							refY="5"
							markerWidth="6"
							markerHeight="6"
							orient="auto-start-reverse"
						>
							<path
								transform={`translate(5 5) scale(${headScale}) translate(-5 -5) `}
								d="M 0 0 L 10 5 L 0 10 L 2 5 z"
								fill={props.object.color}
							/>
						</marker>
					</defs>
				)}
				<path
					data-objectid={props.object.id}
					data-layerid={props.object.layerId}
					strokeLinejoin={"round"}
					strokeLinecap={"round"}
					markerEnd={isArrow ? `url(#arrow-points-${props.object.id})` : undefined}
					strokeWidth={50}
					stroke={props.object.color}
					fill={"transparent"}
					d={path}
					style={{ pointerEvents: "stroke" }}
				/>
			</Draggable>
		</>
	);
};

export { Path };
