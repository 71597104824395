import React, { PropsWithChildren } from "react";
import { useRelativeTimestamp } from "@shared/hooks";
import { FrameEvent } from "@shared/types/riot";

export const EventRow: React.FC<PropsWithChildren<{ event: FrameEvent }>> = ({
	event,
	children,
}) => {
	const { timeText } = useRelativeTimestamp(event.timestamp);
	return (
		<div
			className={
				"flex items-center text-white p-2 border-b border-slate-400 border-opacity-50 last:border-b-0"
			}
		>
			<div className={"mr-2"}>{timeText}</div>
			<div className={"flex-1"}>{children}</div>
		</div>
	);
};
