import React from "react";
import { ChampionIcon } from "@shared/components/icons/ChampionIcon";
import { BuildingKill } from "@shared/types/riot";
import { EventRow } from "./EventRow";

export const BuildingKillEventRow: React.FC<{ event: BuildingKill }> = ({ event }) => {
	return (
		<EventRow event={event}>
			<div className={"flex gap-1 items-center"}>
				{event.killerId !== null && event.killerId > 0 ? (
					<>
						<div className={"h-8 w-8"}>
							<ChampionIcon participantId={event.killerId} />
						</div>
						<div> Destroyed</div>
					</>
				) : (
					<>
						<div>A</div>
						<div>minion</div>
						<div>destroyed</div>
					</>
				)}
				<div>a</div>
				{event.teamId === 100 ? (
					<span className={"text-blue-300"}>Blue</span>
				) : (
					<span className={"text-red-500"}>Red</span>
				)}
				<span className={"capitalize"}>
					{event.laneType.replace("_LANE", "").toLowerCase()}
				</span>
				{event.buildingType === "INHIBITOR_BUILDING" ? "Inhibitor" : "Tower"}
			</div>
		</EventRow>
	);
};
