import React from "react";
import HiddenIcon from "../../../../../assets/icons/eye-hidden.svg?react";
import VisibleIcon from "../../../../../assets/icons/eye-visible.svg?react";
import { useSnapshot } from "valtio";
import { uiState } from "@shared/states/uiStates";
import { useMapFrameSnap } from "@shared/Map/frame_hooks";
import { ChampionObject } from "@shared/Map/types";
import { setTimelineObject } from "@shared/Map/actions";

export interface HideTeamButtonProps {
	team: ChampionObject["team"];
}

const HideTeamButton: React.FC<HideTeamButtonProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const frame = useMapFrameSnap(uiSnap.currentFrameIndex);
	const champions = Object.values(frame.champions?.objects || []).filter(
		(c) => c.team === props.team,
	);

	const isAllTeamHidden = champions.filter((c) => c.visible !== false).length === 0;

	const handleClick = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		for (const champion of champions) {
			setTimelineObject(uiState.currentFrameIndex, "champions", champion.id, {
				visible: isAllTeamHidden,
			});
		}
	};

	return (
		<button
			className={
				"p-1 bg-transparent bg-opacity-40 text-white rounded select-none hover:bg-slate-500"
			}
			onClick={handleClick}
		>
			{isAllTeamHidden ? (
				<VisibleIcon width={20} height={20} />
			) : (
				<HiddenIcon width={20} height={20} />
			)}
		</button>
	);
};

export { HideTeamButton };
