import React, { useState } from "react";

export interface ArrowPathSvgProps {
	width?: number;
	height?: number;
}

const ArrowPathSvg: React.FC<ArrowPathSvgProps> = (props) => {
	const [randomId] = useState(Math.random() + "");
	const width = props.width || 30;
	const height = props.height || 30;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
			<defs>
				<marker
					id={`arrow-path-tip-${randomId}`}
					viewBox="0 0 10 10"
					refX="5"
					refY="5"
					markerWidth="8"
					markerHeight="8"
					orient="auto-start-reverse"
				>
					<path d="M 0 0 L 10 5 L 0 10 L 2 5 z" fill="currentColor" />
				</marker>
			</defs>
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={".6"}
				transform="translate(7 7) rotate(90) translate(-7 -7)"
				markerStart={`url(#arrow-path-tip-${randomId})`}
				fill="none"
				fillRule="evenodd"
				d="M.64 7.86c.785-1.097 3.164-4.214 5.947-5.967c2.093-1.318 4.07.422 2.537 2.174c-1.499 1.714-3.51 4.073-4.368 5.244c-.89 1.214.633 2.682 2.157 1.285c1.019-.934 2.08-2.008 3.158-2.802c1.456-1.071 2.705-.125 2.07 1.082c-.46.873-.793 1.258-1.177 1.992c-.383.735.023 1.615.604 1.691c.72.094 1.176-.423 1.791-1.228"
			/>
		</svg>
	);
};

export { ArrowPathSvg };
