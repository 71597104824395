import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, { BugsnagErrorBoundary } from "@bugsnag/plugin-react";
import React, { PropsWithChildren } from "react";

interface ErrorBoundaryProps {}

let BugsnagBoundary: BugsnagErrorBoundary | undefined = undefined;
if (!!import.meta.env.VITE_BUGSNAG_KEY) {
	Bugsnag.start({
		apiKey: import.meta.env.VITE_BUGSNAG_KEY,
		appVersion: APP_VERSION,
		plugins: [new BugsnagPluginReact()],
		collectUserIp: false,
	});
	BugsnagBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
}

const ErrorBoundary: React.FC<PropsWithChildren<ErrorBoundaryProps>> = (props) => {
	if (BugsnagBoundary) return <BugsnagBoundary>{props.children}</BugsnagBoundary>;
	return <>{props.children}</>;
};

export { ErrorBoundary };
