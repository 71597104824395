import React, { PropsWithChildren } from "react";
import { useSettingsSnapshot } from "@shared/states/uiStates";

import { Position } from "@shared/types/riot";

export interface SVGStructureProps {
	position: Position;
	teamId: 100 | 200;
	show: boolean;
	size: number;
	highlight?: boolean; // for debug
}

const Structure: React.FC<PropsWithChildren<SVGStructureProps>> = (props) => {
	const settingsSnap = useSettingsSnapshot();
	const scale =
		settingsSnap.map?.structureScale !== undefined ? settingsSnap.map.structureScale : 1;
	const color = props.teamId === 100 ? "#1e8bb7" : "red";
	const origin = props.size / 2 + "px";

	if (!props.show) return null;

	return (
		// todo d3 shouldn't need to do -400, just change the actual values in the constants
		<g
			transform={`translate(${props.position.x - 400}, ${props.position.y - 400})`}
			strokeWidth={props.highlight ? 5 : undefined}
			stroke={props.highlight ? "red" : undefined}
		>
			<g style={{ color: color, scale: `${scale}`, transformOrigin: `${origin} ${origin}` }}>
				{props.children}
			</g>
		</g>
	);
};

export { Structure };
