import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSnapshot } from "valtio";
import { barState, X_PADDING } from "@shared/components/NewFrameBar/FramesBar";
import { TimeTick } from "@shared/components/NewFrameBar/Ticks/utils";
import { FrameTime } from "@shared/components/NewFrameBar/Ticks/FrameTime";

export interface TicksProps {
	selectedTick: TimeTick | undefined;
	ticks: TimeTick[];
	onSelectedTick: (tick: TimeTick) => void;
}

const Ticks: React.FC<TicksProps> = (props) => {
	const barSnap = useSnapshot(barState);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const rectRef = useRef<SVGRectElement | null>(null);
	const { ticks } = props;

	useEffect(() => {
		const listener = () => {
			setIsMouseDown(false);
		};

		window.addEventListener("mouseup", listener);
		return () => {
			window.removeEventListener("mouseup", listener);
		};
	}, [isMouseDown]);

	const findClosestTick = useCallback(
		(xPosition: number) => {
			if (!ticks) {
				return null; //shouldn't happen
			}
			const distances = ticks
				.filter((t) => t.frameIndex !== null)
				.map((tick, i) => ({
					frameIndex: tick.frameIndex,
					distance: Math.abs(xPosition - tick.x),
				}));

			distances.sort((a, b) => a.distance - b.distance);

			return ticks.find((t) => t.frameIndex == distances[0].frameIndex);
		},
		[ticks],
	);

	useEffect(() => {
		if (!isMouseDown) {
			return;
		}
		const listener = (e: MouseEvent) => {
			if (!rectRef.current || !ticks) {
				return;
			}

			const pointerX = e.clientX - rectRef.current.getBoundingClientRect().x;
			const closestTick = findClosestTick(pointerX);
			if (closestTick) {
				props.onSelectedTick(closestTick);
			}
		};

		window.addEventListener("mousemove", listener);
		return () => {
			window.removeEventListener("mousemove", listener);
		};
	}, [isMouseDown, rectRef.current, props.onSelectedTick, findClosestTick]);

	const updateSelected = (e: React.MouseEvent<SVGRectElement>) => {
		if (!rectRef.current || !ticks) {
			return;
		}

		const pointerX = e.clientX - rectRef.current.getBoundingClientRect().x;
		const closestTick = findClosestTick(pointerX);
		if (closestTick) {
			props.onSelectedTick(closestTick);
		}
	};

	const handleMouseDown = (e: React.MouseEvent<SVGRectElement>) => {
		setIsMouseDown(true);
		updateSelected(e);
	};

	return (
		<g>
			{ticks &&
				ticks.map((t) => (
					<React.Fragment key={t.timestamp}>
						<Tick x={X_PADDING + t.x} color={"#93abc9"} highlight={t.isMinute} />

						{(!props.selectedTick || Math.abs(props.selectedTick.x - t.x) > 30) && (
							<FrameTime x={X_PADDING + t.x} time={t.timestamp} />
						)}
					</React.Fragment>
				))}
			{/*not super important if, but will trigger a console error when width is 0, because width < 0*/}
			{barSnap.size.width > 0 && (
				<rect
					ref={rectRef}
					x={X_PADDING}
					y={0}
					height={barSnap.size.height}
					width={barSnap.size.width - X_PADDING * 2}
					fill={"#ff000000"}
					onMouseDown={handleMouseDown}
				/>
			)}
		</g>
	);
};

const Tick: React.FC<{ x: number; color: string; highlight: boolean }> = ({
	x,
	color,
	highlight,
}) => {
	return (
		<line
			x1={x}
			x2={x}
			y1={highlight ? 28 : 32}
			y2={highlight ? 40 : 38}
			stroke={color}
			strokeWidth={highlight ? 2 : 1}
		/>
	);
};

export { Ticks };
