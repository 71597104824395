import React, { useState } from "react";
import { resetPassword } from "@shared/api/auth";
import { useQuery } from "@shared/hooks";
import { Button } from "@shared/components/Button";
import { Link } from "react-router-dom";

export const ResetPasswordForm = () => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<Record<string, string[]>>();
	const [done, setDone] = useState<boolean>(false);
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const query = useQuery();

	const handleReset = async () => {
		if (password !== password2) {
			setErrors({ password2: ["Passwords don't match"] });
			return;
		}
		const token = query.get("token") as string;
		setErrors(undefined);
		setLoading(true);
		const res = await resetPassword(token, password);
		setLoading(false);
		if (!res.ok) {
			setErrors(res.errors);
			return;
		}
		setDone(true);
	};

	return (
		<div className={"flex flex-col w-64"}>
			<div className={"flex flex-col mb-4"}>
				<label className={"mb-4"}>
					<div className={"text-xs text-slate-300 my-1 flex justify-between"}>
						<div>Password</div>
					</div>
					<input
						type={"password"}
						placeholder={"Password"}
						value={password}
						tabIndex={1}
						onChange={(e) => setPassword(e.currentTarget.value)}
						className={
							"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
						}
					/>
					{errors?.["password"]?.map((e) => (
						<div className={"text-red-400 text-xs"} key={e}>
							{e}
						</div>
					))}
				</label>
				<label className={"mb-4"}>
					<div className={"text-xs text-slate-300 my-1 flex justify-between"}>
						<div>Repeat Password</div>
					</div>
					<input
						type={"password"}
						placeholder={"Password"}
						value={password2}
						tabIndex={2}
						onChange={(e) => setPassword2(e.currentTarget.value)}
						className={
							"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
						}
					/>
					{errors?.["password2"]?.map((e) => (
						<div className={"text-red-400 text-xs"} key={e}>
							{e}
						</div>
					))}
				</label>
				{errors?.["token"]?.map((e) => (
					<div className={"text-red-400 text-xs"} key={e}>
						{e}
					</div>
				))}
				{done && (
					<div className={"text-green-400"}>Your password has been reset.</div>
				)}
			</div>

			<div className={"flex justify-between items-center"}>
				{done && <>
					<Link to={"/login"} className={"text-slate-200"}>
						Login
					</Link>
				</>}
				{!done && <>
					{!loading &&
						<Button className={"px-6"} onClick={handleReset}>
							Reset Password
						</Button>
					}
					{loading && <div className={"text-slate-200"}>Loading...</div>}
					<div>
						<Link to={"/"} className={"text-slate-200"}>
							Back
						</Link>
					</div>
				</>}
			</div>
		</div>
	);
};
