import React from "react";
import { ShapeObject } from "@shared/Map/types";

export interface ShapesFactoryProps {
	shape: ShapeObject["shape"];
	objId?: string;
	color?: string;
	stroke?: string;
	strokeWidth?: number;
}

const ShapesFactory: React.FC<ShapesFactoryProps> = (props) => {
	const common = {
		"data-layerid": "shapes",
		"data-objectid": props.objId,
	};

	if (props.shape === "kill")
		return (
			<path
				{...common}
				stroke={props.stroke || "white"}
				fill={props.color}
				strokeWidth={props.strokeWidth || 0.6}
				d={
					"m 8 16.6 l 4.8 4.8 l -1.9 1.9 l 1.9 1.9 l -1.9 1.9 l -3.4 -3.4 l -3.9 3.9 l -1.9 -1.9 l 3.9 -3.9 l -3.4 -3.4 l 1.9 -1.9 l 1.9 1.9 l 1.9 -1.9 z m -5.5 -14.3 l 4.8 0 l 16.3 16.3 l 1.9 -1.9 l 1.9 1.9 l -3.4 3.4 l 3.9 3.9 l -1.9 1.9 l -3.9 -3.9 l -3.4 3.4 l -1.9 -1.9 l 1.9 -1.9 l -16.3 -16.3 z m 19.9 -0.1 l 4.8 0 l 0 4.8 l -5.7 5.7 l -4.8 -4.8 z"
				}
			></path>
		);

	if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(props.shape))
		return (
			<text
				fontStyle={"bold"}
				fill={props.color}
				stroke={props.stroke || "white"}
				strokeWidth={props.strokeWidth || 0.4}
				{...common}
				alignmentBaseline={"middle"}
				textAnchor={"middle"}
				x={16}
				y={16}
				width={32}
				height={32}
				fontSize={28}
			>
				{props.shape}
			</text>
		);

	if (props.shape === "rect")
		return (
			<rect
				stroke={props.stroke || props.color}
				strokeWidth={props.strokeWidth || 2}
				{...common}
				x={2}
				y={2}
				width={26}
				fill={"transparent"}
				height={26}
			/>
		);

	if (props.shape === "circle")
		return (
			<circle
				stroke={props.stroke || props.color}
				strokeWidth={props.strokeWidth || 2}
				fill={"transparent"}
				{...common}
				cx={15}
				cy={15}
				r={13}
			/>
		);

	if (props.shape === "cross")
		return (
			<g {...common}>
				<line
					{...common}
					stroke={props.stroke || "white"}
					strokeWidth={(props.strokeWidth || 4) + 0.6}
					x1={2}
					x2={26}
					y1={2}
					y2={26}
				/>
				<line
					{...common}
					stroke={props.stroke || "white"}
					strokeWidth={(props.strokeWidth || 4) + 0.6}
					x1={26}
					x2={2}
					y1={2}
					y2={26}
				/>
				<line
					{...common}
					stroke={props.color}
					strokeWidth={props.strokeWidth || 4}
					x1={2}
					x2={26}
					y1={2}
					y2={26}
				/>
				<line
					{...common}
					stroke={props.color}
					strokeWidth={props.strokeWidth || 4}
					x1={26}
					x2={2}
					y1={2}
					y2={26}
				/>
			</g>
		);

	return null;
};

export { ShapesFactory };
