import React, { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import { useSnapshot } from "valtio";
import { fetchGameSummary, fetchTimeline } from "./api";
import { checkPurchase } from "./api/membership";
import { getStrategy } from "./api/strategies";
import { getMe } from "./api/user";
import { FramesBar } from "./components/NewFrameBar/FramesBar";
import { useQuery } from "./hooks";
import "./index.css";
import { AuthModal } from "./modals/Auth/AuthModal";
import { LoginForm } from "./modals/Auth/LoginForm";
import { RegisterForm } from "./modals/Auth/RegisterForm";
import { RequestResetForm } from "./modals/Auth/RequestResetForm";
import { Modals } from "./modals/Modals";
import { useNetwork } from "./network/hooks";
import { Sidebar } from "./Sidebar/Sidebar";
import { authState, useAuth } from "./states/authState";
import { loadMapStateFromStrategy, playbookState } from "./states/frameState";
import { closeAllModals, matchState, uiState } from "./states/uiStates";
import { Toolbar } from "./Toolbar/Toolbar";
import { RegionV5 } from "./types/riot";
import { Map } from "./Map/Map";
import { useApi } from "@shared/api/hooks";
import { normalizeRegionToV5 } from "@shared/utils";
import { fetchCustomGame, fetchCustomGameByToken } from "@shared/api/custom_game";
import { ResetPasswordForm } from "@shared/modals/Auth/ResetPasswordForm";

export const Main = () => {
	const playbookSnap = useSnapshot(playbookState);
	const matchSnap = useSnapshot(matchState);
	const { isAuthenticated } = useAuth();
	const { strategyId } = useParams();
	const query = useQuery();
	const sale_id = query.get("sale_id") as string | undefined;

	const { request: requestTimeline } = useApi(fetchTimeline);
	const { request: requestSummary } = useApi(fetchGameSummary);
	const { request: requestCustomGameByToken } = useApi(fetchCustomGameByToken);
	const { request: requestCustomGame } = useApi(fetchCustomGame);

	useEffect(() => {
		async function loadTimeline(region: RegionV5, gameId: string) {
			const result = await requestTimeline(region, gameId);
			return result?.timeline;
		}

		async function loadSummary(region: RegionV5, gameId: string) {
			const result = await requestSummary(region, gameId);
			return result?.summary;
		}

		async function loadGame() {
			const { region, gameId } = playbookSnap.game!;
			const [summary, timeline] = await Promise.all([
				loadSummary(normalizeRegionToV5(region), gameId),
				loadTimeline(normalizeRegionToV5(region), gameId),
			]);
			if (!!summary && !!timeline) {
				uiState.currentFrameIndex = 0;
				matchState.gameProvider = "api";
				matchState.match = { summary, timeline };
			}
		}

		async function loadCustomGame() {
			if (playbookSnap.customGameId && playbookSnap.customGameId === matchSnap.customGameId) {
				return;
			}
			const customGame = await requestCustomGameByToken(playbookSnap.customMatchToken!);
			if (customGame.match && customGame.custom_game.game_provider) {
				uiState.currentFrameIndex = 0;
				matchState.gameProvider = customGame.custom_game.game_provider;
				matchState.match = customGame.match;
			}
		}

		if (!playbookSnap.game && !playbookSnap.customMatchToken && !playbookSnap.customGameId) {
			matchState.match = undefined;
			matchState.gameProvider = undefined;
			matchState.customGameId = undefined;
		} else {
			if (playbookSnap.game) {
				loadGame();
			} else if (playbookSnap.customMatchToken && playbookSnap.customGameId) {
				loadCustomGame();
			}
		}
	}, [playbookSnap.game, playbookSnap.customMatchToken, matchSnap.customGameId]);

	useAsyncEffect(async () => {
		if (!strategyId) return;

		try {
			const res = await getStrategy(strategyId);
			// TODO f2 not found?
			if (!res || !res.ok) return;

			if (res.strategy.custom_game_id) {
				const custom_game_res = await requestCustomGame(res.strategy.custom_game_id);
				playbookState.customMatchToken = custom_game_res.token;
				loadMapStateFromStrategy(
					res.strategy,
					custom_game_res.custom_game,
					custom_game_res.match,
				);
			} else {
				loadMapStateFromStrategy(res.strategy);
			}

			closeAllModals();
		} catch (e) {
			// TODO f2 error handling
		}
	}, [strategyId]);

	useAsyncEffect(async () => {
		if (!sale_id) return;

		const res = await checkPurchase(sale_id);
		if (res.ok) {
			const user = await getMe();
			authState.user = user.user;
		}
	}, [sale_id]);

	useAsyncEffect(async () => {
		if (!isAuthenticated) return;

		const user = await getMe();
		if (!user.ok) {
			authState.accessToken = undefined;
			authState.user = undefined;
			return;
		}
		authState.user = user.user;
	}, [isAuthenticated]);

	useNetwork();

	return (
		<div className="flex flex-row flex-1">
			<Toolbar />
			<div className="flex flex-col flex-1">
				<div className={"flex-1 flex flex-col"}>
					<div className={"p-2 pl-0 flex-1"}>
						<div
							className={
								"relative h-full overflow-hidden border border-slate-800 bg-slate-600"
							}
						>
							<Map />
						</div>
					</div>
				</div>
				<FramesBar />
			</div>
			<Sidebar />
			<Routes>
				<Route
					path={"/login"}
					element={
						<AuthModal title={"Login"}>
							<LoginForm />
						</AuthModal>
					}
				/>
				<Route
					path={"/request-reset"}
					element={
						<AuthModal title={"Reset Password"}>
							<RequestResetForm />
						</AuthModal>
					}
				/>
				<Route
					path={"/reset-password"}
					element={
						<AuthModal title={"Reset Password"}>
							<ResetPasswordForm />
						</AuthModal>
					}
				/>
				<Route
					path={"/register"}
					element={
						<AuthModal title={"Register"}>
							<RegisterForm />
						</AuthModal>
					}
				/>
			</Routes>
			<Modals />
		</div>
	);
};
