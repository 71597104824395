import { authState } from "../states/authState";
import { MapFrame } from "../types/map";
import { PlaybookState } from "@shared/states/frameState";

const BASE_URL = import.meta.env.VITE_API_URL;

export interface Strategy {
	id: string;
	title: string;
	created_at: string;
	updated_at: string;
	custom_game_id?: number;
	state?: {
		version: number;
		frames?: Record<number, MapFrame>;
		notes?: string;
		game?: PlaybookState["game"];
	};
}

interface ListStrategiesResponse {
	ok: boolean;
	strategies: Strategy[];
}

export async function getStrategy(id: string): Promise<{ ok: boolean; strategy: Strategy }> {
	const res = await fetch(`${BASE_URL}/strategy/${id}`, {
		method: "GET",
		headers: { Authorization: "Bearer " + authState.accessToken },
	});

	return await res.json();
}

export async function listStrategies(): Promise<ListStrategiesResponse> {
	const res = await fetch(`${BASE_URL}/strategy/`, {
		method: "GET",
		headers: { Authorization: "Bearer " + authState.accessToken },
	});

	return await res.json();
}

export interface SaveStratBody {
	title: string;
	state: Strategy["state"];
	custom_game_id?: number;
}

export async function saveNewStrategy(
	body: SaveStratBody,
): Promise<{ ok: boolean; strategy: Strategy }> {
	const res = await fetch(`${BASE_URL}/strategy/`, {
		method: "POST",
		body: JSON.stringify(body),
		headers: {
			Authorization: "Bearer " + authState.accessToken,
			"Content-type": "application/json",
		},
	});

	return await res.json();
}

export async function saveStrategy(id: string, body: SaveStratBody): Promise<Strategy> {
	const res = await fetch(`${BASE_URL}/strategy/${id}`, {
		method: "PUT",
		body: JSON.stringify(body),
		headers: {
			Authorization: "Bearer " + authState.accessToken,
			"Content-type": "application/json",
		},
	});

	return await res.json();
}

export async function deleteStrategy(id: string): Promise<{ ok: boolean }> {
	const res = await fetch(`${BASE_URL}/strategy/${id}`, {
		method: "DELETE",
		headers: { Authorization: "Bearer " + authState.accessToken },
	});

	return await res.json();
}
