import React, { useState } from "react";
import { Popover } from "react-tiny-popover";

interface ToolColorPickerProps {
	colors?: string[];
	currentColor: string;
	onSelect: (newColor: string) => void;
}

export const BASE_COLORS = ["white", "black", "#5795f1", "#c54423", "#3fb66a", "#d9bd1d"];

const ToolColorPicker: React.FC<ToolColorPickerProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const colors = props.colors || BASE_COLORS;

	const handleSelect = (color: string) => {
		props.onSelect(color);
		setIsOpen(false);
	};

	return (
		<>
			<Popover
				isOpen={isOpen}
				positions={["right"]}
				content={
					<ColorsPopover
						currentColor={props.currentColor}
						onSelect={handleSelect}
						colors={colors}
					/>
				}
				onClickOutside={() => setIsOpen(false)}
			>
				<div className={"absolute -right-2 -bottom-2"}>
					<div
						onClick={() => setIsOpen(!isOpen)}
						className={
							"border border-slate-400 w-3 h-3 rounded-full m-2 hover:cursor-pointer"
						}
						style={{ background: props.currentColor }}
					></div>
				</div>
			</Popover>
		</>
	);
};

export const ColorsPopover: React.FC<ToolColorPickerProps> = (props) => {
	return (
		<div className={"p-2 bg-slate-600 border border-black drop-shadow-md rounded"}>
			<div className={"flex gap-2"}>
				{props.colors?.map((c) => (
					<ColorBlock key={c} color={c} onClick={props.onSelect} />
				))}
			</div>
		</div>
	);
};

export const ColorBlock: React.FC<{ color: string; onClick: ToolColorPickerProps["onSelect"] }> = (
	props,
) => {
	const handleClick = () => {
		props.onClick(props.color);
	};

	return (
		<div
			className={"rounded w-5 h-5 border border-slate-300 cursor-pointer"}
			onClick={handleClick}
			style={{ background: props.color }}
		/>
	);
};
export { ToolColorPicker };
