import React, { useCallback } from "react";
import { ImageObject, ShapeObject } from "@shared/Map/types";
import { Position } from "@shared/types/riot";
import { Draggable } from "@shared/Map/Draggable";
import { SVGImage } from "@shared/Map/objects/SVGImage";

export interface MapImageProps {
	image: ImageObject;
	onUpdate: (update: Partial<ImageObject>) => void;
}

const MapImage: React.FC<MapImageProps> = (props) => {
	const handleMove = useCallback((newPosition: Position) => {
		props.onUpdate({ position: newPosition });
	}, []);

	const translate = 14 * props.image.scale * 20;
	return (
		<Draggable position={props.image.position} onMove={handleMove}>
			<g
				transform={`translate(-${translate} -${translate}) scale(${
					props.image.scale * 20
				})`}
			>
				<SVGImage href={props.image.url} />
			</g>
		</Draggable>
	);
};

export { MapImage };
