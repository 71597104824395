import { proxy, subscribe, useSnapshot } from "valtio";
import { loadSettings, storeSettings } from "../storage/settings";
import { Match, RegionV5 } from "../types/riot";
import { MapTool, ToolProps } from "../types/tools";
import { authState } from "@shared/states/authState";
import { useNavigate } from "react-router-dom";

export interface ToolState {
	selectedTool: MapTool;
	properties: ToolProps;
}

export const toolState = proxy<ToolState>({
	selectedTool: MapTool.MOVE,
	properties: {
		[MapTool.PENCIL]: { color: "white", isArrow: false },
		[MapTool.IMAGE]: { scale: 1 },
		[MapTool.WARD]: { color: "green", areaColor: "blue" },
		[MapTool.SHAPE]: { color: "white", shape: "kill", scale: 1 },
		[MapTool.TEXT]: { color: "white", editingId: null, fontSize: 20 },
	},
});

subscribe(toolState, () => {
	if (toolState.selectedTool !== MapTool.TEXT) {
		toolState.properties[MapTool.TEXT].editingId = null;
	}
});

export const platformState = proxy<{ platform: "web" | "desktop" | "iframe" }>({ platform: "web" });

export interface UIState {
	currentFrameIndex: number;
	inGame: boolean;
	applyToAllFrames: boolean;
	modal?: "settings" | "help" | "import" | "strategies" | "upgrade" | "changelog";
	strategyId?: string;
}

export const uiState = proxy<UIState>({
	currentFrameIndex: 0,
	inGame: false,
	applyToAllFrames: true,
});

export type GameProvider = "api" | "grid" | "custom";

export interface MatchState {
	match?: Match;
	customGameId?: number | null;
	gameProvider?: GameProvider | null;
}

export const matchState = proxy<MatchState>({});

export type SettingsState = {
	user?: {
		name: string;
		platform_id: RegionV5;
	};
	online?: {
		username: string;
		is_custom_name: boolean; // def false
	};
	map?: {
		vectorStyle?: boolean; // def true
		showBush?: boolean; // def true
		championSize?: number; // def 1
		showStructures?: boolean; // def true
		showWardVision?: boolean; // def true
		structureScale?: number; // def 1
	};
	behavior?: {
		openPostGame?: boolean; // def true
		openMinimap?: boolean; // def true
		closeOnGame?: boolean; // def false
		moveToSecondScreen?: boolean; // def true
	};
};

// todo setting to auto open game after it finishes?
export const settingsState = proxy<SettingsState>(loadSettings());

export function useSettingsSnapshot() {
	return useSnapshot(settingsState);
}

subscribe(settingsState, () => {
	storeSettings(settingsState);
});

export function closeAllModals() {
	uiState.modal = undefined;
}

export function useOpenUpgrade() {
	const navigate = useNavigate();

	const openUpgradeModal = () => {
		closeAllModals();
		if (!authState.user) {
			navigate("/login");
			return;
		}

		uiState.modal = "upgrade";
	};

	return { openUpgradeModal };
}
