import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { convertGridToMatch } from "@shared/modals/ImportGameModal/grid/gridGameImporter";
import { GridEvent } from "@shared/modals/ImportGameModal/grid/gridTypes";
import { playbookState, resetMapState } from "@shared/states/frameState";
import { closeAllModals, matchState, useOpenUpgrade } from "@shared/states/uiStates";
import { StaticDataContext } from "@shared/context/StaticDataContext";
import { trackEvent } from "@shared/utils";
import { useHasTier } from "@shared/states/authState";
import { useApi } from "@shared/api/hooks";
import { saveNewCustomGame } from "@shared/api/custom_game";

const fileTypes = ["JSONL"];

export interface ImportGridProps {}

const ImportGrid: React.FC<ImportGridProps> = (props) => {
	const [isImporting, setIsImporting] = useState(false);
	const [error, setError] = useState(false);
	const { champions } = useContext(StaticDataContext);
	const isChallenger = useHasTier("Challenger");
	const { openUpgradeModal } = useOpenUpgrade();
	const { request: requestSaveNewCustomGame } = useApi(saveNewCustomGame);

	const handleChange = (file: File) => {
		const reader = new FileReader();
		reader.readAsText(file, "UTF-8");

		reader.onload = function (evt) {
			setIsImporting(true);
			setTimeout(async () => {
				const res = evt.target?.result as string | null;
				const lines = res
					?.split(/\n/)
					.filter((l) => l.length > 0)
					.map((l) => JSON.parse(l));
				const match = convertGridToMatch(lines as GridEvent[], champions);

				if (!match) {
					//todo error
					setIsImporting(false);
					setError(true);
					return;
				}

				trackEvent("import_grid");
				const saved_game = await requestSaveNewCustomGame({
					game_id: match.summary.gameName!,
					game_provider: "grid",
					match,
				});
				setIsImporting(false);
				resetMapState();
				matchState.gameProvider = "grid";
				matchState.customGameId = saved_game.custom_game.id;
				matchState.match = match;
				playbookState.customGameId = saved_game.custom_game.id;
				playbookState.customMatchToken = saved_game.token;
				closeAllModals();
			}, 10);
		};
		reader.onerror = function (evt) {
			setIsImporting(false);
			setError(true);
			console.error(evt);
		};
	};

	if (!isChallenger) {
		return (
			<div className={"flex flex-col items-center justify-center gap-4"}>
				<div className={"text-slate-100"}>
					Importing GRID games requires a Challenger Subscription.
				</div>
				<div
					onClick={openUpgradeModal}
					className={
						"text-center w-100 rounded p-2 cursor-pointer bg-opacity-80 bg-blend-lighten hover:bg-blend-darken hover:bg-opacity-100 active:bg-slate-700 select-none text-white disabled:opacity-30 bg-blue-500"
					}
				>
					Upgrade Now
				</div>
			</div>
		);
	}

	return (
		<div>
			{error && <div className={"text-red-500"}>Error importing file.</div>}
			{isImporting ? (
				<div className={"text-slate-400"}>Importing...</div>
			) : (
				<FileUploader
					handleChange={handleChange}
					name="file"
					types={fileTypes}
					classes={"!border-slate-500 !text-white"}
				>
					<div
						className={
							"border border-slate-400 border-dashed rounded p-2 text-sm cursor-pointer"
						}
					>
						Click or Drag{" "}
						<span
							className={
								"rounded bg-blue-400 bg-opacity-40 px-1 border border-blue-400"
							}
						>
							.jsonl
						</span>{" "}
						Game file here
					</div>
				</FileUploader>
			)}
		</div>
	);
};

export { ImportGrid };
