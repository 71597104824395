import { authState } from "../states/authState";

const BASE_URL = import.meta.env.VITE_API_URL;

export type MembershipTier = "Diamond" | "Challenger" | "admin";

export interface User {
	id: string;
	email: string;
	membershipTier: MembershipTier;
	subscriptionId: string;
}

interface UserResponse {
	ok: boolean;
	user: User;
}

export async function getMe(): Promise<UserResponse> {
	const res = await fetch(`${BASE_URL}/user/me`, {
		method: "GET",
		headers: { Authorization: "Bearer " + authState.accessToken }
	});
	return await res.json();
}
