import React, { useContext } from "react";
import { StaticDataContext } from "../../context/StaticDataContext";
import { AssetIcon } from "@shared/components/AssetIcon";

interface SummonerItemIconProps {
	itemId: number;
	width: number;
	height: number;
}

export const SummonerItemIcon: React.FC<SummonerItemIconProps> = ({ itemId, width, height }) => {
	const { items } = useContext(StaticDataContext);
	const item = items[`${itemId}`];

	if (itemId === 0 || !item) return null;

	return <AssetIcon image={item.image} width={width} height={height} />;
};
