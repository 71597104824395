import { proxy, useSnapshot } from "valtio";
import { Strategy } from "../api/strategies";
import { FramePoint, MapFrame } from "../types/map";
import { Match, RegionV4, RegionV5 } from "../types/riot";
import { matchState, uiState } from "./uiStates";
import { normalizeRegionToV5 } from "@shared/utils";
import { CustomGame } from "@shared/api/custom_game";

export const mapOverlayFramesState = proxy<{
	frames?: Record<number, MapFrame>;
}>({ frames: { 0: { frameIndex: 0 } } });

export const overlaySettingsState = proxy<{ drag?: Record<string, FramePoint> }>({});

export const sharedSettingsState = proxy<{ notes?: string }>({});

export interface PlaybookState {
	customGameId?: number | null;
	customMatchToken?: string | null;
	game?: {
		gameId: string;
		region: RegionV4 | RegionV5;
	} | null;
}

export const playbookState = proxy<PlaybookState>({});

/**
 * Return the Map Overlay Frame state, which is the current frame's overlay, to then apply to the timeline frame to obtain the map frame
 */
export function useMapOverlayFrameSnapshot() {
	return useSnapshot(mapOverlayFramesState);
}

export function loadMapStateFromGame(regionV5: RegionV5, gameId: string) {
	resetMapState();
	mapOverlayFramesState.frames = {};
	playbookState.game = {
		region: regionV5,
		gameId: gameId,
	};
}

export function loadMapStateFromStrategy(
	strategy: Strategy,
	customGame?: CustomGame,
	customMatch?: Match,
) {
	mapOverlayFramesState.frames = strategy.state?.frames;
	sharedSettingsState.notes = strategy?.state?.notes;
	if (strategy.state?.game) {
		playbookState.customGameId = null;
		matchState.customGameId = null;
		matchState.gameProvider = null;
		if (strategy.state.game?.region) {
			playbookState.game = {
				gameId: strategy.state.game.gameId,
				region: normalizeRegionToV5(strategy.state.game.region),
			};
		} else {
			playbookState.game = strategy.state.game;
		}
	}

	if (customMatch && customGame) {
		playbookState.game = null;
		playbookState.customGameId = customGame.id;
		matchState.customGameId = customGame.id;
		matchState.gameProvider = customGame.game_provider;
		matchState.match = customMatch;
	}

	uiState.strategyId = strategy.id;
	uiState.currentFrameIndex = 0;
}

export function resetMapState() {
	uiState.currentFrameIndex = 0;
	mapOverlayFramesState.frames = { 0: { frameIndex: 0 } };
	sharedSettingsState.notes = undefined;
	// not sure why but using undefined doesn't work.
	// if you start a new strat and then share, it would retain the old values.
	playbookState.game = null;
	playbookState.customGameId = null;
	playbookState.customMatchToken = null;
	// for these, it doesn't matter as they are not shared
	matchState.customGameId = undefined;
	matchState.gameProvider = undefined;
	matchState.match = undefined;
	uiState.strategyId = undefined;
}
