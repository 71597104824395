import React from "react";
import { FrameEvent } from "@shared/types/riot";
import { BuildingKillEventRow } from "./BuildingKillEventRow";
import { EliteKillEventRow } from "./EliteKillEventRow";
import { ItemPurchaseEventRow } from "./ItemPurchaseEventRow";
import { KillEventRow } from "./KillEventRow";

interface EventsListProps {
	events: FrameEvent[];
}

export const EventsList: React.FC<EventsListProps> = (props) => {
	return (
		<div>
			{props.events.map((event, i) => {
				if (event.type === "CHAMPION_KILL")
					return (
						<KillEventRow
							event={event}
							key={`${event.timestamp}${event.type}${event.victimId}${i}`}
						/>
					);
				if (event.type === "ITEM_PURCHASED")
					return (
						<ItemPurchaseEventRow
							event={event}
							key={`${event.timestamp}${event.type}${event.itemId}${i}`}
						/>
					);
				if (event.type === "ELITE_MONSTER_KILL")
					return (
						<EliteKillEventRow
							event={event}
							key={`${event.timestamp}${event.type}${event.monsterType}${i}`}
						/>
					);
				if (event.type === "BUILDING_KILL")
					return (
						<BuildingKillEventRow
							event={event}
							key={`${event.timestamp}${event.type}${event.position.x}${event.position.y}${i}`}
						/>
					);
			})}
		</div>
	);
};
