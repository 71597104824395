import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { authState, useAuth, useHasTier } from "../../states/authState";
import { uiState } from "@shared/states/uiStates";

export interface UserMenuProps {
	onClose: () => void;
}

const UserMenu: React.FC<UserMenuProps> = (props) => {
	const { user, isAuthenticated } = useAuth();
	const navigate = useNavigate();
	const hasChallengerTier = useHasTier("Challenger");

	const handleLogout = () => {
		authState.accessToken = undefined;
		authState.user = undefined;
		navigate("/");
		props.onClose();
	};

	const handleUpgradeClick = () => {
		uiState.modal = "upgrade";
	};

	if (!user) return null;

	return (
		<div className={"p-2 bg-slate-600 border border-black drop-shadow-md rounded"}>
			<div className={"text-white mb-2"}>
				<div className={"font-bold"}>Logged in as:</div>
				<div>{user.email}</div>
			</div>

			<div className={"text-white mb-2"}>
				<div className={"font-bold"}>Membership Tier:</div>
				{!user.membershipTier && <div>Amateur</div>}
				{user.membershipTier && <div>{user.membershipTier}</div>}
			</div>
			{user.subscriptionId && user.membershipTier && (
				<div className={"mb-8"}>
					<a
						href={`https://app.gumroad.com/subscriptions/${user.subscriptionId}/manage`}
						className={"underline text-blue-200"}
						target={"_blank"}
						rel={"noreferrer noopener"}
					>
						Manage subscription
					</a>
				</div>
			)}

			<div className={"flex gap-4"}>
				{!hasChallengerTier && (
					<Button onClick={handleUpgradeClick} className={"bg-green-500 font-bold"}>
						Upgrade
					</Button>
				)}

				<Button onClick={handleLogout}>Log out</Button>
			</div>
		</div>
	);
};

export { UserMenu };
