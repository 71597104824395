import * as React from "react";
const SvgTowerPlates = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:serif": "http://www.serif.com/", width: "100%", height: "100%", viewBox: "0 0 101 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-141,0)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(3.31977,0,0,2.81975,111.751,-17.284)" }, /* @__PURE__ */ React.createElement("rect", { id: "tower-plates-symbol", "serif:id": "tower plates symbol", x: 8.975, y: 6.13, width: 30.123, height: 35.464, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "tower-plates-symbol1", "serif:id": "tower plates symbol" }, /* @__PURE__ */ React.createElement("g", { id: "blue_tower_plate", transform: "matrix(0.301226,0,0,0.354641,-33.6624,6.1296)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,2,0.125054)" }, /* @__PURE__ */ React.createElement("path", { d: "M213.548,66L189.548,87.687L165.548,66L165.548,32.875L213.548,32.875L213.548,66Z", style: {
  fill: "currentColor",
  stroke: "black",
  strokeWidth: 3
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.791667,0,0,0.812996,41.4892,9.83556)" }, /* @__PURE__ */ React.createElement("path", { d: "M213.548,66L189.548,87.687L165.548,66L165.548,32.875L213.548,32.875L213.548,66Z" })))))));
export default SvgTowerPlates;
