import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { usePageResize } from "@shared/utils";
import { proxy, useSnapshot } from "valtio";
import { Ticks } from "@shared/components/NewFrameBar/Ticks/Ticks";
import { TickCursor } from "@shared/components/NewFrameBar/Ticks/TickCursor";
import { Size } from "@shared/Map/types";
import { Position } from "@shared/types/riot";
import { EventIcons } from "@shared/components/NewFrameBar/EventIcons/EventIcons";
import { matchState, uiState } from "@shared/states/uiStates";
import { useThrottledCallback } from "@react-hookz/web";
import { createTicks, TimeTick } from "@shared/components/NewFrameBar/Ticks/utils";
import { updateAwareness } from "@shared/network/playbook_network";
import { networkState } from "@shared/states/networkState";

export const ICON_SCALE = 0.8;
export const X_PADDING = 4;
export const ICON_SIZE = 24 * ICON_SCALE;
export const ICON_MARGIN = 4 * ICON_SCALE;
export const ICON_HALF = ICON_SIZE / 2 + ICON_MARGIN;

export interface FrameBarProps {}

export const barState = proxy<{ size: Size; position: Position }>({
	size: { width: 0, height: 0 },
	position: { x: 0, y: 0 },
});

const FramesBar: React.FC<FrameBarProps> = (props) => {
	const barSnap = useSnapshot(barState);
	const matchStateSnap = useSnapshot(matchState);
	const matchSnap = matchStateSnap.match;
	const uiSnap = useSnapshot(uiState);
	const container = useRef<SVGSVGElement>(null);
	const { connected, states, clientId } = useSnapshot(networkState);

	const ticksPerMinute = matchStateSnap.gameProvider === "grid" ? 12 : 6;

	const ticks = useMemo(() => {
		if (!matchSnap?.timeline.frames) return [];

		const timestamps = matchSnap.timeline.frames.map((f) => f.timestamp);

		const newTicks = createTicks(
			timestamps,
			ticksPerMinute,
			barSnap.size.width - X_PADDING * 2,
		);

		return newTicks;
	}, [matchSnap?.timeline.frames, barSnap.size.width, ticksPerMinute]);

	const selectedTick = useMemo(() => {
		return ticks.find((t) => t.frameIndex === uiSnap.currentFrameIndex);
	}, [ticks, uiSnap.currentFrameIndex]);

	const pageResizeCallback = useCallback(() => {
		if (!container.current) return;

		const rect = container.current!.getBoundingClientRect();

		barState.size.width = rect.width;
		barState.size.height = rect.height;
		barState.position.x = rect.left;
		barState.position.y = rect.top;
	}, [container.current]);

	usePageResize(pageResizeCallback, 100);

	useEffect(() => {
		pageResizeCallback();
	}, [pageResizeCallback, matchSnap]);

	const handleSelected = useThrottledCallback(
		(tick: TimeTick) => {
			if (tick.frameIndex !== uiState.currentFrameIndex) {
				uiState.currentFrameIndex = tick.frameIndex!;
				updateAwareness("frameIndex", tick.frameIndex);
			}
		},
		[],
		50,
	);

	if (!barSnap.size || !matchSnap) return null;

	return (
		<div className={"h-16 p-2 pt-0 pl-0"}>
			<svg className={"h-full w-full"} ref={container}>
				<EventIcons />
				{connected &&
					states
						.filter((v) => v.clientId !== clientId)
						.map((v) => {
							const tick = ticks.find((t) => t.frameIndex === v.frameIndex);
							if (!tick) return null;

							return (
								<TickCursor
									key={v.clientId}
									tick={tick}
									color={v.color}
									showTime={false}
								/>
							);
						})}
				<Ticks onSelectedTick={handleSelected} ticks={ticks} selectedTick={selectedTick} />
				{selectedTick && <TickCursor tick={selectedTick} showTime={true} />}
			</svg>
		</div>
	);
};

export { FramesBar };
