import React, { FC, useMemo } from "react";
import { useSnapshot } from "valtio";
import { matchState, uiState, useSettingsSnapshot } from "@shared/states/uiStates";
import { Structure } from "@shared/Map/objects/structures/Structure";
import InhibitorIcon from "@assets/img/structures/inhib.svg?react";
import NexusIcon from "@assets/img/structures/nexus.svg?react";
import { Tower } from "@shared/Map/objects/structures/Tower";

import { LaneType, Position } from "@shared/types/riot";
import { playbookState } from "@shared/states/frameState";
import { pickle } from "@shared/utils";
import base_structures from "@shared/Map/objects/structures/base_structures.json";
import { useMatchSnap } from "@shared/Map/frame_hooks";

interface SVGStructuresLayerProps {}

export interface TowerType {
	life: number;
	lane: LaneType;
	teamId: 100 | 200;
	towerType: string;
}

export interface InhibitorType {
	life: number;
	lane: LaneType;
	teamId: 100 | 200;
	resurrectAt?: number;
}

interface StructuresType {
	inhibitors?: Record<string, InhibitorType>;
	towers?: Record<string, TowerType>;
}

export const useStructuresFrame = (frameIndex: number): StructuresType => {
	const matchSnap = useMatchSnap();

	// todo d4 wtf why is this not use hook?
	return useMemo(() => {
		if (!matchSnap) return {};

		const { towers, inhibitors } = pickle(base_structures) as StructuresType;

		for (let i = 0; i < frameIndex; i++) {
			const frame = matchSnap.timeline.frames[i];
			for (const event of frame?.events || []) {
				for (const [key, inhib] of Object.entries(inhibitors || {})) {
					if (inhib.resurrectAt && inhib.resurrectAt < frame.timestamp) {
						// delete inhibitors![key];
						inhib.life = 5;
					}
				}
				if (event.type == "TURRET_PLATE_DESTROYED") {
					const key = `${event.position.x}-${event.position.y}`;
					towers![key]!.life--;
				} else if (event.type == "BUILDING_KILL") {
					const key = `${event.position.x}-${event.position.y}`;
					if (event.buildingType == "TOWER_BUILDING") {
						towers![key].life = 0;
					}
					if (event.buildingType == "INHIBITOR_BUILDING") {
						inhibitors![key].life = 0;
						inhibitors![key].resurrectAt = frame.timestamp + 1000 * 60 * 5;
					}
				}
			}
		}

		return { towers, inhibitors };
	}, [frameIndex, matchSnap]);
};

const StructuresLayer: React.FC<SVGStructuresLayerProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const settingsSnap = useSettingsSnapshot();
	const structures = useStructuresFrame(uiSnap.currentFrameIndex + 1);

	if (settingsSnap.map?.showStructures === false) return null;

	if (!structures.towers || !structures.inhibitors) return null;

	return (
		<>
			<Tower
				position={{ x: 1100, y: 4400 }}
				tower={structures.towers["981-10441"]}
				outer={true}
			/>
			<Tower
				position={{ x: 5900, y: 8500 }}
				tower={structures.towers["5846-6396"]}
				outer={true}
			/>
			<Tower
				position={{ x: 10550, y: 13800 }}
				tower={structures.towers["10504-1029"]}
				outer={true}
			/>

			<Tower
				position={{ x: 4400, y: 1000 }}
				tower={structures.towers["4318-13875"]}
				outer={true}
			/>
			<Tower
				position={{ x: 8900, y: 6400 }}
				tower={structures.towers["8955-8510"]}
				outer={true}
			/>
			<Tower
				position={{ x: 13900, y: 10400 }}
				tower={structures.towers["13866-4505"]}
				outer={true}
			/>

			<Tower position={{ x: 1600, y: 8150 }} tower={structures.towers["1512-6699"]} />
			<Tower position={{ x: 5100, y: 10000 }} tower={structures.towers["5048-4812"]} />
			<Tower position={{ x: 6900, y: 13400 }} tower={structures.towers["6919-1483"]} />
			<Tower position={{ x: 1250, y: 10500 }} tower={structures.towers["1169-4287"]} />
			<Tower position={{ x: 3750, y: 11100 }} tower={structures.towers["3651-3696"]} />
			<Tower position={{ x: 4350, y: 13550 }} tower={structures.towers["4281-1253"]} />
			<Tower position={{ x: 1800, y: 12500 }} tower={structures.towers["1748-2270"]} />
			<Tower position={{ x: 2300, y: 13000 }} tower={structures.towers["2177-1807"]} />

			<Tower position={{ x: 8000, y: 1500 }} tower={structures.towers["7943-13411"]} />
			<Tower position={{ x: 9850, y: 4700 }} tower={structures.towers["9767-10113"]} />
			<Tower position={{ x: 13350, y: 6600 }} tower={structures.towers["13327-8226"]} />
			<Tower position={{ x: 10500, y: 1200 }} tower={structures.towers["10481-13650"]} />
			<Tower position={{ x: 11100, y: 3800 }} tower={structures.towers["11134-11207"]} />
			<Tower position={{ x: 13600, y: 4400 }} tower={structures.towers["13624-10572"]} />
			<Tower position={{ x: 12600, y: 1800 }} tower={structures.towers["13052-12612"]} />
			<Tower position={{ x: 13100, y: 2400 }} tower={structures.towers["12611-13084"]} />

			<Inhibitor pos={{ x: 1250, y: 11300 }} inhib={structures.inhibitors["1169-3573"]} />
			<Inhibitor pos={{ x: 3300, y: 11600 }} inhib={structures.inhibitors["3203-3208"]} />
			<Inhibitor pos={{ x: 3550, y: 13600 }} inhib={structures.inhibitors["3454-1241"]} />

			<Inhibitor pos={{ x: 11280, y: 1300 }} inhib={structures.inhibitors["11261-13659"]} />
			<Inhibitor pos={{ x: 11600, y: 3300 }} inhib={structures.inhibitors["11603-11667"]} />
			<Inhibitor pos={{ x: 13600, y: 3650 }} inhib={structures.inhibitors["13598-11316"]} />

			<Structure position={{ x: 1650, y: 13200 }} teamId={100} show={true} size={800}>
				<NexusIcon width={800} height={800} />
			</Structure>
			<Structure position={{ x: 13250, y: 1750 }} teamId={200} show={true} size={800}>
				<NexusIcon width={800} height={800} />
			</Structure>
		</>
	);
};

const Inhibitor: FC<{ pos: Position; inhib: InhibitorType }> = ({ pos, inhib }) => {
	return (
		<Structure position={pos} teamId={inhib.teamId} show={inhib.life > 0} size={800}>
			<InhibitorIcon width={800} height={800} />
		</Structure>
	);
};

export { StructuresLayer };
