import React from "react";
import { Tooltip } from "../components/Tooltip";

export interface NewToolbarButtonProps {
	onClick: React.MouseEventHandler;
	onEnter?: React.MouseEventHandler;
	onLeave?: React.MouseEventHandler;
	extra?: React.ReactElement;
	tooltipMessage: string;
	isSelected?: boolean;
	testId: string;
}

const NewToolbarButton: React.FC<React.PropsWithChildren<NewToolbarButtonProps>> = (props) => {
	let actualSelected = props.isSelected === true;
	return (
		<div className={"relative w-10 h-10"}>
			<Tooltip content={props.tooltipMessage}>
				<button
					data-testid={props.testId}
					onClick={props.onClick}
					onMouseEnter={props.onEnter}
					onMouseLeave={props.onLeave}
					className={
						"w-10 h-10 rounded flex items-center justify-center relative text-white " +
						(actualSelected
							? "bg-slate-500 hover:bg-slate-400 border-slate-400 border"
							: "bg-slate-600 hover:bg-slate-500")
					}
				>
					{props.children}
				</button>
			</Tooltip>
			{props?.extra}
		</div>
	);
};

export { NewToolbarButton };
