import React, { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../Modal";
import { closeAllModals } from "@shared/states/uiStates";

export interface AuthModalProps {
	title: string;
}

const AuthModal: React.FC<PropsWithChildren<AuthModalProps>> = (props) => {
	const navigate = useNavigate();

	useEffect(() => {
		closeAllModals();
	}, []);

	return (
		<Modal title={props.title} onClose={() => navigate("/")}>
			{props.children}
		</Modal>
	);
};

export { AuthModal };
