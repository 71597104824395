import React from "react";
import { useSnapshot } from "valtio";
import { setOverlayLayer } from "../../Map/actions";
import { uiState } from "../../states/uiStates";
import { BaronTerrainType, DragonType } from "../../types/map";
import { dragons } from "./DragonToolButton";
import { useFrameBaronTerrain, useFrameDragon } from "@shared/Map/frame_hooks";

import BaronHunterIcon from "../../../../assets/barons/baron-hunter.svg?react";
import BaronTerritorialIcon from "../../../../assets/barons/baron-territorial.svg?react";
import BaronSeeingIcon from "../../../../assets/barons/baron-seeing.svg?react";

export interface DragonPopoverProps {
	onClose: () => void;
}

const DragonPopover: React.FC<DragonPopoverProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const dragonType = useFrameDragon(uiSnap.currentFrameIndex);
	const baronTerrainType = useFrameBaronTerrain(uiSnap.currentFrameIndex);

	const handleClick = (type: string) => () => {
		setOverlayLayer(uiSnap.currentFrameIndex, "dragon", type as DragonType);
		props.onClose();
	};

	const handleBaronClick = (type: BaronTerrainType) => () => {
		if (baronTerrainType === type) {
			setOverlayLayer(0, "baronTerrain", undefined);
		} else {
			setOverlayLayer(0, "baronTerrain", type);
		}
		props.onClose();
	};

	return (
		<div className={"p-2 bg-slate-600 border border-black drop-shadow-md rounded"}>
			<div className={"flex gap-2"}>
				{Object.values(dragons).map(({ key, icon }) => {
					const DragonIcon = icon;

					const actualSelected = dragonType === key;
					return (
						<button
							key={key}
							className={
								"w-10 h-10 rounded flex items-center justify-center " +
								(actualSelected
									? "bg-slate-700"
									: "bg-slate-600 hover:bg-slate-500")
							}
							onClick={handleClick(key)}
						>
							<DragonIcon width={26} height={26}></DragonIcon>
						</button>
					);
				})}
			</div>
			<div className={"flex justify-center gap-4"}>
				<button
					onClick={handleBaronClick("hunting")}
					className={
						"w-10 h-10 rounded flex items-center justify-center " +
						(baronTerrainType === "hunting"
							? "bg-slate-700"
							: "bg-slate-600 hover:bg-slate-500")
					}
				>
					<BaronHunterIcon width={26} height={26}></BaronHunterIcon>
				</button>
				<button
					onClick={handleBaronClick("seeing")}
					className={
						"w-10 h-10 rounded flex items-center justify-center " +
						(baronTerrainType === "seeing"
							? "bg-slate-700"
							: "bg-slate-600 hover:bg-slate-500")
					}
				>
					<BaronSeeingIcon width={26} height={26}></BaronSeeingIcon>
				</button>
				<button
					onClick={handleBaronClick("territorial")}
					className={
						"w-10 h-10 rounded flex items-center justify-center " +
						(baronTerrainType === "territorial"
							? "bg-slate-700"
							: "bg-slate-600 hover:bg-slate-500")
					}
				>
					<BaronTerritorialIcon width={26} height={26}></BaronTerritorialIcon>
				</button>
			</div>
		</div>
	);
};

export { DragonPopover };
