import React, { useState } from "react";
import { Popover } from "react-tiny-popover";
import { useSnapshot } from "valtio";
import ChemtechDragonIcon from "../../../../assets/dragons/chemtech.svg?react";
import FireDragonIcon from "../../../../assets/dragons/fire.svg?react";
import HextechDragonIcon from "../../../../assets/dragons/hextech.svg?react";
import MountainDragonIcon from "../../../../assets/dragons/mountain.svg?react";
import NormalDragonIcon from "../../../../assets/dragons/normal.svg?react";
import OceanDragonIcon from "../../../../assets/dragons/ocean.svg?react";
import WindDragonIcon from "../../../../assets/dragons/wind.svg?react";
import { uiState } from "../../states/uiStates";
import { NewToolbarButton } from "../NewToolbarButton";
import { DragonPopover } from "./DragonPopover";
import { useFrameDragon } from "@shared/Map/frame_hooks";

interface DragonButtonProps {}

export const dragons = {
	normal: { key: "normal", icon: NormalDragonIcon },
	ocean: { key: "ocean", icon: OceanDragonIcon },
	wind: { key: "wind", icon: WindDragonIcon },
	mountain: { key: "mountain", icon: MountainDragonIcon },
	fire: { key: "fire", icon: FireDragonIcon },
	hextech: { key: "hextech", icon: HextechDragonIcon },
	chemtech: { key: "chemtech", icon: ChemtechDragonIcon },
};

const DragonToolButton: React.FC<DragonButtonProps> = (props) => {
	const uiSnap = useSnapshot(uiState);
	const dragonType = useFrameDragon(uiSnap.currentFrameIndex);
	const [isOpen, setIsOpen] = useState(false);

	const CurrentDragonIcon = dragons[dragonType || "normal"].icon;

	const handleClick = (e: React.MouseEvent) => {
		setIsOpen(true);
	};

	const handleClickOutside = () => {
		setIsOpen(false);
	};

	return (
		<Popover
			isOpen={isOpen}
			positions={["right"]}
			padding={10}
			content={<DragonPopover onClose={handleClickOutside} />}
			onClickOutside={handleClickOutside}
		>
			<div>
				<NewToolbarButton
					onClick={handleClick}
					testId="toolbar-btn-dragon"
					tooltipMessage={"Change the elemental Dragon influencing the Rift"}
				>
					<CurrentDragonIcon width={30} height={30} />
				</NewToolbarButton>
			</div>
		</Popover>
	);
};

export { DragonToolButton };
