import React, { useState } from "react";
import { APIError, fetchSummoner } from "../../api";
import { RegionV4, RegionV5, Summoner } from "../../types/riot";
import { SearchSummonerInput } from "./SearchSummonerInput";
import { GamesHistoryView } from "./GamesHistoryView";
import { useApi } from "@shared/api/hooks";
import { storeLastSearch } from "@shared/storage/settings";

export interface ImportSummonerProps {
	queueId: string;
}

const ImportSummoner: React.FC<ImportSummonerProps> = (props) => {
	const { loading: loading, request: request } = useApi(fetchSummoner);

	const [summoner, setSummoner] = useState<Summoner | null>(null);
	const [region, setRegion] = useState<RegionV5>("americas");
	const [error, setError] = useState<APIError>();

	const search = async (username: string, region: RegionV5) => {
		let localSummoner = summoner;
		setSummoner(null);
		setError(undefined);

		if (
			!localSummoner?.name ||
			localSummoner.name.toLocaleLowerCase() !== username.toLocaleLowerCase()
		) {
			const summonerResponse = await request(region, username);
			if (!summonerResponse) {
				setError({ message: "An error occurred.", code: 2446 });
				return;
			}
			if (summonerResponse.error) {
				setError(summonerResponse.error);
				return;
			}
			if (!summonerResponse || !summonerResponse.summoner) {
				setError({ message: "An error occurred.", code: 2446 });
				return;
			}
			localSummoner = summonerResponse.summoner;
			storeLastSearch(username, region);
		}

		setRegion(region);
		setSummoner(localSummoner);
	};

	return (
		<>
			<div className={"mb-2"}>
				<SearchSummonerInput onSearch={search} />
				{!loading && error && <div className={"text-red-400 mt-2"}>{error.message}</div>}
			</div>

			{summoner && (
				<div className={"max-h-96 overflow-y-scroll"}>
					<GamesHistoryView summoner={summoner} region={region} queueId={props.queueId} />
				</div>
			)}
		</>
	);
};

export { ImportSummoner };
