import React from "react";
import { WardObject } from "@shared/Map/types";
import { MiniWard } from "@shared/Toolbar/WardButton/MiniWard";
import { wardAreaColors, wardColors } from "@shared/Toolbar/WardButton/WardToolButton";
import { setOverlayObject } from "@shared/Map/actions";
import { uiState } from "@shared/states/uiStates";
import { wardHighlightState } from "@shared/Sidebar/panels/WardsPanel/WardsPanel";
import { useSnapshot } from "valtio";
import HiddenIcon from "../../../../../assets/icons/eye-hidden.svg?react";
import VisibleIcon from "../../../../../assets/icons/eye-visible.svg?react";

export interface WardRowProps {
	ward: WardObject;
}

const WardRow: React.FC<WardRowProps> = (props) => {
	const { highlightWardId } = useSnapshot(wardHighlightState);
	const handleClick = () => {};

	const handleTextChange =
		(position: "topText" | "bottomText") => (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value.length > 0 ? e.target.value : undefined;
			setOverlayObject(uiState.currentFrameIndex, "wards", props.ward.id, {
				[position]: e.target.value,
			});
		};

	const handleMouseOver = () => {
		wardHighlightState.highlightWardId = props.ward.id;
	};

	const handleMouseLeave = () => {
		wardHighlightState.highlightWardId = null;
	};

	const handleVisibilityToggle = () => {
		setOverlayObject(uiState.currentFrameIndex, "wards", props.ward.id, {
			visible: props.ward.visible == false,
		});
	};

	return (
		<div className={"border-b border-slate-800 p-2 bg-slate-500 flex items-center gap-2"}>
			<div className={""}>
				<MiniWard
					onClick={handleClick}
					selected={highlightWardId === props.ward.id}
					color={wardColors[props.ward.color]}
					areaColor={wardAreaColors[props.ward.areaColor]}
					onMouseOver={handleMouseOver}
					onMouseLeave={handleMouseLeave}
				/>
			</div>
			<div className={"flex flex-col flex-1 gap-2"}>
				<input
					placeholder={"Enter text above"}
					onChange={handleTextChange("topText")}
					value={props.ward.topText || ""}
					maxLength={30}
					className={
						"bg-slate-400 text-white placeholder:text-opacity-40 placeholder:text-white rounded px-1"
					}
				/>
				<input
					placeholder={"Enter text below"}
					maxLength={30}
					onChange={handleTextChange("bottomText")}
					value={props.ward.bottomText || ""}
					className={
						"bg-slate-400 text-white placeholder:text-opacity-40 placeholder:text-white rounded px-1"
					}
				/>
			</div>
			<button
				className={"p-1 bg-transparent bg-opacity-40 text-white rounded select-none"}
				onClick={handleVisibilityToggle}
			>
				{props.ward.visible == false ? (
					<VisibleIcon width={20} height={20} />
				) : (
					<HiddenIcon width={20} height={20} />
				)}
			</button>
		</div>
	);
};

export { WardRow };
