import * as React from "react";
const SvgTower = (props) => /* @__PURE__ */ React.createElement("svg", { "xmlns:serif": "http://www.serif.com/", width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlSpace: "preserve", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(3.31977,0,0,2.81975,-29.7965,-17.284)" }, /* @__PURE__ */ React.createElement("rect", { id: "blue_tower", x: 8.975, y: 6.13, width: 30.123, height: 35.464, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "blue_tower1", "serif:id": "blue_tower" }, /* @__PURE__ */ React.createElement("g", { id: "blue_tower2", "serif:id": "blue_tower", transform: "matrix(0.301226,0,0,0.354641,8.97548,6.1296)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.5,0,0,1.43571,-26.3841,-27.2042)" }, /* @__PURE__ */ React.createElement("path", { d: "M68.846,83.376L32.999,83.376L40.886,76.672L60.96,76.672L68.846,83.376Z", style: {
  fill: "currentColor",
  stroke: "black",
  strokeWidth: "2.04px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.5,0,0,1.43571,-25,-24.8034)" }, /* @__PURE__ */ React.createElement("path", { d: "M60,60L60,75L40,75L40,60L35,60L35,46.298L65,46.298L65,60L60,60Z", style: {
  fill: "currentColor",
  stroke: "black",
  strokeWidth: "2.04px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.5,0,0,1.43571,-25,-24.8034)" }, /* @__PURE__ */ React.createElement("path", { d: "M42.5,37.5L42.5,30C42.5,25.861 45.861,22.5 50,22.5C54.139,22.5 57.5,25.861 57.5,30L57.5,37.5L70,37.5L70,47.5L50,60L30,47.5L30,37.5L42.5,37.5Z", style: {
  fill: "currentColor",
  stroke: "black",
  strokeWidth: "2.04px"
} }))))));
export default SvgTower;
