import {
	forwardRef,
	MouseEventHandler,
	PropsWithChildren,
	ReactElement,
	SyntheticEvent,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";

interface ViewerOptions {
	stageWidth: number;
	stageHeight: number;
	scaleFactor?: number;
	minScale?: number;
	maxScale?: number;
	padding?: number;
}

export interface Viewport {
	x: number;
	y: number;
	scale: number;
	matrix?: DOMMatrix;
}

interface ViewerProps {
	viewport: Viewport;
	onViewportChange: (viewport: Viewport) => void;
	options: ViewerOptions;

	width?: number;
	height?: number;
	className?: string;

	canPan?: boolean;
	canZoom?: boolean;
	tool?: ReactElement;
	onMouseMove?: MouseEventHandler;
}

export interface ViewerRef {
	bbox: () => DOMRect | null;
	svg: () => SVGSVGElement | null;
	fit: () => void;
}

export const Viewer = forwardRef<ViewerRef, PropsWithChildren<ViewerProps>>((props, ref) => {
	const svgRef = useRef<SVGSVGElement>(null);
	const { children, viewport, onViewportChange, options, tool, canPan, canZoom, ...restProps } =
		props;
	const {
		scaleFactor = 0.1,
		maxScale = 5,
		minScale = 0.1,
		stageHeight,
		stageWidth,
		padding = 20,
	} = options;

	const [offset, setOffset] = useState<DOMPoint | null>(null);
	const [isDragging, setIsDragging] = useState(false);

	const matrix = useMemo(() => {
		return new DOMMatrix([viewport.scale, 0, 0, viewport.scale, viewport.x, viewport.y]);
	}, [viewport.scale, viewport.x, viewport.y]);

	const getPointFromScreenCoordinates = (ctm: DOMMatrix, x: number, y: number) => {
		let svgPoint = new DOMPoint();
		svgPoint.x = x;
		svgPoint.y = y;
		return svgPoint.matrixTransform(ctm.inverse());
	};

	let handleInputStart = (e: SyntheticEvent<SVGSVGElement>) => {
		function handleStart(target: SVGSVGElement, event: Touch | MouseEvent) {
			let point = getPointFromScreenCoordinates(
				target.getScreenCTM()!,
				event.clientX,
				event.clientY,
			);
			if (!point) return;

			setOffset(point);
			setIsDragging(true);
		}

		if (window.TouchEvent && e.nativeEvent instanceof TouchEvent && canPan) {
			handleStart(e.currentTarget, e.nativeEvent.touches[0]);
		}
		if (e.nativeEvent instanceof MouseEvent && (canPan || e.nativeEvent.button === 1)) {
			handleStart(e.currentTarget, e.nativeEvent);
		}
	};

	const handleUpdateViewport = (scale: number, x: number, y: number) => {
		const containerBox = svgRef.current?.getBoundingClientRect();
		const newScale = bounds(minScale, scale, maxScale);
		const minX = -stageWidth * newScale + padding;
		const maxX = containerBox?.width! - padding;
		const newX = bounds(minX, x, maxX);
		const minY = -stageHeight * newScale + padding;
		const maxY = containerBox?.height! - padding;
		const newY = bounds(minY, y, maxY);

		props.onViewportChange({
			scale: newScale,
			x: newX,
			y: newY,
			// matrix: new DOMMatrix([viewport.scale, 0, 0, viewport.scale, viewport.x, viewport.y])
		});
	};

	const handleMove = (event: MouseEvent) => {
		if (!isDragging || !offset) return;
		let point = getPointFromScreenCoordinates(
			svgRef.current?.getScreenCTM()!,
			event.clientX,
			event.clientY,
		);
		if (!point) return;

		handleUpdateViewport(
			viewport.scale,
			viewport.x + point.x - offset.x,
			viewport.y + point.y - offset.y,
		);
	};

	let handleWheel = (e: WheelEvent) => {
		if (canZoom === false) return;
		e.preventDefault();
		const isMac =
			navigator.userAgent.indexOf("MacOS") >= 0 ||
			navigator.userAgent.indexOf("Mac OS") >= 0 ||
			navigator.userAgent.indexOf("Macintosh") >= 0;
		if (e.ctrlKey || !isMac) {
			let point = getPointFromScreenCoordinates(
				svgRef.current?.getScreenCTM()!,
				e.clientX,
				e.clientY,
			);
			if (!point) return;
			let dir = e.deltaY < 0 ? 1 : -1;
			let newScale = bounds(minScale, viewport.scale * (1 + dir * scaleFactor), maxScale);
			if (newScale === viewport.scale) return;
			const newMatrix = new DOMMatrix()
				.translate(point.x, point.y)
				.scale(1 / viewport.scale, 1 / viewport.scale)
				.scale(newScale, newScale)
				.translate(-point.x, -point.y)
				.multiply(matrix);

			handleUpdateViewport(newScale, newMatrix.e, newMatrix.f);
		} else {
			// on mac, panning with 2 fingers simlates wheel + ctrl
			handleUpdateViewport(viewport.scale, viewport.x - e.deltaX, viewport.y - e.deltaY);
		}
	};

	useEffect(() => {
		if (!isDragging) return;

		const upListener = () => {
			setIsDragging(false);
		};

		window.addEventListener("mouseup", upListener);
		window.addEventListener("pointerup", upListener);
		window.addEventListener("pointercancel", upListener);
		window.addEventListener("mousemove", handleMove);

		return () => {
			window.removeEventListener("mouseup", upListener);
			window.removeEventListener("pointerup", upListener);
			window.removeEventListener("pointercancel", upListener);
			window.removeEventListener("mousemove", handleMove);
		};
	}, [isDragging]);

	useEffect(() => {
		if (!svgRef.current) return;
		svgRef.current.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			svgRef.current?.removeEventListener("wheel", handleWheel);
		};
	}, [svgRef.current, viewport.scale, viewport.x, viewport.y]);

	const fit = () => {
		const bbox = svgRef.current?.getBoundingClientRect();
		if (!bbox) return;

		const scaleWidth = bbox.width / stageWidth;
		const scaleHeight = bbox.height / stageHeight;
		const scale = Math.min(scaleWidth, scaleHeight);

		const scaledWidth = stageWidth * scale;
		const scaledHeight = stageHeight * scale;

		const newX = (bbox.width - scaledWidth) / 2;
		const newY = (bbox.height - scaledHeight) / 2;

		handleUpdateViewport(scale, newX, newY);
	};

	const bbox = () => {
		return svgRef.current?.getBoundingClientRect() || null;
	};

	const svg = () => {
		return svgRef.current;
	};

	useImperativeHandle(
		ref,
		() => ({
			fit,
			bbox,
			svg,
		}),
		[svgRef.current],
	);

	return (
		<svg
			data-testid={"svg-viewer"}
			id={"map-viewer"}
			ref={svgRef}
			{...restProps}
			onMouseDown={handleInputStart}
			onTouchStart={handleInputStart}
		>
			<g transform={matrix.toString()}>{children}</g>
		</svg>
	);
});

const bounds = (min: number, val: number, max: number) => {
	return Math.max(Math.min(val, max), min);
};
