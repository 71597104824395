import React, { useCallback, useEffect, useRef, useState } from "react";
import { TextObject } from "@shared/Map/types";
import { Position } from "@shared/types/riot";
import { Draggable } from "@shared/Map/Draggable";
import { useSnapshot } from "valtio";
import { toolState, uiState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { deleteOverlayObject } from "@shared/Map/actions";
import "./Text.css";

export interface TextProps {
	obj: TextObject;
	onUpdate: (update: Partial<TextObject>) => void;
}

const Text: React.FC<TextProps> = (props) => {
	const toolSnap = useSnapshot(toolState);
	const [hasMoved, setHasMoved] = useState(false);
	const handleMove = useCallback((newPosition: Position) => {
		props.onUpdate({ position: newPosition });
		setHasMoved(true);
	}, []);
	const [initialValue, setInitialValue] = useState(props.obj.text);

	const areaRef = useRef<any | null>(null);
	const hasTool = toolSnap.selectedTool === MapTool.TEXT;
	const isEditing = hasTool && toolSnap.properties[MapTool.TEXT].editingId === props.obj.id;

	useEffect(() => {
		if (areaRef.current && isEditing) {
			areaRef.current.focus();
		}
	}, [areaRef.current, isEditing]);

	const handleChange = (e: any) => {
		props.onUpdate({ text: e.currentTarget.innerText.trimEnd() });
	};

	const handleClick = () => {
		if (toolState.selectedTool !== MapTool.TEXT || hasMoved) return;

		setInitialValue(props.obj.text);
		toolState.properties[MapTool.TEXT].editingId = props.obj.id;
	};

	const lines = props.obj.text.split("\n").map((l) => (l.length === 0 ? "‎ " : l));
	const handleKeyDown = (e: React.KeyboardEvent) => {
		e.stopPropagation();
		if (e.key === "Escape") {
			toolState.properties[MapTool.TEXT].editingId = null;
		}
		if (e.ctrlKey && e.key === "Enter") {
			toolState.properties[MapTool.TEXT].editingId = null;
		}
	};

	useEffect(() => {
		if (!isEditing && props.obj.text.length === 0) {
			deleteOverlayObject(uiState.currentFrameIndex, "texts", props.obj.id);
		}
	}, [isEditing, props.obj.text]);

	if (isEditing) {
		return (
			<Draggable position={props.obj.position} onMove={handleMove} draggable={false}>
				<g transform={`scale(20)`}>
					<foreignObject
						width={"100%"}
						height={"100%"}
						onClick={() => {
							toolState.properties[MapTool.TEXT].editingId = null;
						}}
					>
						<div
							className={
								"border border-dashed border-blue-400 w-fit rounded box-content"
							}
						>
							<div
								ref={areaRef}
								contentEditable={true}
								onKeyDown={handleKeyDown}
								onClick={(e) => {
									e.stopPropagation();
								}}
								onInput={handleChange}
								className={"text-editor"}
								style={{
									fontSize: props.obj.fontSize + "px",
									lineHeight: props.obj.fontSize + "px",
									color: props.obj.color,
								}}
								suppressContentEditableWarning={true}
							>
								{initialValue}
							</div>
						</div>
					</foreignObject>
				</g>
			</Draggable>
		);
	}

	return (
		<Draggable position={props.obj.position} onMove={handleMove}>
			<g transform={`scale(20)`}>
				<text
					x={0}
					y={0}
					fontSize={props.obj.fontSize}
					onClick={handleClick}
					onMouseDown={() => setHasMoved(false)}
					style={{ cursor: hasTool ? "text" : "initial", color: props.obj.color }}
					fill={props.obj.color}
					fontWeight={500}
					stroke={"black"}
					strokeWidth={0.3}
				>
					{lines.map((l, i) => (
						<tspan
							key={i}
							x={1}
							dy={props.obj.fontSize}
							data-objectid={props.obj.id}
							data-layerid={props.obj.layerId}
						>
							{l}
						</tspan>
					))}
				</text>
			</g>
		</Draggable>
	);
};

export { Text };
