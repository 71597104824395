import React, { useContext, useState } from "react";
import { useSnapshot } from "valtio";
import { Button } from "../../components/Button";
import { platformState } from "../../states/uiStates";
import { Modal } from "../Modal";
import { WelcomeModalContext } from "./WelcomeModalContext";
import { WelcomeModalImportPage } from "./WelcomeModalImportPage";
import { WelcomeModalMinimapPage } from "./WelcomeModalMinimapPage";
import { WelcomeModalSharePage } from "./WelcomeModalSharePage";

interface WelcomeModalProps {
	onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = (props) => {
	const { nextEnabled } = useContext(WelcomeModalContext);
	const [step, setStep] = useState(0);
	const platformSnap = useSnapshot(platformState);

	const handleStep = (dir: number) => () => {
		setStep((s) => s + dir);
	};

	const steps = [<WelcomeModalImportPage />, <WelcomeModalSharePage />];

	if (platformSnap.platform === "desktop") steps.splice(1, 0, <WelcomeModalMinimapPage />);

	return (
		<Modal title={"Welcome to LoLPlanner"}>
			<div className={"flex flex-col"}>
				<div className={"flex gap-2 px-4"}>{steps[step]}</div>

				{/*TODO d2 modal footer to component*/}
				<div className={"flex justify-between mt-4"}>
					<div>
						<Button data-testid={"welcome-btn-close"} onClick={props.onClose}>
							Close
						</Button>
					</div>
					<div className={"flex gap-2"}>
						{step > 0 && <Button onClick={handleStep(-1)}>Previous</Button>}
						{step < steps.length - 1 && (
							<Button disabled={!nextEnabled} onClick={handleStep(1)}>
								Next
							</Button>
						)}
						{step == steps.length - 1 && (
							<Button disabled={!nextEnabled} onClick={props.onClose}>
								Let's Go!
							</Button>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export { WelcomeModal };
