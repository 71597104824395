import React from "react";
import import_img from "./img/history.png";

interface WelcomeModalImportPageProps {}

const WelcomeModalImportPage: React.FC<WelcomeModalImportPageProps> = (props) => {
	return (
		<div className={"text-white"}>
			<img src={import_img} className={"mb-2"} />
			<h3 className={"text-2xl font-bold"}>Review any game</h3>
			<p className={"text-slate-300"}>
				Import in LoLPlanner any game from your history or any other player.
			</p>
		</div>
	);
};

export { WelcomeModalImportPage };