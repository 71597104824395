import React from "react";
import { ToolButton } from "../ToolButton";
import { MapTool } from "@shared/types/tools";
import { TextPopup } from "@shared/Toolbar/TextButton/TextPopup";

export interface TextButtonProps {}

const TextButton: React.FC<TextButtonProps> = (props) => {
	return (
		<ToolButton tool={MapTool.TEXT} tooltipMessage={"Draw text [t]"} extra={<TextPopup />}>
			<svg viewBox="0 0 256 256" width={32} height={32} xmlns="http://www.w3.org/2000/svg">
				<text
					x={120}
					y={200}
					textAnchor={"middle"}
					fontSize={200}
					fill={"white"}
					fontWeight={200}
				>
					A
				</text>
			</svg>
		</ToolButton>
	);
};

export { TextButton };
