import { proxy } from "valtio";
import { subscribeKey } from "valtio/utils";
import { updateAwareness } from "@shared/network/playbook_network";
import { throttle } from "lodash";
import { Position } from "@shared/types/riot";

// We use this pointer in draggable handlers. This pointer is where the cursor is in svg coordinates.
// Since we already have this info on the map, we don't need to recalculate it or carry around the vtm
// we can just use this state to do all other calculation
export const pointerState = proxy<{
	position: { x: number; y: number };
	// we use this for the eraser to attach the mousedown
	ref?: SVGSVGElement;
}>({
	position: { x: 0, y: 0 },
});

const throttledUpdateAwareness = throttle(
	(position: Position) => updateAwareness("position", position),
	100,
);

subscribeKey(pointerState, "position", ({ x, y }) => {
	throttledUpdateAwareness({ x, y });
});
