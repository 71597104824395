import { MatchParticipant, Position } from "../types/riot";

export interface Size {
	width: number;
	height: number;
}

export interface BaseMapObject {
	id: string;
	position: Position;
	dragging?: boolean;
	visible?: boolean;
}

export interface ChampionObject extends BaseMapObject {
	type: "champion";
	layerId: "champions";
	team: "red" | "blue";
	// role is only for the default (I think?)
	role?: MatchParticipant["teamPosition"];
	// on default overlay we don't have a participantId because there is no summary
	alive?: boolean;
	participantId?: number;
	championId?: string;

	summonerName?: string;
}

export interface WardObject extends BaseMapObject {
	type: "ward";
	layerId: "wards";
	color: "green" | "pink" | "blue" | "yellow";
	areaColor: "red" | "blue";

	topText?: string;
	bottomText?: string;
}

export interface ArrowObject extends BaseMapObject {
	type: "arrow";
	layerId: "arrows";
	points: Position[];
	color: string;
	expiry?: number;
}

export interface PencilPathObject extends BaseMapObject {
	type: "path";
	layerId: "paths";
	points: Position[];
	color: string;
	expiry?: number;
}

export interface ShapeObject extends BaseMapObject {
	type: "shape";
	layerId: "shapes";
	shape: (typeof ALL_SHAPES)[number];
	color: string;
	scale: number;
}

export interface ImageObject extends BaseMapObject {
	type: "image";
	layerId: "images";
	url: string;
	scale: number;
}

export interface TextObject extends BaseMapObject {
	type: "text";
	layerId: "texts";
	text: string;
	color: string;
	fontSize: number;
}

export interface BuildingObject extends BaseMapObject {
	type: "building";
	layerId: "buildings";
	team: "red" | "blue";
	buildingType:
		| "OUTER_TURRET"
		| "INNER_TURRET"
		| "BASE_TURRET"
		| "NEXUS_TURRET"
		| "INHIBITOR"
		| "NEXUS";
}

export const ALL_SHAPES = [
	"kill",
	"rect",
	"circle",
	"cross",
	"0",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
] as const;

export type MapObject =
	| ChampionObject
	| WardObject
	| ArrowObject
	| PencilPathObject
	| ShapeObject
	| TextObject
	| ImageObject
	| BuildingObject;

export type LayerID = MapObject["layerId"];
