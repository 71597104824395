import React, { useState } from "react";
import { Link } from "react-router-dom";
import { requestPasswordReset } from "../../api/auth";
import { Button } from "../../components/Button";

export interface RequestResetFormProps {
}

const RequestResetForm: React.FC<RequestResetFormProps> = (props) => {
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<Record<string, string[]>>();
	const [sent, setSent] = useState<boolean>(false);

	const handleReset = async () => {
		setErrors(undefined);
		setLoading(true);
		const res = await requestPasswordReset(email);
		setLoading(false);
		if (!res.ok) {
			setErrors(res.errors);
			return;
		}
		setSent(true);
	};
	return (
		<div className={"flex flex-col w-64"}>
			<div className={"flex flex-col mb-4"}>
				<label className={"mb-4"}>
					<div className={"text-xs text-slate-300 my-1"}>Email</div>
					<input
						placeholder={"Email"}
						value={email}
						onChange={(e) => setEmail(e.currentTarget.value)}
						className={
							"rounded border border-slate-600 bg-slate-200 p-1 text-slate-800 w-full"
						}
					/>
				</label>
				{sent && (
					<div className={"text-green-400 text-xs"}>If your email exists, you will be sent a link to reset
						your
						password.</div>
				)}
			</div>

			<div className={"flex justify-between items-center"}>
				{!loading && !sent &&
					<Button className={"px-6"} onClick={handleReset}>
						Request Reset
					</Button>
				}
				{sent && <div></div>}
				{loading && <div className={"text-slate-200"}>Loading...</div>}
				<div>
					<Link to={"/login"} className={"text-slate-200"}>
						Back
					</Link>
				</div>
			</div>
		</div>
	);
};

export { RequestResetForm };