import { useEffect, useMemo, useState } from "react";
import { useSnapshot } from "valtio";
import { networkState } from "../states/networkState";
import { settingsState, useSettingsSnapshot } from "../states/uiStates";
import { connectPlaybook } from "./playbook_network";
import { trackEvent } from "@shared/utils";

export function useNetworkUsername() {
	const settingsSnap = useSettingsSnapshot();
	return useMemo(() => {
		const randn = 10 + Math.floor(Math.random() * 99);
		let username = `User-${randn}`;

		if (settingsSnap.online?.is_custom_name && settingsSnap.online.username)
			return settingsSnap.online.username;

		if (settingsSnap.user?.name) return settingsSnap.user?.name;

		if (!settingsSnap.online) {
			settingsState.online = { username, is_custom_name: false };
		}

		return settingsSnap.online?.username;
	}, [settingsSnap.online]);
}

export function useNetwork() {
	const networkSnap = useSnapshot(networkState);
	const [initialized, setInitialized] = useState(false);
	const yjsUsername = useNetworkUsername();

	useEffect(() => {
		if (!yjsUsername) return;

		const query = new URLSearchParams(window.location.search);
		const roomName = query.get("room");

		if (!initialized && !networkSnap.connected && !!roomName) {
			trackEvent("share_open");
			setInitialized(true);
			connectPlaybook(roomName, yjsUsername);
		}
	}, [yjsUsername, networkSnap.connected, initialized]);
}
