import React, { useContext } from "react";
import { StaticDataContext } from "../../context/StaticDataContext";
import { Rune } from "../../types/riot";

interface SummonerRuneIconProps {
	runeId: number;
	width: number;
	height: number;
}

export const SummonerRuneIcon: React.FC<SummonerRuneIconProps> = ({ runeId, width, height }) => {
	const { runes: trees } = useContext(StaticDataContext);

	const runes = trees.reduce<Rune[]>((a, c) => {
		for (const s of c.slots) {
			for (const r of s.runes) {
				a.push(r);
			}
		}
		return a;
	}, []);
	const rune = runes.find((r) => r.id === runeId);

	if (!rune) return null;

	return (
		<img
			src={`https://ddragon.leagueoflegends.com/cdn/img/${rune.icon}`}
			width={width}
			height={height}
		/>
	);
};
