import { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import { StaticDataContext } from "./context/StaticDataContext";
import { platformState } from "./states/uiStates";
import { AssetImage, Champion, Position } from "./types/riot";
import { convertTimestampToTime } from "@shared/utils";

export const useGameMessage = (callback: (data: any) => void) => {
	const windowCallback = useCallback(
		(message: MessageEvent) => {
			callback(message.data);
		},
		[callback],
	);

	useWindowMessage(windowCallback);
};

export const useWindowMessage = (callback: (message: MessageEvent) => void) => {
	useEffect(() => {
		window.addEventListener("message", callback);
		return () => {
			window.removeEventListener("message", callback);
		};
	}, [callback]);
};

export const useChampion = (championId: Maybe<number | string>): Maybe<Champion> => {
	const { champions } = useContext(StaticDataContext);
	if (!championId) return null;
	return Object.values(champions).find((c) => `${championId}` == `${c.key}`);
};

export const useTimeSince = (time: number) => {
	return useMemo(() => {
		const diff = time - new Date().getTime();
		const rtf = new Intl.RelativeTimeFormat("en", {
			localeMatcher: "best fit", // other values: "lookup"
			numeric: "always", // other values: "auto"
			style: "long", // other values: "short" or "narrow"
		});

		const minute = 1000 * 60;
		const hour = minute * 60;
		const day = hour * 24;

		const minutes = Math.ceil(diff / minute);
		const hours = Math.ceil(diff / hour);
		const days = Math.ceil(diff / day);

		if (hours > -1) return rtf.format(minutes, "minutes");
		else if (hours > -24) return rtf.format(hours, "hours");
		else return rtf.format(days, "days");
	}, [time]);
};

export const useRelativeTimestamp = (time: number = 0) => {
	return useMemo(() => {
		return convertTimestampToTime(time);
	}, [time]);
};

export interface AwareUser {
	clientId: number;
	username: string;
	color: string;
	position: Position;
	frameIndex: number;
}

export function useAssetImage(image: AssetImage | undefined, sprite: boolean = true) {
	const { version } = useContext(StaticDataContext);
	const { platform } = useSnapshot(platformState);

	return useMemo(() => {
		if (!image) return null;

		if (platform === "desktop") return `/ddragon/${image.group}/${image.full}`;
		else
			return `https://ddragon.leagueoflegends.com/cdn/${version}/img/${sprite ? "sprite" : image.group}/${sprite ? image.sprite : image.full}`;
	}, [version, image]);
}

export function useQuery() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}
